import { useEffect, useState, useRef } from "react";
import BaseButton from "../../base/BaseButton";

import { submitEssayResponseFeedback } from "../../../api/apiCalls";
import { toast } from "react-toastify";
import styles from "../../../styles/main/subcomponents/ResponseFeedback.module.css";

import { DEFAULT_TEXTAREA_MAX_LENGTH_CHARS, ESSAY_MAX_LENGTH_CHARS, ExtractErrorMessage } from "../utils/utils";

/**
 * Essay Response feedback for V2 response in sidebar (ApplicationSidebar.js)
 */
export function LightweightResponseFeedback(props) {
    const [selectedOption, setSelectedOption] = useState(""); // "yes", "no", or ""
    const [hasSubmittedFeedback, setHasSubmittedFeedback] = useState(false);
    const [feedbackSubmissionError, setFeedbackSubmissionError] = useState("");
    const [responseFeedback, setResponseFeedback] = useState("");
    const { currentRequestId } = props;

    const handleSubmitClick = (e) => {
        if (selectedOption === "") return;

        const userFoundHelpful = selectedOption === "yes" ? true : selectedOption === "no" ? false : null;
        const formattedData = {
            essay_response_feedback: responseFeedback,
            request_id: currentRequestId,
            user_found_helpful: userFoundHelpful,
        };
        submitEssayResponseFeedback(formattedData)
            .then((response) => {
                setHasSubmittedFeedback(true);
            })
            .catch((error) => {
                setFeedbackSubmissionError(ExtractErrorMessage(error));
                toast.error(`Error submitting response feedback: ${error.response.data.error}`);
            });
    };
    const feedbackPlaceholder =
        selectedOption === "yes" ? "Awesome! What did you like about this response?" : "What could have been better?";
    const handleClick = (event, option) => {
        if (hasSubmittedFeedback) return;

        setSelectedOption(option);
    };
    return (
        <div className={styles.lw_feedback_container}>
            <h3 className={styles.lw_feedback_title}>Was this helpful?</h3>
            <div className={styles.lw_feedback_button_container}>
                <BaseButton
                    className={`${styles.lw_feedback_button} ${
                        selectedOption === "yes" && styles.lw_feedback_button_selected
                    } `}
                    onClick={(e) => handleClick(e, "yes")}
                >
                    Yes
                </BaseButton>
                <BaseButton
                    className={`${styles.lw_feedback_button} ${
                        selectedOption === "no" && styles.lw_feedback_button_selected
                    } `}
                    onClick={(e) => handleClick(e, "no")}
                >
                    No
                </BaseButton>
            </div>
            {selectedOption !== "" && !hasSubmittedFeedback && (
                <textarea
                    className={styles.lw_feedback_textarea}
                    onChange={(event) => setResponseFeedback(event.target.value)}
                    name="essayResponseFeedbackTextarea"
                    value={responseFeedback}
                    draggable={false}
                    placeholder={feedbackPlaceholder}
                    maxLength={DEFAULT_TEXTAREA_MAX_LENGTH_CHARS}
                />
            )}
            {selectedOption !== "" && !hasSubmittedFeedback && (
                <BaseButton className={styles.lw_feedback_submit} onClick={handleSubmitClick}>
                    Submit Feedback
                </BaseButton>
            )}
            {hasSubmittedFeedback ? <p className={styles.lw_feedback_thanks}>Thanks for your feedback! 😎</p> : null}
            {feedbackSubmissionError ? <p className={styles.lw_feedback_error}>{feedbackSubmissionError}</p> : null}
        </div>
    );
}

/**
 * Essay Response feedback for Athena V0/V1 response (full page)
 */
export default function ResponseFeedback(props) {
    const [selectedOption, setSelectedOption] = useState("");
    const [hasSubmittedFeedback, setHasSubmittedFeedback] = useState(false);
    const [feedbackSubmissionError, setFeedbackSubmissionError] = useState("");
    const currentRequestID = props.current_request_id;
    const textareaRef = useRef(null);

    useEffect(() => {
        if (textareaRef.current) {
            textareaRef.current.scrollIntoView({
                behavior: "smooth",
                block: "center",
            });
        }
    });

    const [formData, setFormData] = useState({
        essay_response_feedback: "",
    });

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const userFoundHelpful = selectedOption === "Yes" ? true : selectedOption === "No" ? false : null;
        const formattedFormData = {
            essay_response_feedback: formData.essay_response_feedback,
            request_id: currentRequestID,
            user_found_helpful: userFoundHelpful,
        };
        submitEssayResponseFeedback(formattedFormData)
            .then((response) => {
                setHasSubmittedFeedback(true);
            })
            .catch((error) => {
                setFeedbackSubmissionError(ExtractErrorMessage(error));
            });
    };

    const handleClick = (event, option) => {
        event.preventDefault();
        if (hasSubmittedFeedback) {
            return;
        }
        setSelectedOption(option);
    };

    return (
        <div className={styles.response_feedback}>
            <h3 className={styles.response_feedback_title}>Was this helpful?</h3>
            <div className={styles.response_feedback_option_container}>
                <BaseButton
                    className={`${styles.response_feedback_option} ${
                        selectedOption === "Yes"
                            ? styles.response_feedback_selected_option
                            : styles.response_feedback_unselected_option
                    }`}
                    onClick={(event) => handleClick(event, "Yes")}
                >
                    Yes
                </BaseButton>
                <BaseButton
                    className={`${styles.response_feedback_option} ${
                        selectedOption === "No"
                            ? styles.response_feedback_selected_option
                            : styles.response_feedback_unselected_option
                    }`}
                    onClick={(event) => handleClick(event, "No")}
                >
                    No
                </BaseButton>
            </div>
            <form onSubmit={handleSubmit}>
                {selectedOption === "No" && !hasSubmittedFeedback ? (
                    <textarea
                        className={styles.response_feedback_input}
                        onChange={handleChange}
                        name="essay_response_feedback"
                        value={formData.essay_response_feedback}
                        placeholder="What could have been better?"
                        maxLength={ESSAY_MAX_LENGTH_CHARS}
                        ref={textareaRef}
                    />
                ) : null}
                {selectedOption !== "" && !hasSubmittedFeedback ? (
                    <BaseButton className={styles.response_feedback_submit}>Submit Feedback</BaseButton>
                ) : null}
            </form>
            {hasSubmittedFeedback ? <p className={styles.response_feedback_thanks}>Thanks for your feedback!</p> : null}
            {feedbackSubmissionError ? (
                <p className={styles.response_feedback_error}>{feedbackSubmissionError}</p>
            ) : null}
        </div>
    );
}
