import styles from "../../../styles/content/articles/GeneralArticle.module.css";
import ajay from "../../../imgs/ajay.jpg";
import working from "../../../imgs/working.jpg";

export const StaticArticlePath = "/content/what-you-need-to-be-doing-now-as-a-2nd-semester-junior";

function Article() {
    return (
        <div className={styles.article_outer_container}>
            <div className={styles.article_inner_container}>
                <div className={styles.header_container}>
                    <div className={styles.title}>What You Need To Be Doing Now As A Second Semester Junior</div>
                    <div className={styles.author_container}>
                        <img className={styles.author_img} src={ajay} alt="ajay"/>
                        <div className={styles.author_info}>
                            <div className={styles.author_name}>Ajay Natarajan</div>
                            <div className={styles.author_description}>Caltech Class of 2022</div>
                        </div>
                    </div>
                </div>
                
                <div className={styles.article_body}>
                    <img className={styles.article_img} src={working} alt="working"/>
                    <div className={styles.paragraph}>
                        You might have a lot on your plate, maybe too much to handle and strategize. To take the burden on you, we have a very distinct step by step timeline of what you need to do for the year before you submit your applications. If you are applying for the next admissions cycle (Class of 2025), then this pertains to you. The below is the same schedule I have given to all the students I have done private counseling for — hence the more informal language.
                        <br></br><br></br>
                        Not all the advice below needs to be taken. This is, from what I have found, the most optimal way to approach the college admissions process. But if you have no interest in Top 30 schools, then feel free to disregard some of my more strict advice like “do not take a summer vacation.”
                        <br></br><br></br>
                        <div className={styles.header}>January 2024</div>
                        <span className={styles.italic_and_underline}>Summary: Focus on grades, think about recommendation letters, and create an initial version of your list of colleges you want to apply to.</span>
                        <br></br>
                        <ul>
                            <li>Focus on finishing off with A's, these are the last grades that “Early Action/Decision” colleges will see. This takes absolute priority.</li>
                            <li>Think about recommendation letters — which teachers do you want? Think about which teachers really like you and know you. That needs to be balanced with whether those teachers actually write good recommendation letters in general. Talk to your upperclassmen that have gone to the college(s) you want to attend and see who they used.</li>
                            <li>Do NOT be that quiet student who just does their homework in the back of the classroom. Start contributing to class discussions if you don't already. Help the person sitting next to you when they get stuck.</li>
                            <li>Think about what you want those teachers to write about you in your recommendation letters, and then do those things. They can't write about things you haven't done. Be helpful, resourceful, funny, smart, etc - whatever showcases your personality in the classroom.</li>
                            <li>You will need at least one STEM teacher and at least one humanities teacher to write you recommendation letters. Plan accordingly.</li>
                            <li>Create your tentative college list. Start by doing some basic research online.</li>
                        </ul>
                        <br></br>
                        <div className={styles.header}>February 2024</div>
                        <span className={styles.italic_and_underline}>Summary: Plan college visits, keep thinking about recommendation letters, and iterate on your college list.</span>
                        <br></br>
                        <ul>
                            <li>Plan for college visits over spring break — most top colleges don't track whether you visit them so how do you make these visits help your application?</li>
                            <li>Talk to undergraduates on campus and get unique insights about campus culture/community — the type of stuff that won't appear on the internet. You can reference these tidbits in your essay which will help convince admissions officers that you're the perfect fit for their campus and that you've really done your research.</li>
                            <li>Keep giving teachers things they can write about in their letter of recommendation (via how you act in class).</li>
                            <li>Start finalizing the college list.</li>
                        </ul>
                        <br></br>
                        <div className={styles.header}>March 2024</div>
                        <span className={styles.italic_and_underline}>Summary: Study for APs, strategize for cabinet spots, iterate on your college listSummary: Go to college visits, study for APs, and set up a plan for summer.</span>
                        <ul>
                            <li>Attend your scheduled college visits, and most importantly, talk to students. Get their names and message them periodically. You can reference them, by first name, as a friend in your essays and talk about how their experience convinced you this college was your perfect home for the next 4 years.</li>
                            <li>Grind preparation for APs - you'll need these 5s to pass through the funnel.</li>
                            <li>In the grand scheme of things, APs don't matter much but getting a 5 can partially offset a bad performance in a class (i.e. maybe you got a B- in AP Chem 1st semester but a 5 on the AP exam which means you're nationally in the top ~13% can help a little).</li>
                            <li>Set up something official for the summer — if you're in STEM, look for any research opportunities at local community colleges or any startups near you that can use an extra set of hands. Avoid doing summer school or any other “collegiate program” since most of these are a waste of time and don't help your application with the exception of a select few very competitive ones.</li>
                        </ul>
                        <br></br>
                        <div className={styles.header}>April 2024</div>
                        <span className={styles.italic_and_underline}>Summary: Study for APs, strategize for cabinet spots, iterate on your college list</span>
                        <ul>
                            <li>Spring break should be wrapping up so keep doing those college visits.</li>
                            <li>Try to lock down cabinet spots for next year — not Treasury/Secretary spots since those do little to move the needle; we're talking about core leadership positions in clubs like President/VP/Captain. The former are useless because a college doesn't care about how many cabinet spots you have — they care about whether you have the capacity, knowledge, and personality to be a leader that enriches life on their campus.</li>
                            <li>Based on your college visits, create an updated version of your college list.</li>
                        </ul>
                        <br></br>
                        <div className={styles.header}>May 2024</div>
                        <span className={styles.italic_and_underline}>Summary: Grades, don't drop the ball on recommendation letters, finish STRONG</span>
                        <ul>
                            <li>Even though APs are done, class performance still matters (insofar as recommendation letters go). Teachers are still paying attention to you — are you still a helpful and resourceful person that brings their whole personality to class?</li>
                            <li>Teachers will write only a limited set of recommendation letters — and they don't just go to their “best academic performing students”. They go to people who the teachers (A) like and respect — because they are human, and (B) feel that they can write a strong recommendation letter for. Everything you have been doing for the past several months is to satisfy these two criteria and you should continue to do so.</li>
                            <li>Let me take a pause here to say — it is important to never be “fake” because the best teachers see straight through that. The point I'm making here is you should be trying to bring your personality into the classroom as much as possible — however that might manifest. Maybe it's by being helpful and resourceful or maybe it's by being funny. Whatever your own personal brand is... that's what you should do.</li>
                        </ul>
                        <br></br>
                        <div className={styles.header}>June 2024</div>
                        <span className={styles.italic_and_underline}>Summary: Internship/research, common app essay iteration, do not take an extended summer vacation.</span>
                        <ul>
                            <li>Do your summer plan. Not community service, not wasted, but ideally some type of formal experience in your intended major. Get the internship or research position, whether it is close by or far away, at a company or university, paid or unpaid. It doesn't matter. Just do something!</li>
                            <li>Look to finish a project or at least something noteworthy at your internship to talk about on college apps. Think about how you can make this result sound impressive in just one or two sentences (because that's about how much room you'll have to explain it on your college application).</li>
                            <li>Write practice common app essays about things you have experienced through your extracurriculars, family, friends, summer experiences, travels abroad, maybe even the internship you're doing now. Just write and write and write.</li>
                            <li>The secret is the “prompt” of the common app essay doesn't actually matter. They change from year to year but feel free to respond to one from the prior year and when the new prompts come out in July, you'll realize your essay can probably easily slot into one of the new prompts. So start writing now.</li>
                            <li>Use our essay grading/feedback models on https://athenaco.ai to see how well your essay drafts align with what has worked for past college applicants to the universities you're interested in.</li>
                        </ul>
                        <br></br>
                        <div className={styles.header}>July 2024</div>
                        <span className={styles.italic_and_underline}>Summary: Internship/research, write essays in response to the newly released Common App prompts</span>
                        <ul>
                            <li>Do everything you did in June.</li>
                            <li>Finalize your college list of where you'll apply.</li>
                            <li>Decide where you want to apply “Early”. This is the university to which you'll apply in late October and receive word in mid-to-late December. Many universities have “Early Decision” programs which means if you are accepted, you must go. Some others have “Restricted Early Action” which means if you choose to apply here early, you can't apply anywhere else early. And the third category is “Early Action” which means there are no restrictions and you can just apply early with no repercussions or restrictions here.</li>
                            <li>Keep iterating on writing potential Common App essays. Good rule of thumb is I would try to write at least 1 new essay concept per week. Feel free to iterate on it using our Athena platform.</li>
                        </ul>
                        <br></br>
                        <div className={styles.header}>August 2024</div>
                        <span className={styles.italic_and_underline}>Summary: Summer homework, finalize Common App Essay, recommendation letter logistics</span>
                        <ul>
                            <li>Most high schools start around mid-August. For the first half of August, cram any summer homework you might have been assigned. Don't start working on it until now since it will just be a distraction in June/July which is bad for you.</li>
                            <li>Finalize a Common App essay, whether this is a completely new essay or a version of one of your previously written essays.</li>
                            <li>Determine the “reach-out” process to get letters of recommendation from the teachers you are interested in. Frequently, they will send out an email or some type of announcement but it can't hurt to be proactive as well. Don't be annoying and pester them but use it as an excuse just to catch up a bit.</li>
                        </ul>
                        <br></br>
                        <div className={styles.header}>September 2024</div>
                        <span className={styles.italic_and_underline}>Summary: Supplemental Essays and Activities List</span>
                        <ul>
                            <li>Recognize that by the end of this month, you will only have 12-13 weeks left before all your college applications are due. If you are applying to 12 colleges, you have to write at a rate of 1 complete college application per week. This is on top of your school work, exams, extracurriculars, etc. So planning ahead and starting early (AKA now) is important.</li>
                            <li>Apply to at least one school “Early”, I cannot stress this enough. Ivies have a significantly higher early acceptance rate than regular decision acceptance rate. And there's no downside to it.</li>
                            <li>Start the supplemental essay for your Early school(s)</li>
                            <li>Common App has this thing called an “Activities List” where you list out your activities and awards in those activities and cabinet positions, etc. Start writing this down and fill it out.</li>
                        </ul>
                        <br></br>
                        <div className={styles.header}>October 2024</div>
                        <span className={styles.italic_and_underline}>Summary: Supplemental Essays</span>
                        <ul>
                            <li>Work through a bunch of supplemental essays. You will frequently have to write 3-4 of these per university you apply to so this workload can be quite hefty. But these are frequently where universities pay the most attention so you don't want them to feel rushed. I would recommend passing in your drafts into our essay AI grading models to get quick feedback on what you're doing well and where you need to improve.</li>
                        </ul>
                        <br></br>
                        <div className={styles.header}>November 2024</div>
                        <span className={styles.italic_and_underline}>Summary: Supplemental Essays, Grades</span>
                        <ul>
                            <li>Same as October, make sure your grades don't slip and do some preparation for Finals.</li>
                        </ul>
                        <br></br>
                        <div className={styles.header}>December 2024</div>
                        <span className={styles.italic_and_underline}>Summary: Supplemental Essays, Grades</span>
                        <ul>
                            <li>Submit all your applications. Congratulations, you're finished!!</li>
                            <li>If you did happen to get into your “Early” school, feel free to withdraw any prior applications you already submitted to schools you know you wouldn't prefer to attend over that school. Many colleges admit by region and school (i.e. they won't accept too many people from one geographic region or school). So even though it might be fun just to see if you will get in, you might inadvertently be screwing over one of your own classmates. It is a personal belief of mine that we should do our best to serve our community and those around us, hence why I am calling this out.</li>
                        </ul>
                        <br></br>
                    </div>
                </div>
            </div>
            
        </div>
    );
}

export function WhatYouNeedToBeDoingNowAsA2ndSemesterJunior() {
    return (
        <Article />
    );
}