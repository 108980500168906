import styles from "../../../styles/main/subcomponents/BackArrowButton.module.css";

import { useNavigate } from 'react-router-dom';

import backArrow from "../../../imgs/back_arrow.png";

export default function BackArrowButton() {

    const navigate = useNavigate();

    return (
        <img
            className={styles.back_arrow_button}
            src={backArrow}
            alt="back arrow button"
            width={30}
            onClick={() => navigate(-1)}
        />
    );
}