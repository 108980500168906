import { verifyEmail, sendEmailVerification } from "../../api/apiCalls";
import { useAuthContext } from "./AuthContext";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useEffect, useState, Suspense, lazy } from "react";
// import styles from "../../styles/auth/EmailVerification.module.css";
import BaseButton from "../base/BaseButton";
import ClusterSelectionPage, { ModelSelectionWidget } from "../clusterSelection/ClusterSelectionPage";
import Princeton from "../../imgs/princeton.png";

import Editor from "../editor/Editor";

import styles from "../../styles/editor/Editor.module.css";

import { createCheckoutSession } from "../../api/apiCalls";

import { ping } from "../../api/apiCalls";

export default function Testing() {
    const { userProfile } = useAuthContext();
    const handleMonthly = () => {
        alert("hi!");
        const pro_id = "price_1PLvIQEmDnm3A5wzZMnw7yPa";
        const lite_id = "price_1PLvBqEmDnm3A5wzbtPguuI7";
        const data = {
            price_id: pro_id,
            user_id: userProfile.id,
        };
        createCheckoutSession(data)
            .then((response) => {
                console.log("ok we're in the response", response);
                // window.location.href = response.data.checkout_url;
                window.open(response.data.checkout_url, "_blank");
            })
            .catch((error) => {
                console.log("error???");
            });
    };

    const [activeTab, setActiveTab] = useState("prompt1");

    const handleSwitchTab = (tab) => {
        setActiveTab(tab);
    };

    const handlePing = () => {
        ping().then((response) => {
            console.log("ping response", response);
        });
    };

    const prompt =
        activeTab === "prompt1"
            ? "At Caltech, we investigate some of the most challenging, fundamental problems in science, technology, engineering, and mathematics. Identify and describe two STEM-related experiences from your high school years, either in or out of the classroom, and tell us how and why they activated your curiosity. What about them made you want to learn more and explore further?"
            : "Describe three experiences and/or activities that have helped develop your passion for a possible career in STEM. What about these experiences and/or activities has motivated you to pursue a career in STEM?";

    const testingHtml = (
        <div className={styles.editor_wrapper}>
            <button onClick={handlePing}>Monthly</button>
        </div>
    );

    return testingHtml;
}
