import styles from "../../../styles/main/GradingPage.module.css";

export default function InterpretFeedbackHyperlink() {
    return (
        <div className={styles.section_subtext}>
            <a href="/content/how-to-interpret-athena-feedback" target="_blank" rel="noopener">
                Need help understanding this feedback?
            </a>
        </div>
    );
}
