import { $createTextNode, $getRoot, $getSelection, $isNodeSelection, $isRangeSelection } from "lexical";
import { HeadingNode, $createHeadingNode } from "@lexical/rich-text";
import { useEffect, useState } from "react";

import { AutoFocusPlugin } from "@lexical/react/LexicalAutoFocusPlugin";
import { LexicalComposer } from "@lexical/react/LexicalComposer";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin";
import { LexicalErrorBoundary } from "@lexical/react/LexicalErrorBoundary";
import ToolbarPlugin from "./ToolbarPlugin";
import TreeViewPlugin from "./TreeViewPlugin";
import { $setBlocksType } from "@lexical/selection";

import styles from "../../styles/editor/Editor.module.css";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { fetchCollegeEssayData, saveCollegeEssayData } from "../../api/apiCalls";
import { useApplicationContext } from "../application/ApplicationContext";

import { toast } from "react-toastify";

const theme = {
    // Theme styling goes here
    //...
    code: styles.editor_code,
    heading: {
        h1: styles.editor_heading_h1,
        h2: styles.editor_heading_h2,
        h3: styles.editor_heading_h3,
        h4: styles.editor_heading_h4,
        h5: styles.editor_heading_h5,
    },
    image: styles.editor_image,
    link: styles.editor_link,
    list: {
        listitem: styles.editor_listitem,
        nested: {
            listitem: styles.editor_nested_listitem,
        },
        ol: styles.editor_list_ol,
        ul: styles.editor_list_ul,
    },
    ltr: styles.ltr,
    paragraph: styles.editor_paragraph,
    placeholder: styles.editor_placeholder,
    quote: styles.editor_quote,
    rtl: styles.rtl,
    text: {
        bold: styles.editor_text_bold,
        code: styles.editor_text_code,
        hashtag: styles.editor_text_hashtag,
        italic: styles.editor_text_italic,
        overflowed: styles.editor_text_overflowed,
        strikethrough: styles.editor_text_strikethrough,
        underline: styles.editor_text_underline,
        underlineStrikethrough: styles.editor_text_underlineStrikethrough,
    },
};

// Catch any errors that occur during Lexical updates and log them or throw them as needed.
// If you don't throw them, Lexical will try to recover gracefully without losing user data.
function onError(error) {
    toast.error(error);
}

function LazySavePlugin(props) {
    const [editor] = useLexicalComposerContext();
    const { isLoading, onChange } = props;
    useEffect(() => {
        return editor.registerUpdateListener(({ editorState }) => {
            if (isLoading) return;
            onChange(editorState);
        });
    }, [editor, isLoading]);
}

function LoadSavedStatePlugin(props) {
    const [editor] = useLexicalComposerContext();
    const { isLoading, setIsLoading, essayId } = props;
    const { setEditorStateStr } = useApplicationContext();

    useEffect(() => {
        if (!editor) return;
        if (!isLoading) return;

        // Wait for Application.js to set essayId before fetching data
        if (!essayId) return;

        fetchCollegeEssayData(essayId)
            .then((response) => {
                const essayJSON = response.data.essay.essay;
                const editorState = editor.parseEditorState(essayJSON);
                editor.setEditorState(editorState);
                editorState.read(() => {
                    const editorStateStr = $getRoot().getTextContent();
                    setEditorStateStr(editorStateStr);
                });
            })
            .catch((error) => {
                toast.error(`Error fetching saved essay: ${error.response.data.error}`);
                const editorState = editor.parseEditorState(emptyEditorState);
                editor.setEditorState(editorState);
            })
            .finally(() => setIsLoading(false));
    }, [editor, essayId, isLoading, setIsLoading]);
}

function VisibilityChangeStatePlugin(props) {
    const [editor] = useLexicalComposerContext();
    const { isLoading, setIsLoading, essayId } = props;
    // const { essayId } = useApplicationContext();
    useEffect(() => {
        const handleVisibilityChange = () => {
            if (!editor) return;

            // Wait for Application.js to set essayId before fetching data
            if (!essayId) return;

            // Patch to fix syncing editors if users have multiple tabs open. This is not true real-time syncing.
            // We'll eventually migrate over to websockets, but it's not a priority right now.
            if (document.visibilityState === "visible") {
                if (!isLoading) return;

                fetchCollegeEssayData(essayId)
                    .then((response) => {
                        const essayJSON = response.data.essay.essay;
                        const editorState = editor.parseEditorState(essayJSON);
                        editor.setEditorState(editorState);
                    })
                    .catch((error) => toast.error(`Error fetching saved state: ${error.response.data.error}`))
                    .finally(() => setIsLoading(false));
            } else {
                setIsLoading(true);
            }
        };
        document.addEventListener("visibilitychange", handleVisibilityChange);

        return () => {
            document.removeEventListener("visibilitychange", handleVisibilityChange);
        };
    }, [editor, essayId, isLoading, setIsLoading]);
}

export const emptyEditorState =
    '{"root":{"children":[{"children":[],"direction":null,"format":"","indent":0,"type":"paragraph","version":1}],"direction":null,"format":"","indent":0,"type":"root","version":1}}';

export const initialConfig = {
    editorState: emptyEditorState,
    namespace: "MyNewEditor",
    theme,
    onError,
    nodes: [HeadingNode],
};

export default function Editor(props) {
    const [isLoading, setIsLoading] = useState(true);
    const { editorStateJSON, setEditorStateJSON, setEditorStateStr, setIsEditorStateSaving } = useApplicationContext();
    const { essayId } = props;

    // On new editor load, we need to empty out existing editor state JSON from ApplicationContext
    // I feel like there's a race condition waiting to be found somewhere here... :goofy:
    useEffect(() => {
        setEditorStateJSON(emptyEditorState);
        setEditorStateStr("");
    }, []);

    const handleEditorChange = (editorState) => {
        setEditorStateJSON(JSON.stringify(editorState));
        editorState.read(() => {
            const editorStateStr = $getRoot().getTextContent();
            setEditorStateStr(editorStateStr);
        });
        setHasRecentChanges(true);
    };

    /* Save editor data every 1 second if there are recent changes */
    const [hasRecentChanges, setHasRecentChanges] = useState(false);

    useEffect(() => {
        if (!hasRecentChanges) return;

        setIsEditorStateSaving(true);
        const interval = setInterval(() => {
            saveEditorData(editorStateJSON);
        }, 1000);

        return () => clearInterval(interval);
    }, [hasRecentChanges, editorStateJSON]);

    const saveEditorData = (editorStateJSONSnapshot) => {
        // Prevent saving if the editor is still loading
        if (isLoading) return;
        if (!essayId) return;

        const formattedData = {
            essay: editorStateJSONSnapshot,
        };
        saveCollegeEssayData(essayId, formattedData)
            .then((response) => {})
            .catch((error) => toast.error(`Error saving essay: ${error.response.data.error}`))
            .finally(() => setIsEditorStateSaving(false));
        setHasRecentChanges(false);
    };

    return (
        <div className={styles.editor}>
            {/* <HeadingPlugin /> */}
            <LoadSavedStatePlugin isLoading={isLoading} setIsLoading={setIsLoading} essayId={essayId} />
            <VisibilityChangeStatePlugin isLoading={isLoading} setIsLoading={setIsLoading} />
            <div className={styles.editor_content_container}>
                <div className={styles.editor_content}>
                    {isLoading ? (
                        <div className={styles.editor_loading_screen}>Loading...</div>
                    ) : (
                        <RichTextPlugin
                            contentEditable={<ContentEditable className={styles.editor_input} />}
                            placeholder={<div className={styles.editor_placeholder}>Enter some text...</div>}
                            ErrorBoundary={LexicalErrorBoundary}
                        />
                    )}
                </div>
            </div>
            <HistoryPlugin />
            <AutoFocusPlugin />
            <LazySavePlugin isLoading={isLoading} onChange={handleEditorChange} />
            {/* <div className={styles.editor_extras}>
                    <div className={styles.editor_treeview}>
                        <TreeViewPlugin />
                    </div>
                </div> */}
        </div>
    );
}

// APPENDIX: Additional plugins for reference
function MyOnChangePlugin(props) {
    const [editor] = useLexicalComposerContext();
    const { onChange } = props;
    useEffect(() => {
        return editor.registerUpdateListener(({ editorState }) => {
            onChange(editorState);
        });
    }, [onChange, editor]);
}

function HeadingPlugin() {
    const [editor] = useLexicalComposerContext();
    const onClick = (tag) => {
        editor.update(() => {
            const selection = $getSelection();
            if ($isRangeSelection(selection)) {
                $setBlocksType(selection, () => $createHeadingNode(tag));
            }
        });
    };
    return (
        <div>
            {["h1", "h2", "h3"].map((tag) => {
                return <button onClick={() => onClick(tag)}>{tag}</button>;
            })}
        </div>
    );
}
