import { useContext, useEffect, useRef, useState } from "react";
import styles from "../../styles/application/Application.module.css";
import { useAuthContext } from "../auth/AuthContext";
import { useApplicationContext } from "./ApplicationContext";
import { SidebarContext } from "../sidebar/SidebarContext";
import { LightweightResponseFeedback } from "../main/subcomponents/ResponseFeedback";
import InterpretFeedbackHyperlink from "../main/subcomponents/InterpretFeedbackHyperlink";
import GreenCheckMark from "../main/subcomponents/GreenCheckMark";
import LoadingSection from "../main/subcomponents/LoadingSection";

import ReactMarkdown from "react-markdown";

import ArrowUpCircleIcon from "../../imgs/arrow-up-circle-icon.png";
import ChevronDownIcon from "../../imgs/chevron-down-icon.png";
import ChevronRightIcon from "../../imgs/chevron-right-icon.png";
import ChevronUpIcon from "../../imgs/chevron-up-icon.png";
import DoubleChevronRightIcon from "../../imgs/double-chevron-right-icon.png";
import NoEssayFeedback from "../../imgs/no-essay-feedback.png";
import BaseButton from "../base/BaseButton";
import blackAthenaV2 from "../../imgs/black-athena-v2.png";
import BlueZapIcon from "../../imgs/blue-zap-icon.png";
import GradeIcon from "../../imgs/grade-icon.png";

import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import {
    deleteAllChatMessages,
    deleteChatMessage,
    doesAthenaProModelExist,
    fetchApplicationCollegeEssays,
    fetchChatHistory,
    submitQuestion,
    pollResponse,
    fetchPreviousEssayGradeSubmissions,
    getHistoryItem,
} from "../../api/apiCalls";

import { FormatModelResponse, ExtractErrorMessage } from "../main/utils/utils";

export default function ApplicationSidebar() {
    const { showSidebar, setShowSidebar, activeSidebarTab, setActiveSidebarTab } = useApplicationContext();

    const handleSwitchTab = (tab) => {
        setActiveSidebarTab(tab);
    };

    return (
        <div
            className={`${styles.athena_sidebar_container} ${
                showSidebar ? styles.open_sidebar : styles.closed_sidebar
            }`}
        >
            <div className={styles.sidebar_header}>
                {showSidebar && (
                    <BaseButton
                        className={`${styles.button} ${styles.close_button}`}
                        onClick={() => setShowSidebar(false)}
                    >
                        <img
                            className={styles.button_icon}
                            src={DoubleChevronRightIcon}
                            alt="button for closing athena sidebar"
                        />
                    </BaseButton>
                )}
            </div>
            <div className={styles.tabs_container}>
                <div
                    className={`${styles.tab} ${activeSidebarTab === "chat" && styles.active_tab}`}
                    onClick={() => handleSwitchTab("chat")}
                >
                    Essay Guidance
                </div>
                <div
                    className={`${styles.tab} ${activeSidebarTab === "review" && styles.active_tab}`}
                    onClick={() => handleSwitchTab("review")}
                >
                    Report Card
                </div>
            </div>
            {showSidebar && (
                <div className={styles.sidebar_content_container}>
                    {activeSidebarTab === "chat" ? <AthenaChat /> : <EssayReviews />}
                </div>
            )}
        </div>
    );
}

function MessageBubble(props) {
    const { userProfile } = useAuthContext();
    const { message, sender, messageType } = props;
    const isUserMessage = sender === "user";
    const userProfileImg = userProfile.profile_image || blackAthenaV2;

    return (
        <div className={`${isUserMessage ? styles.user_message_bubble_container : styles.message_bubble_container}`}>
            <div className={styles.message_bubble}>
                <div className={`${isUserMessage ? styles.user_message_bubble_header : styles.message_bubble_header}`}>
                    {/* If we only have user messages, we don't need the confusing order 1, 2, 3 css for these messages */}
                    <img
                        className={`${isUserMessage ? styles.user_message_bubble_icon : styles.message_bubble_icon}`}
                        src={isUserMessage ? userProfileImg : blackAthenaV2}
                        alt="message sender"
                    />
                    <span
                        className={`${
                            isUserMessage ? styles.user_message_bubble_sender : styles.message_bubble_sender
                        }`}
                    >
                        {isUserMessage ? "You" : "Athena"}
                    </span>
                    <span
                        className={`${
                            isUserMessage ? styles.user_message_bubble_message_type : styles.message_bubble_message_type
                        }`}
                    >
                        {messageType}
                    </span>
                </div>
                <div className={styles.message_bubble_message}>
                    <ReactMarkdown>{FormatModelResponse(message)}</ReactMarkdown>
                </div>
            </div>
        </div>
    );
}

function AthenaChat(props) {
    const [chatInput, setChatInput] = useState("");
    const [formattedMessageList, setFormattedMessageList] = useState([]);
    const navigate = useNavigate();

    const { essayId, initialMessage, messages, setMessages, isBrainstormLoading, isProgressCheckLoading } =
        useApplicationContext();

    const handleClearHistoryClick = () => {
        deleteAllChatMessages(essayId)
            .then((response) => {
                return fetchChatHistory(essayId)
                    .then((response) => {
                        const rawChatHistory = response.data.chat_history;
                        const formattedChatHistory = rawChatHistory.map((chat) => {
                            return {
                                id: chat.id,
                                message: chat.message,
                                sender: chat.sender,
                                messageType: chat.message_type,
                            };
                        });
                        if (!formattedChatHistory || formattedChatHistory.length === 0) {
                            setMessages([initialMessage]);
                        } else {
                            setMessages([...formattedChatHistory]);
                        }
                    })
                    .catch((error) => {
                        toast.error(`Error fetching chat history: ${error.response.data.error}`);
                        setMessages([initialMessage]);
                    });
            })
            .catch((error) => {
                toast.error(`Error deleting message: ${error.response.data.error}`);
            });
    };

    useEffect(() => {
        const formattedMessages = messages.map((message, index) => {
            const formattedMessageType = message.messageType
                ?.split("_")
                .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                .join(" ");
            return (
                <MessageBubble
                    key={message.id + index}
                    message={message.message}
                    sender={message.sender}
                    messageType={formattedMessageType}
                />
            );
        });
        setFormattedMessageList(formattedMessages);
    }, [messages, setFormattedMessageList]);

    return (
        <div className={styles.athena_chat}>
            <div className={styles.athena_chat_container}>{formattedMessageList}</div>
            {(isBrainstormLoading || isProgressCheckLoading) && (
                <div className={styles.loading_image}>
                    <LoadingSection shouldShowText={false} />
                </div>
            )}
            <div className={styles.athena_chat_footer}>
                <p className={styles.athena_chat_notice}>
                    Complete your{" "}
                    <span className={styles.athena_chat_background_link} onClick={() => navigate("/background")}>
                        background
                    </span>{" "}
                    for personalized feedback!
                </p>
                <BaseButton className={styles.clear_history_button} onClick={handleClearHistoryClick}>
                    Clear History
                </BaseButton>
            </div>
        </div>
    );
}

function GradeDisclaimer(props) {
    return (
        <div className={styles.note_container}>
            <p className={styles.note}>
                Note: Essay grades are a heuristic and can fluctuate up and down slightly (e.g. from a B to a B+ or
                vice-versa) but generally not more than that. Think of this as how your essay roughly compares to other
                successful essays!
            </p>
            <p className={styles.note}>
                Note: An A or even an A- is meant to be rare. When our platform gives that grade out, it means it has
                satisfied all the core requirements that were present across thousands of essays that actually worked.
                When that grade comes through, you know you have an extremely strong chance of having close to a
                "perfect" essay.
            </p>
        </div>
    );
}

function EssayFeedback(props) {
    const { errorMessageRef } = props;
    const { userProfile } = useAuthContext();
    const {
        applicationInfo,
        modelResponse,
        gradeErrorMessage,
        handleSubmitGradeRequest,
        isEssaySubmissionLoading,
        currentRequestId,
        editorStateStr,
    } = useApplicationContext();
    const navigate = useNavigate();

    const handleGradeClick = () => {
        try {
            handleSubmitGradeRequest(editorStateStr);
        } catch (error) {
            toast.error(error.message);
            return;
        }
    };

    const [doesProModelExist, setDoesProModelExist] = useState(false);

    useEffect(() => {
        doesAthenaProModelExist(applicationInfo.schoolCode)
            .then((response) => {
                setDoesProModelExist(response.data.does_pro_model_exist);
            })
            .catch((error) => {
                toast.error(`Error checking for Athena Pro models: ${error.response.data.error}`);
                setDoesProModelExist(false); // Default to false if there's an error
            });
    }, [applicationInfo.schoolCode]);

    const isProUser = userProfile.membership_plan === "pro";

    return (
        <div className={styles.essay_feedback_container}>
            {modelResponse || isEssaySubmissionLoading || gradeErrorMessage ? (
                <div className={styles.essay_feedback}>
                    <div className={styles.essay_feedback_header}>
                        {doesProModelExist ? (
                            <div className={styles.sidebar_pro_upsell_container}>
                                {isProUser ? (
                                    <div className={styles.pro_user_notice}>
                                        <p>You're currently getting school-specific feedback for</p>
                                        <p>
                                            🎉<strong>{applicationInfo.schoolName}</strong>🎉
                                        </p>
                                    </div>
                                ) : (
                                    <>
                                        <span className={styles.sidebar_pro_upsell}>
                                            Want to use our tailored model for{" "}
                                            <strong>{applicationInfo.schoolName}</strong>?
                                        </span>
                                        <BaseButton
                                            className={styles.upgrade_button}
                                            onClick={() => navigate("/pricing")}
                                        >
                                            <img
                                                className={styles.action_button_icon}
                                                src={BlueZapIcon}
                                                alt="upgrade icon"
                                            />
                                            <div className={styles.action_button_text}>Upgrade to Pro</div>
                                        </BaseButton>
                                    </>
                                )}
                            </div>
                        ) : null}
                        <div className={styles.sidebar_section_title}>Athena's feedback</div>
                        <div className={styles.sidebar_section_subtitle}>
                            {/* TODO (Eugene): Make this less jank */}
                            Graded using Athena's {doesProModelExist && isProUser ? "Pro" : "Flagship"} Model
                        </div>
                        {/* <div className={styles.essay_feedback_timestamp}>June 4, 1969, 4:20 PM</div> we don't need this to instantly display */}
                    </div>
                    <div className={styles.loading_image}>
                        {isEssaySubmissionLoading ? (
                            <LoadingSection shouldShowText={true} />
                        ) : (
                            modelResponse && <GreenCheckMark />
                        )}
                    </div>
                    {modelResponse && (
                        <div className={styles.essay_feedback_content}>
                            <InterpretFeedbackHyperlink />
                            <GradeDisclaimer />
                            <div className={styles.model_response_container}>
                                <ReactMarkdown>{FormatModelResponse(modelResponse)}</ReactMarkdown>
                            </div>
                        </div>
                    )}
                    {gradeErrorMessage && (
                        <div ref={errorMessageRef} className={styles.grade_error_message}>
                            {gradeErrorMessage}
                        </div>
                    )}

                    {modelResponse && (
                        <>
                            <div className={styles.divider} />
                            <LightweightResponseFeedback currentRequestId={currentRequestId} />
                        </>
                    )}
                </div>
            ) : (
                <div className={styles.no_essay_feedback}>
                    <img src={NoEssayFeedback} className={styles.no_essay_feedback_img} alt="no feedback" />
                    <h3 className={styles.no_essay_feedback_title}>No feedback yet</h3>
                    <p className={styles.no_essay_feedback_text}>
                        Submit your draft when you're ready to receive personalized feedback. Ready to start?
                    </p>
                    <BaseButton className={styles.grade_button} onClick={handleGradeClick}>
                        <img className={styles.grade_icon} src={GradeIcon} alt="Grade Icon" />
                        Grade
                    </BaseButton>
                </div>
            )}
        </div>
    );
}

function PreviousSubmissionItem(props) {
    const { item, setSelectedItem, setShowPreviousSubmissionDisplay } = props;
    const handleClick = () => {
        setSelectedItem(item);
        setShowPreviousSubmissionDisplay(true);
    };
    return (
        <li className={styles.previous_submissions_list_item} onClick={handleClick}>
            <div className={styles.submission_info}>
                <span className={styles.submission_preview}>{item.essayPreview}</span>
                <span className={styles.submission_date}>{item.date}</span>
            </div>
            <img className={styles.chevron} src={ChevronRightIcon} alt="arrow" />
        </li>
    );
}

function PreviousSubmissionDisplay(props) {
    const { selectedItem, setShowPreviousSubmissionDisplay } = props;
    const [submission, setSubmission] = useState(null);

    // Fetch selected history item
    useEffect(() => {
        getHistoryItem(selectedItem.historyId)
            .then((response) => {
                const rawSubmission = response.data;
                const formattedSubmission = {
                    essay: rawSubmission.initial_essay,
                    modelResponse: rawSubmission.model_response,
                    // date: new Date(rawSubmission.created_at).toLocaleString(), not currently used
                    athenaModel: rawSubmission.athena_model,
                    supplementalEssayCategory: rawSubmission.supplemental_essay_category,
                };
                setSubmission(formattedSubmission);
            })
            .catch((error) => toast.error(`Error fetching history item: ${error.response.data.error}`));
    }, []);

    let athenaModel = "";
    switch (submission?.athenaModel) {
        case "flagship":
            athenaModel = "Flagship";
            break;
        case "pro":
            athenaModel = "Athena Pro";
            break;
        default:
            break;
    }

    return (
        <div className={styles.previous_submission_display_container}>
            <div className={styles.submission_header}>
                <div className={styles.submission_info}>
                    <div className={styles.submission_name}>{selectedItem?.essayPreview}</div>
                    <div className={styles.submission_timestamp}>{selectedItem?.date}</div>
                </div>
                <BaseButton className={`${styles.button}`} onClick={() => setShowPreviousSubmissionDisplay(false)}>
                    <img
                        className={styles.button_icon}
                        src={DoubleChevronRightIcon}
                        alt="button for closing athena sidebar"
                    />
                </BaseButton>
            </div>
            <div className={styles.submission_body}>
                <div className={styles.submission_essay_container}>
                    <div className={styles.submission_essay_title}>Your Submission</div>
                    <div>{submission?.essay}</div>
                </div>
                <div className={styles.submission_model_response_container}>
                    <div className={styles.submission_model_response_header}>
                        <div className={styles.submission_model_response_title}>Athena's feedback</div>
                        <div className={styles.submission_model_response_athena_model}>{athenaModel + " Model"}</div>
                    </div>
                    <div className={styles.model_response_container}>
                        <ReactMarkdown>{FormatModelResponse(submission?.modelResponse)}</ReactMarkdown>
                    </div>
                </div>
            </div>
        </div>
    );
}

function EssayReviews(props) {
    const [showSubmission, setShowSubmission] = useState(false);
    const [showPreviousSubmissions, setShowPreviousSubmissions] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const {
        essaySubmission,
        handleSubmitGradeRequest,
        currentRequestId,
        modelResponse,
        setModelResponse,
        gradeErrorMessage,
        setGradeErrorMessage,
        isEssaySubmissionLoading,
        setIsEssaySubmissionLoading,
        editorStateStr,
        previousSubmissions,
        setPreviousSubmissions,
        essayId,
        showPreviousSubmissionDisplay,
        setShowPreviousSubmissionDisplay,
    } = useApplicationContext();
    const errorMessageRef = useRef(null);
    const { fetchHistoryItemPreviews } = useContext(SidebarContext);

    useEffect(() => {
        let intervalId;

        const shouldPollResponse = currentRequestId !== "" && modelResponse === "" && gradeErrorMessage === "";

        function handlePollResponse(data) {
            if (data.gpt_response_status === "finished") {
                setModelResponse(data.model_response);
                fetchHistoryItemPreviews(); // This will retrigger sidebar history preview
                setIsEssaySubmissionLoading(false);
                fetchPreviousEssayGradeSubmissions(essayId)
                    .then((response) => {
                        const rawPreviousSubmission = response.data.previous_submissions;
                        const formattedPreviousSubmissions = rawPreviousSubmission.map((prevSubmission) => {
                            return {
                                essayPreview: prevSubmission.essay_preview,
                                date: new Date(prevSubmission.created_at).toLocaleString(),
                                historyId: prevSubmission.id,
                            };
                        });
                        setPreviousSubmissions(formattedPreviousSubmissions);
                    })
                    .catch((error) => toast.error(`Error fetching previous submissions: ${error.response.data.error}`));
            } else if (data.gpt_response_status === "error") {
                setGradeErrorMessage(data.error_reason);
                setIsEssaySubmissionLoading(false);
            }
        }

        if (shouldPollResponse) {
            // Set up the interval
            intervalId = setInterval(() => {
                pollResponse(currentRequestId)
                    .then((response) => {
                        handlePollResponse(response.data);
                    })
                    .catch((error) => {
                        setIsEssaySubmissionLoading(false);
                        setGradeErrorMessage(ExtractErrorMessage(error)); // Why id ExtractErrorMessage capitalized?
                    });
            }, 10000); // 10000 milliseconds = 10 seconds
        }

        // Clean up the interval on unmount or when currentRequestId changes
        return () => {
            if (intervalId) {
                clearInterval(intervalId);
            }
        };
    }, [
        currentRequestId,
        modelResponse,
        gradeErrorMessage,
        fetchHistoryItemPreviews,
        setModelResponse,
        setIsEssaySubmissionLoading,
        setGradeErrorMessage,
    ]); // Effect depends on currentRequestId

    useEffect(() => {
        if (gradeErrorMessage && errorMessageRef.current) {
            setTimeout(() => {
                errorMessageRef?.current?.scrollIntoView({ behavior: "smooth" });
            }, 500); // delay scroll to error to avoid interfering with sidebar opening animation
        }
    }, [gradeErrorMessage, errorMessageRef]); // We might not need this because it should already be in view?

    const handleShowSubmission = () => {
        if (!essaySubmission) return;
        setShowSubmission(!showSubmission);
    };

    return (
        <div className={styles.athena_essay_reviews}>
            <div className={styles.essay_submission}>
                <div className={styles.essay_submission_header} onClick={handleShowSubmission}>
                    <span className={styles.sidebar_section_title}>Your Submission</span>
                    <img
                        className={`${styles.chevron} ${showSubmission && styles.rotated_chevron}`}
                        src={ChevronDownIcon}
                        alt="chevron"
                    />
                </div>
                {/* TODO (Eugene): Make sure this essay submission can display markdown */}
                {showSubmission && essaySubmission && (
                    <div className={styles.essay_submission_content}>{essaySubmission}</div>
                )}
            </div>
            <EssayFeedback errorMessageRef={errorMessageRef} />
            <div className={styles.previous_submissions}>
                <div
                    className={styles.previous_submissions_header}
                    onClick={() => setShowPreviousSubmissions(!showPreviousSubmissions)}
                >
                    <span className={styles.sidebar_section_title}>Previous Submissions</span>
                    <img
                        className={`${styles.chevron} ${showPreviousSubmissions && styles.rotated_chevron}`}
                        src={ChevronUpIcon}
                        alt="chevron"
                    />
                </div>
                {showPreviousSubmissions &&
                    (previousSubmissions.length > 0 ? (
                        <ul className={styles.previous_submissions_list}>
                            {previousSubmissions.map((item, index) => (
                                <PreviousSubmissionItem
                                    key={index}
                                    item={item}
                                    setSelectedItem={setSelectedItem}
                                    setShowPreviousSubmissionDisplay={setShowPreviousSubmissionDisplay}
                                />
                            ))}
                        </ul>
                    ) : (
                        <div className={styles.no_previous_submissions}>No previous submissions</div>
                    ))}
            </div>
            {showPreviousSubmissionDisplay && (
                <PreviousSubmissionDisplay
                    selectedItem={selectedItem}
                    setShowPreviousSubmissionDisplay={setShowPreviousSubmissionDisplay}
                />
            )}
        </div>
    );
}
