import styles from "../../../styles/content/articles/GeneralArticle.module.css";
import eugene from "../../../imgs/eugene.jpg";
import admissions from "../../../imgs/admissions.png";

export const StaticArticlePath = "/content/interview-with-an-ivy-league-admissions-officer";

function Article() {
    return (
        <div className={styles.article_outer_container}>
            <div className={styles.article_inner_container}>
                <div className={styles.header_container}>
                    <div className={styles.title}>Interview With an Ivy League Admissions Officer</div>
                    <div className={styles.author_container}>
                        <img className={styles.author_img} src={eugene} alt="eugene"/>
                        <div className={styles.author_info}>
                            <div className={styles.author_name}>Eugene Shao</div>
                            <div className={styles.author_description}>Caltech Class of 2022</div>
                        </div>
                    </div>
                </div>
                
                <div className={styles.article_body}>
                    <img className={styles.article_img} src={admissions} alt="admissions"/>
                    <div className={styles.paragraph}>
                        We recently sat down with a current Ivy League Admissions Officer to get an inside look on how the admissions process works from the university side. Here are the most important parts of what he had to say which you can help use to formulate strategies of how to approach this process:
                        <br></br><br></br>
                        <span className={styles.bold_and_italic}>Q: First up, can you walk us through a behind-the-scenes look at an admissions committee meeting? How are decisions made, and what factors often lead to the most discussion or debate as to whether an applicant should be admitted?</span>
                        <br></br><br></br>
                        Most colleges try to read their applications by territory, because they need to understand the environment that students are coming from. The first reader will do an assessment, and if they feel the candidate is a strong admit, then the application will go to a second round where the file is reviewed. From there, it goes to the committee round. 
                        <br></br><br></br>
                        Often at this stage, the Dean of Director of Admissions looks at where we are at that stage and the strength of the pool. It could be that we want more students from rural America and haven't yet admitted anyone from there, just to provide an example. And at that point, the person in charge of that territory will make the case for an applicant.
                        <br></br><br></br>
                        At this point, it is never about the academic qualifications of the applicant. By this point, everyone that has made it is sufficiently qualified to succeed at our university so it comes down to the next level — engagement in community, activities outside of your classroom, do you have a sufficient depth of interest in your major, how is your intellectual curiosity, lived experiences, are you the right fit for our institution.
                        <br></br><br></br>
                        <span className={styles.bold}>What's the takeaway:</span> That last bit is the key — crafting an amazing college application is all about what you do outside of the classroom (although success in the classroom is a prerequisite, there's no need to go totally overboard at the expense of you showing that you have deep interests that extend beyond just your school curriculum).
                        <br></br><br></br>
                        <span className={styles.bold_and_italic}>Q: What makes a recommendation letter stand out? Are there any common characteristics in the most impactful letters you have read?</span>
                        <br></br><br></br>
                        There are 2 kinds of distinct recommendation letters — those from your guidance counselor and those from your teachers. And we are looking at different things when evaluating each of these. 
                        <br></br><br></br>
                        For the guidance counselor recommendation, we're looking for general knowledge of how that student is in their community and whether they are taking full advantage of the rigor of the program offered at school. Particularly at larger schools, we don't expect the guidance counselors to know the students well at a personal level.
                        <br></br><br></br>
                        For the teacher recommendation, we expect deeper insight to understanding how that student is in the classroom setting and what types of behaviors and personality traits they express. The most important thing here are concrete examples rather than vague descriptions.
                        <br></br><br></br>
                        <span className={styles.bold}>What's the takeaway:</span> Concrete examples. Even if a teacher really likes you, if they are unable to point out specific examples of you exhibiting some trait that is admirable, then your recommendation letter will be weak. This means you need to set these up for yourself in the classroom — in a group assignment, take the lead; or when your classmates are struggling with assignments, help them out; or if the person next to you isn't understanding a concept after a lecture, come up with a more intuitive way to explain it. These types of behaviors are the sort of concrete examples that will help your recommendation letters go from good to fantastic.
                        <br></br><br></br>
                        <span className={styles.bold_and_italic}>Q: What are some common missteps or oversights you see in applications that might unintentionally weaken a candidate's chances?</span>
                        <br></br><br></br>
                        There are four mistakes I've seen over the years that pop up frequently.
                        <br></br><br></br>
                        First is asking for too much help on their essays. When too many other people edit their essay directly, their own voice doesn't shine through even if the essay is well-written. And we're looking for authenticity in voice. A weaker application is one where I can't intuitively match the voice of the person in the essays to the person I see on paper from the extracurriculars and recommendation letters.
                        <br></br><br></br>
                        Second is that we want engaged community members. Some students don't do much outside the classroom except focus on perfect grades and doing as many APs as possible. But that won't impress colleges, particularly as you get to the later rounds of the admissions process.
                        <br></br><br></br>
                        Third is the opposite of the second. Sometimes students get too engrossed in their activities and let their academics slide and their grades drop. Good grades are necessary but not sufficient.
                        <br></br><br></br>
                        Fourth is perhaps the most common mistake. Many students underindex on time spent on their supplemental essays. They spend all their time perfecting their Common App Essay but then they rush their supplemental essays for each college. And it's quite obvious when something has been rushed. It is important to ask yourself, “why is this university asking me this supplemental question”. Take the time to process it and really spend time to craft a response that is authentically you.
                        <br></br><br></br>
                        <span className={styles.bold}>What's the takeaway:</span> Planning is so so important when it comes to the admissions process. When you start too late, you'll be rushed. And that's when you'll start asking for too much help on your essays and rushing them which is a surefire path to weakening your application. Start writing essays, particularly Supplemental Essays, as early as possible. Ideally, as soon as they are released.
                        <br></br><br></br>
                        <span className={styles.bold_and_italic}>Q: Tell me about the top 2-3 applicants you have seen in the past where you thought to yourself “we need this person here at our university”. Specifically, I want you to think about students who did NOT have the most impressive accomplishments but rather those whose essays and personality blew you away.</span>
                        <br></br><br></br>
                        This has to do with how students use the resources available to them. Those students where neither parent went to college, or those living in rural America with no resources... when I look at their accomplishments, and think to myself — how the hell did they do that? Those are the ones where I think that we need this person here at our university. It's about whether applicants have the passion and intellectual curiosity and drive to push past the resources you have and take the next step to the benefit of yourself and those around you. Even if someone does come from a more affluent background, I still love seeing when people use the resources available to them and exceed the expectations of those around them simply because of their love for a field.
                        <br></br><br></br>
                        <span className={styles.bold}>What's the takeaway:</span> Your application is always taken in the context of the area you grew up in and your background. Intellectual curiosity is generally highly valued by universities and so showing how your love for a field pushed you beyond conventional opportunities available at your school or in your community can really take your application to the next level.
                        <br></br><br></br>
                    </div>
                </div>
            </div>
            
        </div>
    );
}

export function InterviewWithAnIvyLeagueAdmissionsOfficer() {
    return (
        <Article />
    );
}