import styles from "../../../styles/content/articles/GeneralArticle.module.css";
import eugene from "../../../imgs/eugene.jpg";
import laptop from "../../../imgs/laptop.jpg";

export const StaticArticlePath = "/content/the-biggest-mistake-every-applicant-makes";

function Article() {
    return (
        <div className={styles.article_outer_container}>
            <div className={styles.article_inner_container}>
                <div className={styles.header_container}>
                    <div className={styles.title}>The Biggest Mistake Every Applicant Makes</div>
                    <div className={styles.author_container}>
                        <img className={styles.author_img} src={eugene} alt="eugene"/>
                        <div className={styles.author_info}>
                            <div className={styles.author_name}>Eugene Shao</div>
                            <div className={styles.author_description}>Caltech Class of 2022</div>
                        </div>
                    </div>
                </div>
                
                <div className={styles.article_body}>
                    <img className={styles.article_img} src={laptop} alt="laptop"/>
                    <div className={styles.paragraph}>
                        While I was a private college admissions consultant, I chatted with several admissions officers and directors to get insights on what <span className={styles.bold}>actually worked</span> when it came to college applications. And without a doubt, the single thing that was repeatedly emphasized by all of them was <span className={styles.bold}>demonstrating fit</span>.
                        <br></br><br></br>
                        What is fit? Well, different universities admit different types of people. And most universities are quite transparent about who they want to admit. For example,
                        <br></br>
                        Caltech: <a href="https://www.admissions.caltech.edu/apply/what-we-look-for">https://www.admissions.caltech.edu/apply/what-we-look-for</a>
                        <br></br>
                        Yale: <a href="https://admissions.yale.edu/what-yale-looks-for">https://admissions.yale.edu/what-yale-looks-for</a>
                        <br></br>
                        Dartmouth: <a href="https://admissions.dartmouth.edu/glossary-question/what-does-dartmouth-look-applicant">https://admissions.dartmouth.edu/glossary-question/what-does-dartmouth-look-applicant</a>
                        <br></br>
                        Stanford: <a href="https://admission.stanford.edu/apply/overview/index.html">https://admission.stanford.edu/apply/overview/index.html</a>
                        <br></br>
                        MIT: <a href="https://mitadmissions.org/apply/process/what-we-look-for/ ">https://mitadmissions.org/apply/process/what-we-look-for/ </a>
                        <br></br><br></br>
                        In your application, it is important to showcase the qualities that each university is explicitly looking for. So what is the biggest mistake most students make? Instead of focusing on showing the universities how they are a wonderful fit as an applicant, they are more focused on proving that “they are good enough” to attend the university.
                        <br></br><br></br>
                        Look no further than Ajay’s MIT application journey. After Ajay was deferred, there was an opportunity to provide an update in February regarding what he had been up to since applying to MIT. He elected to discuss additional competition awards in science and debate, which frankly wouldn’t have moved the needle at all. Instead, he should have focused on showing why he was the right fit for MIT — whether through experiences that showed intellectual curiosity or risk-taking (pieces which MIT themselves say makes the right fit for an undergrad). Or by discussing how his conversations with existing MIT undergrads had shown him unique ways he could contribute to their campus. These were genuine traits of his, but he didn’t discuss them, because he thought it was more important to show that he was academically impressive. Now, this isn’t to say this would have guaranteed his acceptance — again, no applicant knows why they were accepted or rejected. However, there are certain best practices that exist when it comes to writing admissions essays — and demonstrating fit is the most common piece that students completely miss out on.
                        <br></br><br></br>
                        But it’s not as simple as just checking off boxes on the admissions site. Sure, it’s important to show intellectual curiosity, risk-taking, or whatever else your desired university is looking for. But admissions officers can quickly see through you if you don’t genuinely possess those traits. Remember, they have been reading applications for many years. And have seen many students who have probably tried to fake these traits. Comparatively, this is your first year trying to write these essays. If you try to lie about this and who you are, at worst your application will be tossed out for being inauthentic; and at best, you’ll lose your genuine voice that defines who you are — and as a result, your application will be weaker for it.
                        <br></br><br></br>
                        So it’s important to think how to authentically present yourself as someone that satisfies these constraints while also staying true to yourself and letting your own unique voice shine through in your essays. <span className={styles.bold}>This takes time.</span> So once again, the actionable piece of advice is to start early. Begin reflecting on who you are and what defines you as a person as early as the end of your junior year. Success in this process will take a great deal of personal reflection and take it from personal experience, anything rushed will not turn out well.
                        <br></br><br></br>
                        And so here's the final takeaway: once you have finished your essays, ask yourself, <span className={styles.bold}>"Have I demonstrated that I am the perfect fit for this university specifically".</span> If the answer is No, it's time to go back and rewrite those supplemental essays.
                    </div>
                </div>
            </div>
            
        </div>
    );
}

export function TheBiggestMistakeEveryApplicantMakes() {
    return (
        <Article />
    );
}