import styles from "../../styles/nacacDemo/NacacDemo.module.css"
import React from "react";
import { useState } from "react";

// FIRST ESSAY
export function SampleEssay1() {
    return (
        <React.Fragment>
            <h3>
                Essay 1:
            </h3>
            <p>
                As a first-generation immigrant, I had to persevere early in
                life. I had arrived in the United States without knowing
                English. Although this was exasperating at times, I never gave
                up. Every mistake I made meant learning something new, and,
                soon, I noticed significant progress in my English ability.
                Through it all, I learned the importance of failure and hard
                work. Family separation has been embedded in my life. Shortly
                after my family settled down in New Hampshire, my father got a
                job offer in California. He needed to maintain his work permit
                so that we could stay in the United States, and moving was too
                financially risky, so we separated. Because of his absence, my
                life dramatically changed. Watching my mother work long, tiring
                hours and endure the emotional stress of separation simply to
                ensure my education has motivated me to always try my best,
                whether it is in the classroom or at home. She taught me to
                persevere through temporary obstacles, knowing that success is
                the result of hard work and dedication.
            </p>
            <p>
                Every afternoon, I would return home to an empty house. Sweaty
                from biking and exhausted from a long day of classes, I would
                clamber upstairs to my room and grab the math book on my
                dresser. There is nothing more relieving to a stressed-out soul
                than immersing oneself in the concrete concepts of mathematics.
                Despite everything around me being uncertain, math was always
                there for me to hang on. Originally, I solved math problems
                because it helped me cope with emotional stress; each solved
                problem was like a victory against my troubles. Also, a smile
                always crept across my mom's exhausted face when she returned
                from work and saw me happy and concentrated at work. I pretended
                not to notice, but her happiness always gave me a surge of
                warmth because I made her day. Slowly, my interest crept out of
                the confines of my room. I couldn't wait for school to end so
                that I could return home and work on math problems. Amidst a
                chaotic classroom filled with energetic middle school students,
                one would find me absorbed in a math book.
            </p>
            <p>
                {" "}
                Math was my oasis for escaping my troubles. Because of how
                significantly it helped me, I felt like it was my job to spread
                the help to others. My mom taught inner-city students, and she
                often told me stories of their raucous behavior and lack of
                receptiveness to her instruction. By ninth grade, I wanted to
                help. When I walked into the classroom for the first time, I was
                met by three students in old shirts and torn shoes. I had
                entered a new world. As we slowly built a connection over
                several visits, they told me about their lives, filled with
                absent parents and gang violence. They worked minimum-wage jobs
                to support their siblings and had few resources and little
                motivation. By stepping in, I helped bring something certain
                into their lives, and over time, a friendship started to grow.
                Their eyes lit up when I came, and their eagerness to learn
                blossomed. In the mix of an unstable life, math was a stable
                means that kept them moving forward.
            </p>
            <p>
                Reflecting on my childhood, I realize how my past influenced me,
                especially in spurring personal growth and perseverance that
                cultivated my passion for mathematics. My experiences of using
                math to solve real-life problems have also invigorated my desire
                to use math in real-life applications.
            </p>
        </React.Fragment>
    );
}

export function SampleFeedback1() {
    return (
        <React.Fragment>
            <h3>
                Feedback 1:
            </h3>
            <h2 className={styles.margin_top_0px}>Overall Grade: B</h2>
            <p className={styles.bottom_padding_16px}>
                The essay did not trigger any red flags, showed a strong
                narrative focused on a single theme, and subtly pointed out the
                applicant's interest in STEM, highlighting their persistent
                character and love for mathematics. However, it could benefit
                from a stronger and more unique voice, as well as more effective
                integration of the listed successful techniques to raise this
                grade further.
            </p>
            <h3>Requirement: The Golden Rule</h3>
            <ul>
                <li>
                    <b>Importance:</b> This requirement is essential because it
                    generates emotional investment from the reader, which could
                    strongly influence the admissions committee's decision
                </li>
                <li>
                    <b>Subgrade:</b> A-
                </li>
                <li>
                    <b>Excerpt that satisfies the requirement:</b> "Through it
                    all, I learned the importance of failure and hard work."
                </li>
                <li>
                    <b>Explanation:</b> This line demonstrates relatable
                    struggle and determination, potentially making readers
                    emotionally invested
                </li>
            </ul>
            <h3>Requirement: Narrative Focused on One Main Theme </h3>
            <ul>
                <li>
                    <b>Importance:</b> Concentrating on one theme allows for a
                    deeper emotional connection, necessary for a strong essay
                </li>
                <li>
                    <b>Subgrade:</b> A
                </li>
                <li>
                    <b>Excerpt that satisfies the requirement:</b> "Math was my
                    oasis for escaping my troubles."
                </li>
                <li>
                    <b>Explanation:</b> This anchors the essay in a single,
                    coherent theme, affirming the role of mathematics in the
                    applicant's life and journey.
                </li>
            </ul>
            <h3>Requirement: STEM Nerd Subtlety</h3>
            <ul>
                <li>
                    <b>Importance:</b> It is critical for the essay to exude
                    genuine interest in STEM without boasting, reflecting
                    authenticity to the reader.
                </li>
                <li>
                    <b>Subgrade:</b> A-
                </li>
                <li>
                    <b>Excerpt that satisfies the requirement:</b> "There is
                    nothing more relieving to a stressed-out soul than immersing
                    oneself in the concrete concepts of mathematics."
                </li>
                <li>
                    <b>Explanation:</b> By expressing a deep connection to
                    mathematics in a personal context of stress relief, the
                    student subtly indicates genuine passion for STEM.
                </li>
            </ul>
            <h3>Requirement: Strong and Unique Voice</h3>
            <ul>
                <li>
                    <b>Importance:</b> A distinctive voice helps the reader
                    identify and remember the applicant among many others.
                </li>
                <li>
                    <b>Subgrade:</b> C+
                </li>
                <li>
                    <b>Excerpts to replace:</b> "Every mistake I made meant
                    learning something new.”
                </li>
                <li>
                    <b>Replacement Discussion:</b> The insight into how each
                    error in mathematics led to a deeper understanding of
                    personal resilience, highlighting the applicant's
                    introspective nature.
                </li>
                <li>
                    <b>Why:</b> A unique approach to describing their learning
                    process could showcase a more individualized voice.
                </li>
            </ul>
            <h3>Requirement: Pleasurable Read and Satisfying Ending</h3>
            <ul>
                <li>
                    <b>Importance:</b> An enjoyable and re-readable essay
                    increases the likelihood of the applicant being advocated
                    for.
                </li>
                <li>
                    <b>Subgrade:</b> B
                </li>
                <li>
                    <b>Excerpts to replace:</b> "Reflecting on my childhood, I
                    realize how my past influenced me...
                </li>
                <li>
                    <b>Replacement Discussion:</b> A more direct link between
                    the applicant's personal growth and their aspirations,
                    ending with a forward-looking, inspiring statement about
                    their future in mathematics.
                </li>
                <li>
                    <b>Why:</b> A stronger ending reaffirms the reader's
                    emotional investment and the thematic cohesiveness.
                </li>
            </ul>
            <h2>Techniques To Introduce:</h2>
            <ul>
                <li>
                    <b>
                        Reflections on Personal Identity and Self-Understanding:
                    </b>
                    <ul>
                        <li>
                            Replace the beginning background information with an
                            anecdote that reflects on the applicant's evolving
                            personal identity through overcoming language
                            barriers, to enhance self-understanding.
                        </li>
                    </ul>
                </li>
                <li>
                    <b>Subtle Humor or Wit:</b>
                    <ul>
                        <li>
                            Integrate subtle humor when recounting the academic
                            journey or math experiences, replacing some of the
                            more straightforward emotional statements to add
                            levity.
                        </li>
                    </ul>
                </li>
                <li>
                    <b>Intellectual Curiosity:</b>
                    <ul>
                        <li>
                            Infuse elements that demonstrate the applicant's
                            curiosity beyond just solving problems, perhaps by
                            introducing a particular math concept that the
                            applicant delved into fiercely out of pure interest,
                            to replace a general statement about doing math
                            problems.
                        </li>
                    </ul>
                </li>
            </ul>
            <h2>Red Flags:</h2>
            <p>
                Your essay does not violate any common Red Flags in Common App
                Essays.
            </p>
        </React.Fragment>
    );
}

// ESSAY 2
function SampleEssay2() {
    return (
        <React.Fragment>
            <h3>
                Essay 2:
            </h3>
            <p>
                I am a psychic who thinks in terms of fours and threes. Deal me any hand of Gin, and I can guarantee I’ll have you beat. I stare at the cards in my hand and see numbers moving in my mind. Like a mathematician at a chalkboard, I plan out my next move. I use logic, memory, and a little bit of luck to guess exactly what your hand looks like. The possible combinations seem endless—four Kings and a run of three, three nines and four Queens, a run of four and three sevens, and many, many more. What I love most about playing Gin is the predictability. While I may not know what’s coming, I can use what I already know to strategize, adapt, and have fun along the way.
            </p>
            <p>
                My Gin career began as a small child. My aunt taught me how to play the game while we were camping. My hands were so small that we had to use a chip clip to keep the cards in place. I was at first intimated by the “big kid game,” as I called it then, but soon I couldn’t get enough. I forced my entire family to play, and I even roped in the kids at the campsite next to us. My aunt, a mathematician, is a skilled Gin player. She passed her tips and tricks along to me. After a few years of playing, she was the only opponent I couldn’t beat.
            </p>
            <p>
                Last summer was the first time it finally happened. I bested her. I had a hand with three Aces and a run of Spades. I needed another Ace or a three or seven of Spades. When I drew that final Ace from the deck, I could hardly believe it. I paused to count my cards again. I drew my hands to my chest, looked up at my aunt slowly and triumphantly, and calmly declared, “Gin.” My aunt squealed and embraced me, proud of all the progress her protegee had made.
            </p>
            <p>
                This win came from a year of hard work. I read every book on Gin I could find at the library, watched countless YouTube videos, and became an expert on Gin’s more lively counterpart, Gin Rummy. Learning and practicing drew me into a huge online community of Gin enthusiasts. I never thought that I’d meet some of my best friends through a card game, but I did. Every night, we’d compete against each other. And with each match, my skills would sharpen like a knife on a honing steel. When I finally beat my aunt, I hadn’t just won the game. I’d won lifelong friends and greater reasoning skills.
            </p>
            <p>
                Gin players aren’t internationally recognized for their intellectual prowess like chess or Scrabble. I’ve learned other games and played them successfully, but nothing has come close to the joy and challenge I feel while playing Gin. I love predicting what your opponent holds and what you’ll draw next, betting on your perfect card being in the draw deck, chatting with your opponent as you deal the next round, and earning bragging rights after winning a match—all of it is the perfect mix of strategy and community. When I head off to college in the fall, the first thing I’ll pack will be a deck of cards.
            </p>
        </React.Fragment>
    );
}

function SampleFeedback2() {
    return (
        <React.Fragment>
            <h3>
                Feedback 2:
            </h3>
            <h2 className={styles.margin_top_0px}>Overall Grade: B</h2>
            <p className={styles.bottom_padding_16px}>
                After evaluating the essay against the criteria outlined in the requirements and considering adjustment suggestions and potential techniques, it can be concluded that the essay performs well in some areas while lacking in others. The essay's strengths in narrative, theme, and engaging content contribute positively to its evaluation. However, improvements in depth, STEM emphasis, and integration of additional techniques could enhance adherence to the requirements. By balancing these aspects, an overall grade of B has been determined for the essay.
            </p>
            <h3>Requirement 1: The Golden Rule</h3>
            <ul>
                <li>
                    <b>Title:</b> The Golden Rule
                </li>
                <li>
                    <b>Importance:</b>  It is essential for the reader to become emotionally invested in the applicant, evoking a connection that urges them to advocate for the applicant's admission.
                </li>
                <li>
                    <b>Subgrade: </b> A-
                </li>
                <li>
                    <b>Excerpt satisfying the requirement:</b> "My hands were so small that we had to use a chip clip to keep the cards in place."
                </li>
                <li>
                    <b>Explanation:</b> This childhood reminiscence endears the applicant to the reader, invoking a protective response.
                </li>
                <li>
                    <b>Excerpt satisfying the requirement:</b>  "I stared at the cards in my hand and see numbers moving in my mind."
                </li>
                <li>
                    <b>Explanation:</b> The applicant's depiction of their thought process while playing Gin immerses the reader in their experience, bolstering emotional investment.
                </li>
            </ul>
            <h3>Requirement 2: Narrative Focus on One Main Theme</h3>
            <ul>
                <li>
                    <b>Title:</b>  Narrative Focus
                </li>
                <li>
                    <b>Importance:</b>  A focused narrative on a single theme allows for the development of a strong connection between the reader and the applicant.
                </li>
                <li>
                    <b>Subgrade: </b> B+
                </li>
                <li>
                    <b>Excerpt to replace:</b>  "I read every book on Gin I could find at the library..."
                </li>
                <li>
                    <b>Replacement Discussion:</b> The intense dedication to mastering Gin could be expanded upon to illustrate personal growth and introspection.
                </li>
                <li>
                    <b>Why Better:</b>  It reinforces the narrative's focus and enhances the depth of the one main theme.
                </li>
            </ul>
            <h3>Requirement 3: STEM Nerd Indication</h3>
            <ul>
                <li>
                    <b>Title:</b>  STEM Nerd Indication
                </li>
                <li>
                    <b>Importance:</b> Subtly indicating a passion for STEM assures top-tier STEM institutions of the applicant's genuine interest.
                </li>
                <li>
                    <b>Subgrade: </b> C
                </li>
                <li>
                    <b>Excerpt to replace:</b> "I use logic, memory, and a little bit of luck..."
                </li>
                <li>
                    <b>Replacement Discussion:</b> This section could delve into the methodical and analytical aspects of strategizing in Gin, drawing a stronger parallel to STEM.
                </li>
                <li>
                    <b>Why Better:</b> It would subtly suggest the applicant's analytical skills, reinforcing their affinity for STEM without being overt.
                </li>
            </ul>
            <h3>Requirement 4: Strong and Unique Voice</h3>
            <ul>
                <li>
                    <b>Title:</b> Strong and Unique Voice
                </li>
                <li>
                    <b>Importance:</b> A distinctive voice in the essay creates a memorable application, personalizing the applicant for the reader.
                </li>
                <li>
                    <b>Subgrade: </b> B
                </li>
                <li>
                    <b>Excerpt to replace:</b> "Gin players aren’t internationally recognized for their intellectual prowess..."
                </li>
                <li>
                    <b>Replacement Discussion:</b> Use a more personal anecdote that highlights the applicant's unique perspective or personality traits.
                </li>
                <li>
                    <b>Why Better:</b> It emphasizes the essay's unique voice, ensuring that someone familiar with the applicant would recognize their individuality.
                </li>
            </ul>
            <h3>Requirement 5: Engaging Flow and Satisfying Ending</h3>
            <ul>
                <li>
                    <b>Title:</b> Engaging Flow and Satisfying Ending
                </li>
                <li>
                    <b>Importance:</b>  A well-flowing essay with a satisfying conclusion leaves a lasting impression and enhances the reader's advocacy.
                </li>
                <li>
                    <b>Subgrade: </b> A
                </li>
                <li>
                    <b>Excerpt satisfying the requirement:</b> "The possible combinations seem endless—four Kings and a run of three, three nines and four Queens..."
                </li>
                <li>
                    <b>Explanation:</b> This portion of the essay showcases a breezy rhythm and engaging topic transitions, making it an enjoyable read.
                </li>
            </ul>
            <h2>Techniques To Introduce:</h2>
            <ul>
                <li>
                    Introduced Techniques: No techniques included by previously admitted students can replace parts of your essay and improve the overall essay.
                </li>
            </ul>
            <h2>Red Flags:</h2>
            <p>
                Your essay does not violate any common Red Flags in Common App
                Essays.
            </p>
        </React.Fragment>
    );
}

// ESSAY 3
function SampleEssay3() {
    return (
        <React.Fragment>
            <h3>
                Essay 3:
            </h3>
            <p>
                Miss Linda’s living room. It was the lair of a black dragon, an uncontrollable beast that humiliated all who dared to cross its path. Yet, week after week, I would journey into its cave, sit before its gaping mouth, place my fingers on it white, daunting teeth, and wait for the torture to begin—my piano lesson.
            </p>
            <p>
                Despite my parents’ threats and bribery, I didn’t want to play. I loathed every minute I sat on the piano bench struggling through scales, arpeggios, and yet another piece. Most of all, I hated the fact that I felt like an utter failure.
            </p>
            <p>
                So I quit. Fed up with my constant begging and screaming, my parents stopped forcing me into the lair of the beast, but not without reminding me that I was throwing away something truly valuable. They were, of course, correct.
            </p>
            <p>
                As a condition of quitting lessons, I agreed to continue playing on my own. I would occasionally sit down at the piano—just often enough to appease my parents—and play simple, fun songs that kept the music in my fingers but didn’t challenge me. Nothing more.
            </p>
            <p>
                A few years later, a family friend requested that I give her daughter, Grace, a few lessons. I had no interest in subjecting anyone else to drudgery of piano lessons, but I was eager to earn some cash. I reluctantly agreed. Surprisingly, I found myself looking forward to giving Grace a lesson each week and eagerly selecting pieces she might like to learn. I truly began to regret quitting my own piano lessons. I started playing the piano more and more often and selecting more complex pieces, not just for Grace but also for myself. I didn’t see the instrument as a horrifying dragon anymore but as an outlet for my emotions and a source of pleasure, I happily began to rebuild my repertoire and improve my technique, working through the hard parts this time and realizing that the hard work only made me better.
            </p>
            <p>
                As the months went on, I wanted to share my newfound passion with others besides Grace. I wanted to share music with kids who didn’t necessarily have the opportunity for lessons, so I started a piano program at an inner-city school in Dayton—three miles and a world away from my safe, comfortable upbringing. Each week, I volunteer and give private piano lessons to four girls at the school, each from a different cultural background.
            </p>
            <p>
                On the first day of the lessons, my students tentatively placed their small fingers on the keyboard I had brought with me. I nervously watched as wide grins spread across their faces.
            </p>
            <p>
                That was over a year ago. The girls still look forward to their weekly lessons in Miss Elizabeth’s piano room, and I look forward to seeing them and the joy they get from the same activity that had once brought me such misery. To them, music is a privilege, but its really my privilege to be their teacher. I’ve also become their mentor, someone they come to with their second-grade problems and look to for encouragement and answers. I’m a familiar face at their school, and I love the sheepish smiles and random hugs I receive as I walk down the halls. There will surely be even more hugs this year as another teenage teacher and more students join the program.
            </p>
            <p>
                Ultimately, failure is really about success. Playing the piano has taught me that anything worth having requires hard work and humility. It takes courage and creativity to make the most of the opportunities that failure brings. Truly sharing yourself and your experiences with others is the key to bridging great differences—age, economics, culture—and changing not only someone else’s life but also your own. Never again will I pass up an opportunity to learn more, do more, or be more than I already am.
            </p>
        </React.Fragment>
    );
}

function SampleFeedback3() {
    return (
        <React.Fragment>
            <h3>
                Feedback 3:
            </h3>
            <h2 className={styles.margin_top_0px}>Overall Grade: A-</h2>
            <h3>Requirement: The Golden Rule</h3>
            <ul>
                <li>
                    The essay does a good job of making the reader root for the applicant through a relatable experience and emotional growth.
                </li>
                <li>
                    <b>Subgrade:</b> A-
                </li>
                <li>
                    <b>Excerpt satisfying the requirement:</b> "Yet, week after week, I would journey into its cave, sit before its gaping mouth, place my fingers on it white, daunting teeth, and wait for the torture to begin — my piano lesson." This vivid description draws the reader into the author's emotional world.
                </li>
                <li>
                    <b>Excerpt satisfying the requirement:</b> "Truly sharing yourself and your experiences with others is the key to bridging great differences—age, economics, culture—and changing not only someone else’s life but also your own." This reflection shows personal growth and emotional insight.
                </li>
            </ul>
            <h3>Requirement: Narrative and Thematic Focus </h3>
            <ul>
                <li>
                The essay is narratively cohesive, focusing on one main theme of overcoming personal struggles and finding passion.
                </li>
                <li>
                    <b>Subgrade:</b> A
                </li>
                <li>
                    <b>Excerpt satisfying the requirement:</b> "I started playing the piano more and more often and selecting more complex pieces, not just for Grace but also for myself." This shows internal development while maintaining thematic focus on piano.
                </li>
                <li>
                    <b>Excerpt satisfying the requirement:</b> "Ultimately, failure is really about success." This conclusion ties into the main theme, resulting in a focused narrative.
                </li>
            </ul>
            <h3>Requirement: STEM Nerd Subtlety </h3>
            <ul>
                <li>
                    The essay does not explicitly point out STEM interests, which could be integrated given the target application to top-tier STEM institutions.
                </li>
                <li>
                    <b>Subgrade:</b> C
                </li>
                <li>
                    <b>Excerpt Needing Replacement:</b> "Most of all, I hated the fact that I felt like an utter failure." A discussion about how solving piano's technical difficulties parallels with troubleshooting problems in a STEM field could enhance this section.
                </li>
                <li>
                    <b>Suggested Replacement Discussion:</b> Discussing the systematic approach to overcoming obstacles in piano pieces could connect to a natural inclination toward STEM-related problem-solving.
                </li>
            </ul>
            <h3>Requirement: Strong and Unique Voice</h3>
            <ul>
                <li>
                    The applicant’s voice is unique and personal throughout the essay, making it distinctive and memorable.
                </li>
                <li>
                    <b>Subgrade:</b> A
                </li>
                <li>
                    <b>Excerpt satisfying the requirement:</b> "It was the lair of a black dragon." The use of metaphor personalizes the voice and showcases the applicant's creativity.
                </li>
                <li>
                    <b>Excerpt satisfying the requirement:</b> "I'm a familiar face at their school, and I love the sheepish smiles and random hugs I receive as I walk down the halls." The genuine tone and personal recounting of feelings showcase the applicant's unique voice.
                </li>
            </ul>
            <h3>Requirement: Pleasurable Read and Satisfying Ending</h3>
            <ul>
                <li>
                    The essay flows well, is engaging, and wraps up with a reflective and satisfactory ending.
                </li>
                <li>
                    <b>Subgrade:</b> A
                </li>
                <li>
                    <b>Excerpt satisfying the requirement:</b> "Never again will I pass up an opportunity to learn more, do more, or be more than I already am." This final statement provides a satisfying conclusion that reflects the essay's central themes and emotions.
                </li>
            </ul>
            <h2>Techniques To Introduce:</h2>
            <ul>
                <li>
                    <b>
                        Subtle indications of empathy and a good heart
                    </b>
                    <ul>
                        <li>
                            This technique is already evident in the essay through the applicant's volunteer work giving piano lessons, so no specific replacement is necessary.
                        </li>
                    </ul>
                </li>
                <li>
                    <b>Integrated interdisciplinary themes</b>
                    <ul>
                        <li>
                            The essay could include subtle mentions of how musical patterns reflect logical structures in STEM, which would connect the applicant's narrative with STEM interests.
                        </li>
                    </ul>
                </li>
                <li>
                    <b>Subtle demonstrations of global awareness or cultural sensitivity</b>
                    <ul>
                        <li>
                            By highlighting the cultural backgrounds of the private lesson students more explicitly, the essay could demonstrate cultural sensitivity and awareness that would mesh well with the current themes.
                        </li>
                    </ul>
                </li>
            </ul>
            <h2>Red Flags:</h2>
            <p>
                Your essay does not violate any common Red Flags in Common App
                Essays.
            </p>
        </React.Fragment>
    );
}

function EssayAndFeedback(props) {
    if (props.essayToDisplay === "Sample Essay 1") {
        return (
            <div className={styles.section_container}>
                <div className={styles.essay_container}>
                   <SampleEssay1 />
                </div>
                <div className={styles.feedback_container}>
                    <SampleFeedback1 />
                </div>
            </div>
        );
    } else if (props.essayToDisplay === "Sample Essay 2") {
        return (
            <div className={styles.section_container}>
                <div className={styles.essay_container}>
                   <SampleEssay2 />
                </div>
                <div className={styles.feedback_container}>
                    <SampleFeedback2 />
                </div>
            </div>
        );
    } else {
        return (
            <div className={styles.section_container}>
                <div className={styles.essay_container}>
                   <SampleEssay3 />
                </div>
                <div className={styles.feedback_container}>
                    <SampleFeedback3 />
                </div>
            </div>
        );
    }

}

function EssaySelectionItem(props) {

    if (props.currentEssay === props.text) {
        return (
            <div className={styles.essay_selection_item_container_selected} onClick={props.onClick}>
                {props.text}
            </div>
        )
    }

    return (
        <div className={styles.essay_selection_item_container} onClick={props.onClick}>
            {props.text}
        </div>
    )
}

export default function NacacDemo() {

    const [essayToDisplay, setEssayToDisplay] = useState("Sample Essay 1");
    return (
        <div className={styles.main_container}>
            <div className={styles.page_title}>
                Real Student Essays and Athena's Feedback
            </div>
            <div className={styles.essay_selection_container}>
                <EssaySelectionItem text={"Sample Essay 1"} currentEssay={essayToDisplay} onClick={() => {setEssayToDisplay("Sample Essay 1");}}/>
                <EssaySelectionItem text={"Sample Essay 2"} currentEssay={essayToDisplay} onClick={() => {setEssayToDisplay("Sample Essay 2")}}/>
                <EssaySelectionItem text={"Sample Essay 3"} currentEssay={essayToDisplay} onClick={() => {setEssayToDisplay("Sample Essay 3")}}/>
            </div>
            <EssayAndFeedback essayToDisplay={essayToDisplay}/>
        </div>
        
    )
}