import GradeRequestTypeButton from "../../gradeRequestTypeButton/GradeRequestTypeButton";
import styles from "../../../styles/main/flagship/ModelSelectionPage.module.css";
import outer_styles from "../../../styles/main/OuterStyles.module.css";
import { useNavigate } from "react-router-dom";
import BackArrowButton from "../subcomponents/BackArrowButton";

import singleEssayIcon from "../../../imgs/single_essay.png";
import multipleEssaysIcon from "../../../imgs/multiple_essays.png";

function FlagshipModelSelectionContainer(props) {
    const navigate = useNavigate();

    return (
        <div className={styles.screen1_container}>
            <div className={styles.screen1_title}>
                Step 2: Pick what type of feedback you would like for your {props.university} essay(s).
            </div>
            <div className={styles.grading_type_container}>
                <img src={singleEssayIcon} className={styles.screen1_icon} alt="Single essay icon" />
                <div className={styles.grading_type_text_section}>
                    <div className={styles.grading_type_section_title}>I want feedback on a single essay.</div>
                    <div className={styles.grading_type_section_description}>
                        <span className={outer_styles.bold_font}>When to choose this option:</span> You want to iterate
                        on a single essay and get it as close to perfect as possible. We recommend starting here! These
                        will give you the most specific feedback which you can iterate on quickly.
                    </div>
                    <div className={styles.grading_type_buttons_container}>
                        <GradeRequestTypeButton
                            title={"Common App Essay"}
                            onClick={() => {
                                navigate("/essay-grading/flagship/common-app");
                            }}
                        />
                        <GradeRequestTypeButton
                            title={"Supplemental Essay"}
                            onClick={() => {
                                navigate("/essay-grading/flagship/supplemental");
                            }}
                        />
                    </div>
                </div>
            </div>
            {/* <div className={styles.grading_type_container}>
                <img
                    src={multipleEssaysIcon}
                    className={styles.screen1_icon}
                    alt="Multiple essays icon"
                />
                <div className={styles.grading_type_text_section}>
                    <div className={styles.grading_type_section_title}>
                        I want holistic feedback on all my essays to see how
                        well they work together.
                    </div>
                    <div className={styles.grading_type_section_description}>
                        <span className={outer_styles.bold_font}>
                            When to choose this option:
                        </span>{" "}
                        You have most of your essays looking quite good and want
                        to see how they fit together to make sure you're telling
                        a cohesive story. This can help you decide if you should
                        replace an essay.
                    </div>
                    <div className={styles.grading_type_buttons_container}>
                        <GradeRequestTypeButton
                            title={"Entire Application"}
                            onClick={() => {navigate("/essay-grading/flagship/entire-application")}}
                        />
                    </div>
                </div>
            </div> */}
        </div>
    );
}

export default function FlagshipModelSelectionPage(props) {
    return (
        <div className={styles.model_selection_container}>
            <BackArrowButton />
            <FlagshipModelSelectionContainer />
        </div>
    );
}
