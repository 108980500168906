import styles from "../../../styles/main/GradingPage.module.css";
import GradeRequestTypeButton from "../../gradeRequestTypeButton/GradeRequestTypeButton";
import { useEffect, useState, useRef, useContext } from "react";
import { v5 as uuidv5 } from "uuid";
import { useAuthContext } from "../../auth/AuthContext";
import { gradeCommonAppEssay, pollResponse } from "../../../api/apiCalls";

import GreenCheckMark from "../subcomponents/GreenCheckMark";
import LoadingSection from "../subcomponents/LoadingSection";
import ResponseFeedback from "../subcomponents/ResponseFeedback";
import BackArrowButton from "../subcomponents/BackArrowButton";
import InterpretFeedbackHyperlink from "../subcomponents/InterpretFeedbackHyperlink";
import ReactMarkdown from "react-markdown";

import { SidebarContext } from "../../sidebar/SidebarContext";

import {
    STATIC_ATHENA_NAMESPACE_UUID,
    ESSAY_MAX_LENGTH_CHARS,
    FormatModelResponse,
    ExtractErrorMessage,
} from "../utils/utils";

/* DEPRECATED */
function CommonAppEssayGradingContainer() {
    const { userProfile } = useAuthContext();
    const { fetchHistoryItemPreviews } = useContext(SidebarContext);

    const [currentRequestID, setCurrentRequestID] = useState("");
    const [modelResponse, setModelResponse] = useState("");
    const [gradeErrorMessage, setGradeErrorMessage] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const errorMessageRef = useRef(null);

    useEffect(() => {
        let intervalId;

        const shouldPollResponse = currentRequestID !== "" && modelResponse === "" && gradeErrorMessage === "";

        function handlePollResponse(data) {
            if (data.gpt_response_status === "finished") {
                setModelResponse(data.model_response);
                fetchHistoryItemPreviews(); // This will retrigger sidebar history preview
                setIsLoading(false);
            } else if (data.gpt_response_status === "error") {
                setGradeErrorMessage(data.error_reason);
                setIsLoading(false);
            }
        }

        if (shouldPollResponse) {
            // Set up the interval
            intervalId = setInterval(() => {
                pollResponse(currentRequestID)
                    .then((response) => {
                        handlePollResponse(response.data);
                    })
                    .catch((error) => {
                        setIsLoading(false);
                        setGradeErrorMessage(ExtractErrorMessage(error));
                    });
            }, 10000); // 10000 milliseconds = 10 seconds
        }

        // Clean up the interval on unmount or when currentRequestID changes
        return () => {
            if (intervalId) {
                clearInterval(intervalId);
            }
        };
    }, [
        currentRequestID,
        modelResponse,
        gradeErrorMessage,
        fetchHistoryItemPreviews,
        setModelResponse,
        setIsLoading,
        setGradeErrorMessage,
    ]); // Effect depends on currentRequestID

    const handleSubmit = (e) => {
        e.preventDefault();
        setIsLoading(true);
        setModelResponse("");
        setGradeErrorMessage("");

        const schoolCode = "flagship";
        const timestamp = new Date().toISOString().slice(0, 19);

        const idempotencyKey = uuidv5(`${schoolCode}-${userProfile.email}-${timestamp}`, STATIC_ATHENA_NAMESPACE_UUID);

        const formattedFormData = {
            school_code: schoolCode,
            application_school_code: schoolCode,
            idempotency_key: idempotencyKey,
            common_app_essay: {
                response: formData.common_app_essay,
            },
            essay_id: null,
        };

        gradeCommonAppEssay(formattedFormData)
            .then((response) => {
                setCurrentRequestID(response.data.request_id);
            })
            .catch((error) => {
                setIsLoading(false);
                setGradeErrorMessage(ExtractErrorMessage(error));
            });
    };

    useEffect(() => {
        if (gradeErrorMessage && errorMessageRef.current) {
            errorMessageRef.current.scrollIntoView({ behavior: "smooth" });
        }
    }, [gradeErrorMessage, errorMessageRef]);

    const [formData, setFormData] = useState({
        common_app_essay: "",
    });

    // Function to handle changes to the input fields
    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
    };

    const handleKeyDown = (event) => {
        if (event.key === "Tab") {
            event.preventDefault();
            const { name, selectionStart: start, selectionEnd: end } = event.target;

            setFormData((prevFormData) => ({
                ...prevFormData,
                [name]: prevFormData[name].substring(0, start) + "\t" + prevFormData[name].substring(end),
            }));

            setTimeout(() => {
                event.target.selectionStart = event.target.selectionEnd = start + 1;
            }, 0);
        }
    };

    return (
        <div className={styles.screen2_grading_form_container}>
            <div className={styles.screen2_grading_form_title}>
                Step 3: Get your essay graded with actionable feedback!
            </div>
            <div className={styles.screen2_grading_form_subtitle}>Common Application Essay</div>
            <form className={styles.screen2_grading_form} onSubmit={handleSubmit}>
                <textarea
                    className={styles.screen2_essay_input_field}
                    onChange={handleChange}
                    onKeyDown={handleKeyDown}
                    name="common_app_essay"
                    value={formData.common_app_essay}
                    placeholder="Yum! Feed me your Common App essay..."
                    maxLength={ESSAY_MAX_LENGTH_CHARS}
                />
                {isLoading ? (
                    <LoadingSection shouldShowText={true} />
                ) : modelResponse ? (
                    <GreenCheckMark />
                ) : (
                    <div className={styles.screen2_grade_request_section}>
                        <GradeRequestTypeButton
                            title={"Grade"}
                            cost_in_credits={1}
                            type={"submit"}
                            className={styles.screen2_grade_button}
                        />
                    </div>
                )}
            </form>
            {modelResponse && (
                <div>
                    <InterpretFeedbackHyperlink />
                    <div className={styles.screen2_grading_model_response_container}>
                        <ReactMarkdown>{FormatModelResponse(modelResponse)}</ReactMarkdown>
                    </div>
                </div>
            )}
            {gradeErrorMessage && (
                <div ref={errorMessageRef} className={styles.grade_error_message}>
                    {gradeErrorMessage}
                </div>
            )}
            {modelResponse && <ResponseFeedback current_request_id={currentRequestID} />}
        </div>
    );
}

export default function CommonAppEssayGradingPage() {
    return (
        <div>
            <BackArrowButton />
            <CommonAppEssayGradingContainer />
        </div>
    );
}
