import outer_styles from "../../../styles/main/OuterStyles.module.css";
import { useAuthContext } from "../../auth/AuthContext";

import SupplementalEssayGradingPage from "../flagship/SupplementalEssayGradingPage";

export default function SchoolSpecificGradingPage(props) {
    const { userProfile } = useAuthContext();

    // Display lock-down message if user is not an Athena Pro member.
    if (userProfile.membership_plan !== "pro") {
        return (
            <div className={outer_styles.main_section_system_message}>
                These models are only available to Athena Pro members.
                Please upgrade to Athena Pro to access them.
            </div>
        );
    }

    // Display WIP message if not ready
    if (!props.ready) {
        return (
            <div>
                <div className={outer_styles.main_section_image}>
                    <iframe
                        src="https://giphy.com/embed/kfR5iyQgmq7PoiFTAf"
                        title="workInProgress"
                        width="480"
                        height="320"
                        class="giphy-embed"
                        allowFullScreen
                    ></iframe>
                    <p>
                        <a href="https://giphy.com/stickers/work-process-progress-kfR5iyQgmq7PoiFTAf">
                            via GIPHY
                        </a>
                    </p>
                </div>
                <div className={outer_styles.main_section_system_message}>
                    These models will unlock and become available on July 15, 2024 for you. Thank you for being an Athena Pro member!
                </div>
            </div>     
        );
    }

    // Display proper frontend
    return <SupplementalEssayGradingPage schoolCode={props.schoolCode} subtitle={props.subtitle} />;
}