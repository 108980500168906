import styles from "../../../styles/education/resources/EducationalResources.module.css";
import { useCallback, useEffect, useRef, useState, memo } from "react";
import Dropdown from "../../base/Dropdown";
import BaseButton from "../../base/BaseButton";
import Certificate from "../Certificate";

import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
    Button,
    FormControl,
    FormControlLabel,
    FormLabel,
    Radio,
    RadioGroup,
    Typography,
    Paper,
    Container,
    Box,
} from "@mui/material";

import { fetchIsQuizCompleted, markQuizAsCompleted } from "../../../api/apiCalls";

import { toast } from "react-toastify";

import checkmarkIcon from "../../../imgs/checkmark-icon.png";

const Video = memo(({ url }) => {
    return (
        <div className={styles.video_wrapper}>
            <iframe
                className={styles.video}
                src={url}
                title="YouTube video player"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                referrerPolicy="strict-origin-when-cross-origin"
                allowFullScreen
            />
        </div>
    );
});

function QuizComplete(props) {
    const { setShowCertificate } = props;
    const handleViewCertificate = () => {
        setShowCertificate((prevState) => !prevState);
    };

    return (
        <div className={styles.quiz_complete_container}>
            <div className={styles.inner_quiz_complete_container}>
                <img src={checkmarkIcon} alt="Checkmark Icon" className={styles.checkmark_icon} />
                <div>Congratulations, you have already passed the quiz. Nice work!</div>
            </div>
            <div className={styles.certificate_container}>
                <BaseButton onClick={handleViewCertificate} className={styles.certificate_button}>
                    View certificate
                </BaseButton>
            </div>
        </div>
    );
}

function Quiz(props) {
    const [score, setScore] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [isQuizCompleted, setIsQuizCompleted] = useState(false);
    const { setShowCertificate } = props;

    useEffect(() => {
        fetchIsQuizCompleted()
            .then((response) => {
                setIsQuizCompleted(response.data.is_quiz_completed);
            })
            .catch((error) => toast.error("Something went wrong with determining if you have completed the quiz."));
    }, []);

    const quiz = [
        {
            question: "Which of the following is the best description of AI?",
            name: "question1",
            options: [
                "A program that can think.",
                "A program which has human cognitive function.",
                "A program capable of extremely accurate prediction.",
                "A computer that can write well.",
            ],
            correctAnswer: 2,
        },
        {
            question: 'What is the name of the "bucket" which nearly all university AI policies fall into?',
            name: "question2",
            options: [
                "Use AI like a trusted adult.",
                "Use AI for proofreading.",
                "Do not use AI at all.",
                "Use AI to write your essays.",
            ],
            correctAnswer: 0,
        },
        {
            question:
                "Which reason below best explains why ChatGPT alone is incapable of giving good advice on college essays?",
            name: "question3",
            options: [
                "ChatGPT gives bad advice in general.",
                "ChatGPT has only seen the bad advice about college apps that is present online, and so that is what it will mimic.",
                "The company behind ChatGPT doesn't understand the college app process.",
                "ChatGPT is not perfect and can sometimes forget things.",
            ],
            correctAnswer: 1,
        },
        {
            question: "Which one of the below is one of AI's strengths?",
            name: "question4",
            options: [
                "Not many people use it, so it is a secret weapon.",
                "It knows everything about college admissions.",
                "It can write great college application essays for you.",
                "24/7 Availability.",
            ],
            correctAnswer: 3,
        },
        {
            question: "What are the two steps involved in the AI process?",
            name: "question5",
            options: [
                "Input and output.",
                "Training and prediction.",
                "Question and answer.",
                "Learning and teaching.",
            ],
            correctAnswer: 1,
        },
    ];

    const validationSchema = Yup.object(
        quiz.reduce((schema, question) => {
            schema[question.name] = Yup.string().required("This field is required");
            return schema;
        }, {})
    );

    const formik = useFormik({
        initialValues: quiz.reduce((values, question) => {
            values[question.name] = "";
            return values;
        }, {}),
        validationSchema,
        onSubmit: (values) => {
            const results = quiz.map((q) => ({
                question: q.question,
                correctAnswer: q.options[q.correctAnswer].toLowerCase(),
                userAnswer: values[q.name],
                isCorrect: q.options[q.correctAnswer].toLowerCase() === values[q.name].toLowerCase(),
            }));

            const correctAnswers = results.filter((result) => result.isCorrect).length;
            const totalQuestions = results.length;
            const scorePercentage = (correctAnswers / totalQuestions) * 100;
            setScore(scorePercentage);
            setSubmitted(true);

            if (scorePercentage >= 100) {
                markQuizAsCompleted()
                    .then((response) => {
                        setIsQuizCompleted(response.data.is_quiz_completed);
                    })
                    .catch((error) => toast.error("Something went wrong with marking the quiz as completed."));
            }
        },
    });

    const handleTryAgain = () => {
        formik.resetForm();
        setSubmitted(false);
        setScore(null);
    };

    return (
        <Container
            className={styles.quiz_container}
            component={Paper}
            elevation={5}
            sx={{ p: 4, mt: 4, borderRadius: "20px", padding: "30px 100px 30px 100px" }}
        >
            {isQuizCompleted && <QuizComplete setShowCertificate={setShowCertificate} />}
            <div className={styles.title_h2}>Ready for a quiz on the videos you just watched?</div>
            <form onSubmit={formik.handleSubmit}>
                {quiz.map((q, index) => (
                    <Box key={index} sx={{ mb: 2, pl: 5, pr: 5 }}>
                        <FormControl component="fieldset">
                            <FormLabel className={styles.question_title} component="legend">
                                {q.question}
                            </FormLabel>
                            <RadioGroup
                                className={styles.radio_group}
                                id={q.name}
                                name={q.name}
                                value={formik.values[q.name]}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                            >
                                {q.options.map((option, i) => (
                                    <FormControlLabel
                                        key={i}
                                        value={option}
                                        control={<Radio />}
                                        label={<span className={styles.font_inter}>{option}</span>}
                                        disabled={formik.isSubmitting || submitted || isQuizCompleted}
                                    />
                                ))}
                            </RadioGroup>
                            {formik.touched[q.name] && formik.errors[q.name] && (
                                <Typography color="error" variant="body2">
                                    {formik.errors[q.name]}
                                </Typography>
                            )}
                        </FormControl>
                    </Box>
                ))}
                <Button
                    color="primary"
                    variant="contained"
                    type="submit"
                    disabled={formik.isSubmitting || submitted || isQuizCompleted}
                    sx={{ ml: 5, mr: 5, pl: 5, pr: 5 }}
                >
                    Submit
                </Button>
            </form>
            {submitted && (
                <Box className={styles.result} sx={{ mt: 4 }}>
                    {score >= 100 && (
                        <div>
                            <div>{`Your score is: ${score.toFixed(0)}%. Congratulations, you are done!`}</div>
                        </div>
                    )}
                    {score < 100 && (
                        <div>
                            <div>{`Your score is: ${score.toFixed(0)}%`}</div>
                            <Button color="secondary" variant="contained" onClick={handleTryAgain} sx={{ mt: 2 }}>
                                Try Again
                            </Button>
                        </div>
                    )}
                </Box>
            )}
        </Container>
    );
}

export default function EducationalResources(props) {
    const videos = [
        { name: "Part 1: Welcome", url: "https://www.youtube.com/embed/aqMnbe8ZaNE?si=2PwkWfKT8KLULoyK" },
        { name: "Part 2: College Admissions", url: "https://www.youtube.com/embed/nL2WLxf88Cs?si=b6VyCI36WLAa6-Wv" },
        { name: "Part 3: Essay Deep-Dive", url: "https://www.youtube.com/embed/iVOOssJT5BE?si=hAnPPxhcCYwU0QoO" },
        { name: "Part 4: AI Usage", url: "https://www.youtube.com/embed/68XtEcH4LDs?si=AMiL4Ye0LakUzydp" },
        { name: "Part 5: What is AI", url: "https://www.youtube.com/embed/CFQUphJtypY?si=XgJJDtAkc11goB0y" },
        { name: "Part 6: AI Weaknesses", url: "https://www.youtube.com/embed/bxGF5RMkbvU?si=-hQvTq-IZYXLffB4" },
        { name: "Part 7: AI Strengths", url: "https://www.youtube.com/embed/cGxYF4jIbr8?si=P1ToAC7M0dAQTwQb" },
        { name: "Part 8: University Policies", url: "https://www.youtube.com/embed/kRQzuwCueIg?si=kR_jGodQpcfQMdVP" },
        { name: "Part 9: AI Examples", url: "https://www.youtube.com/embed/P7yAvIhMXV4?si=lgK48EoCVYPPWH9u" },
        { name: "Part 10: Good Luck!", url: "https://www.youtube.com/embed/sAbOSu-wPWo?si=AJpIpdtcjn0GcPjx" },
    ];

    const [activeVideo, setActiveVideo] = useState(videos[0]);
    const [showDropdown, setShowDropdown] = useState(false);
    const [showCertificate, setShowCertificate] = useState(false);
    const certificateRef = useRef(null);

    const dropdownRef = useRef(null);
    const handleOutsideClick = useCallback((event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setShowDropdown(false);
        }
    }, []);

    useEffect(() => {
        document.addEventListener("mousedown", handleOutsideClick);
        return () => {
            document.removeEventListener("mousedown", handleOutsideClick);
        };
    }, [handleOutsideClick]);

    const handleVideoChange = useCallback(
        (newVideo) => {
            if (newVideo.url !== activeVideo.url) {
                setActiveVideo(newVideo);
            }
        },
        [activeVideo]
    );

    const handleDownloadCertificate = async () => {
        const element = certificateRef.current;
        const canvas = await html2canvas(element, { scale: 4, quality: 4 });
        const imageData = canvas.toDataURL("image/png");

        const pdf = new jsPDF({
            orientation: "landscape",
            unit: "in",
            format: [8.5, 11],
            compress: true,
        });

        pdf.addImage(imageData, "PNG", 0, 0, 11, 8.5);
        pdf.save("certificate.pdf");
    };

    return (
        <div className={styles.main_container}>
            <div className={styles.background_header}>
                <div className={styles.title}>Learn About AI and College Admissions</div>
                <div className={styles.subtitle}>Estimated time: 35 minutes</div>
            </div>
            <div className={styles.content_container}>
                <div className={styles.dropdown_input_container}>
                    <Dropdown
                        showDropdown={showDropdown}
                        setShowDropdown={setShowDropdown}
                        items={videos}
                        selectedItem={activeVideo}
                        setSelectedItem={handleVideoChange}
                        dropdownRef={dropdownRef}
                    />
                </div>
                <Video url={activeVideo.url} />
                <Quiz setShowCertificate={setShowCertificate} />
                {showCertificate && (
                    <div className={styles.certificate_modal_container}>
                        <div className={styles.certificate_modal}>
                            <Certificate
                                name="John Doe"
                                course="AI and College Admissions"
                                certificateRef={certificateRef}
                            />
                            <div className={styles.certificate_modal_button_container}>
                                <BaseButton
                                    className={styles.certificate_modal_button}
                                    onClick={() => setShowCertificate((prevState) => !prevState)}
                                >
                                    Close
                                </BaseButton>
                                <BaseButton
                                    className={styles.certificate_modal_button}
                                    onClick={handleDownloadCertificate}
                                >
                                    Download Certificate
                                </BaseButton>
                            </div>
                            <div className={styles.gray_background} />
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}
