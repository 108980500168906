
import styles from "../../styles/content/ContentHome.module.css";
import ajay from "../../imgs/ajay.jpg";
import eugene from "../../imgs/eugene.jpg";
import { useNavigate } from "react-router-dom";


const StartHereSectionName = "Start Here";
const PastMistakesSectionName = "Learn From Applicants' Past Mistakes";
const FromAdmissionsOfficersSectionName = "Interviews with Current Admissions Officers";


export default function ContentHome() {
    return (
        <div className={styles.content_sections_outer_container}>
            <div className={styles.content_sections_container}>
                <div className={styles.content_banner}>
                    <div className={styles.content_banner_text}>
                        Curated content to help you master the college application process
                    </div>
                </div>
                <ContentSection name={StartHereSectionName}/>
                <ContentSection name={PastMistakesSectionName}/>
                <ContentSection name={FromAdmissionsOfficersSectionName}/>
            </div>
        </div>
    );
        
}

function ContentSection(props) {
    return (
        <div className={styles.section_container}>
            <div className={styles.section_title}>
                {props.name}
            </div>
            {props.name === StartHereSectionName && <StartHereArticles />}
            {props.name === PastMistakesSectionName && <PastMistakesArticles />}
            {props.name === FromAdmissionsOfficersSectionName && <FromAdmissionsOfficersArticles />}
        </div>
    )
}

function StartHereArticles(props) {
    return (
        <div className={styles.articles_container}>
            <ArticlePreview
                title="You're Thinking About The Admissions Process Incorrectly"
                date="January 2024"
                img={ajay}
                author="Ajay Natarajan"
                author_description="Caltech Class of 2022"
                url="/content/youre-thinking-about-the-admissions-process-incorrectly"
            />
            <ArticlePreview
                title="What You Need to Be Doing Now as a 2nd Semester Junior"
                date="January 2024"
                img={ajay}
                author="Ajay Natarajan"
                author_description="Caltech Class of 2022"
                url="/content/what-you-need-to-be-doing-now-as-a-2nd-semester-junior"
            />
            <ArticlePreview
                title="How To Interpret Athena Feedback"
                date="May 2024"
                img={ajay}
                author="Ajay Natarajan"
                author_description="Caltech Class of 2022"
                url="/content/how-to-interpret-athena-feedback"
            />
            {/* <ArticlePreview
                title="How AI Will Affect This Upcoming Admissions Cycle"
                date="January 25, 2024"
                img={eugene}
                author="Eugene Shao"
                author_description="Private Admissions Consultant"
                url="/content/how-ai-will-affect-this-upcoming-admissions-cycle"
            /> */}
        </div>
    );
}

function PastMistakesArticles(props) {
    return (
        <div className={styles.articles_container}>
            <ArticlePreview
                title="The Biggest Mistake Every Applicant Makes"
                date="February 2024"
                img={eugene}
                author="Eugene Shao"
                author_description="Caltech Class of 2022"
                url="/content/the-biggest-mistake-every-applicant-makes"
                gated_by_athena_pro={true}
            />
        </div>
    );
}

function FromAdmissionsOfficersArticles(props) {
    return (
        <div className={styles.articles_container}>
            <ArticlePreview
                title="Interview With an Ivy League Admissions Officer"
                date="March 2024"
                img={eugene}
                author="Eugene Shao"
                author_description="Caltech Class of 2022"
                url="/content/interview-with-an-ivy-league-admissions-officer"
            />
        </div>
    );
}

function ArticlePreview(props) {
    const navigate = useNavigate();

    return (
        <div className={styles.article_container}
            onClick={() => {
                navigate(`${props.url}`);
                window.scrollTo(0, 0);
            }}
        >
            <div className={styles.article_title}>
                {props.title}
            </div>
            <div className={styles.article_date}>
                {props.date}
            </div>
            <div className={styles.author_container}>
                <img className={styles.author_img} src={props.img} alt="author"/>
                <div className={styles.author_info_container}>
                    <div className={styles.author_name}>{props.author}</div>
                    <div className={styles.author_description}>{props.author_description}</div>
                </div>
            </div>
        </div>
    )

}
