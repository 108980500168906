import { verifyEmail, sendEmailVerification } from "../../api/apiCalls";
import { useAuthContext } from "./AuthContext";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import styles from "../../styles/auth/EmailVerification.module.css";
import BaseButton from "../base/BaseButton";
import { toast } from "react-toastify";

export default function EmailVerification() {
    const { userProfile } = useAuthContext();
    const sendToken = () => {
        const formData = {
            email: userProfile.email,
        };

        sendEmailVerification(formData)
            .then((response) => {
                toast.success("Email sent!");
            })
            .catch((error) => {
                toast.error(error.response.data.error);
                alert("failed to send email");
            });
    };

    const [searchParams] = useSearchParams();
    const userId = searchParams.get("user_id");
    const token = searchParams.get("token");

    const [loading, setLoading] = useState(true);
    const [verified, setVerified] = useState(false);

    useEffect(() => {
        const formData = {
            user_id: userId,
            token: token,
        };
        verifyEmail(formData)
            .then((response) => {
                toast.success("Email verified!");
                setVerified(true);
            })
            .catch((error) => {
                toast.error(`Error verifying email: ${error.response.data.error}`);
                setVerified(false);
            });
        setLoading(false);
    }, [userId, token]);

    const navigate = useNavigate();

    // const body = verified ? (
    //     <div>
    //         <h1>Email Verified!</h1>
    //         <p>
    //             Your email has been verified. You can now use your account to
    //             login.
    //         </p>
    //         <BaseButton className={styles.button} onClick={() => navigate("/")}>
    //             Get Started
    //         </BaseButton>
    //     </div>
    // ) : (
    //     <div>
    //         <h1>Expired or Invalid Link</h1>
    //         <p>
    //             The link has either expired or is invalid. Please try again with
    //             a new email verification link.
    //         </p>
    //     </div>
    // );

    const body = (
        <div>
            <h1>Email Verified!</h1>
            <p>Your email has been verified. You can now use your account to login.</p>
            <BaseButton className={styles.button} onClick={() => navigate("/")}>
                Get Started
            </BaseButton>
        </div>
    );

    const testingHtml = (
        <>
            <div>I am a testing page</div>
            <p>User ID: {userId}</p>
            <p>Token: {token}</p>
            <p>{verified ? "Verified!" : "Failure"}</p>
            <button onClick={sendToken}>Send request</button>
        </>
    );

    return loading ? <div>Loading...</div> : body;
}
