import * as React from "react";
import { cloneElement, createContext, forwardRef, isValidElement, useContext, useMemo, useRef, useState } from "react";
import {
    useFloating,
    autoUpdate,
    offset,
    flip,
    shift,
    useHover,
    useFocus,
    useDismiss,
    useRole,
    useInteractions,
    useMergeRefs,
    FloatingPortal,
    FloatingArrow,
    arrow,
    useDelayGroup,
    useTransitionStyles,
} from "@floating-ui/react";
import BaseButton from "./BaseButton";
import styles from "../../styles/base/Tooltip.module.css";

export function useTooltip({
    initialOpen = false,
    placement = "top",
    open: controlledOpen,
    onOpenChange: setControlledOpen,
} = {}) {
    const [uncontrolledOpen, setUncontrolledOpen] = useState(initialOpen);

    const open = controlledOpen ?? uncontrolledOpen;
    const setOpen = setControlledOpen ?? setUncontrolledOpen;
    const arrowRef = useRef(null);
    const ARROW_HEIGHT = 7;
    const GAP = 2;

    const data = useFloating({
        placement,
        open,
        onOpenChange: setOpen,
        whileElementsMounted: autoUpdate,
        middleware: [
            offset(ARROW_HEIGHT + GAP),
            flip({
                crossAxis: placement.includes("-"),
                fallbackAxisSideDirection: "start",
                padding: 5,
            }),
            shift({ padding: 5 }),
            arrow({
                element: arrowRef,
            }),
        ],
    });

    const context = data.context;

    const { delay } = useDelayGroup(context);
    const hover = useHover(context, {
        move: false,
        enabled: controlledOpen == null,
        delay: delay,
    });
    const focus = useFocus(context, {
        enabled: controlledOpen == null,
    });
    const dismiss = useDismiss(context);
    const role = useRole(context, { role: "tooltip" });

    const interactions = useInteractions([hover, focus, dismiss, role]);

    return useMemo(
        () => ({
            open,
            setOpen,
            ...interactions,
            ...data,
            arrowRef,
        }),
        [open, setOpen, interactions, data, arrowRef]
    );
}

const TooltipContext = createContext(null);

export const useTooltipContext = () => {
    const context = useContext(TooltipContext);

    if (context == null) {
        throw new Error("Tooltip components must be wrapped in <Tooltip />");
    }

    return context;
};

export function Tooltip({ children, ...options }) {
    // This can accept any props as options, e.g. `placement`,
    // or other positioning options.
    const tooltip = useTooltip(options);
    return <TooltipContext.Provider value={tooltip}>{children}</TooltipContext.Provider>;
}

export const TooltipTrigger = forwardRef(function TooltipTrigger({ children, asChild = false, ...props }, propRef) {
    const context = useTooltipContext();
    const childrenRef = children.ref;
    const ref = useMergeRefs([context.refs.setReference, propRef, childrenRef]);

    // `asChild` allows the user to pass any element as the anchor
    if (asChild && isValidElement(children)) {
        return cloneElement(
            children,
            context.getReferenceProps({
                ref,
                ...props,
                ...children.props,
                "data-state": context.open ? "open" : "closed",
            })
        );
    }

    // TODO (Eugene): Change default return to be something other than a BaseButton
    return (
        <BaseButton
            ref={ref}
            // The user can style the trigger based on the state
            data-state={context.open ? "open" : "closed"}
            {...context.getReferenceProps(props)}
            className={props.className}
        >
            {children}
        </BaseButton>
    );
});

export const TooltipContent = forwardRef(function TooltipContent({ style, ...props }, propRef) {
    const context = useTooltipContext();
    const ref = useMergeRefs([context.refs.setFloating, propRef]);

    if (!context.open) return null;

    return (
        <FloatingPortal>
            <div
                ref={ref}
                style={{
                    ...context.floatingStyles,
                    ...style,
                }}
                {...context.getFloatingProps(props)}
                // className={styles.tooltip}
                className={`${styles.tooltip} ${props.className}`}
            >
                {props.children}
                <FloatingArrow
                    ref={context.arrowRef}
                    context={context}
                    className={styles.arrow}
                    fill={"#0090f7"}
                    tipRadius={2}
                />
            </div>
        </FloatingPortal>
    );
});
