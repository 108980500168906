import styles from "../../styles/education/Certificate.module.css";
import React from "react";
import { useAuthContext } from "../auth/AuthContext";

export default function Certificate(props) {
    const { certificateRef } = props;
    const { userProfile } = useAuthContext();

    return (
        <div className={styles.certificate} ref={certificateRef}>
            <h1 className={styles.name}>{userProfile?.first_name + " " + userProfile?.last_name}</h1>
            <p className={styles.date}>{new Date().toLocaleDateString()}</p>
            <p className={styles.certificate_id}>{userProfile?.id}</p>
        </div>
    );
}
