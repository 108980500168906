import whiteAthenaV2 from "../../imgs/white_athena_v2.png";
import closeIcon from "../../imgs/close-icon.png";
import styles from "../../styles/admin/UserSettings.module.css";
import { useAuthContext } from "../auth/AuthContext";
import { useEffect, useState } from "react";
import BaseButton from "../base/BaseButton.js";
import { useNavigate } from "react-router-dom";

function Modal({ children, closeModal }) {
    return (
        <div className={styles.modal_background}>
            <div className={styles.modal_body}>{children}</div>
        </div>
    );
}

function DeactivationWarningModal({ handleCloseModal, handleDeactivateAccount }) {
    return (
        <Modal closeModal={handleCloseModal}>
            <h2>Are you sure you want to deactivate your account?</h2>
            <p>
                Deactivating your account will remove all your data from Athena. You will lose access to your membership
                and features.
            </p>
            <div className={styles.modal_button_options}>
                <BaseButton className={styles.modal_deactivate_button} onClick={handleDeactivateAccount}>
                    Deactivate
                </BaseButton>
                <BaseButton className={styles.modal_cancel_button} onClick={handleCloseModal}>
                    Cancel
                </BaseButton>
            </div>
        </Modal>
    );
}

export default function UserSettings() {
    const navigate = useNavigate();
    const {
        userProfile,
        handleAccountLinkingRequest,
        handleAccountUnlinkingRequest,
        handleConfirmIncomingLinkingRequest,
        handleDeleteIncomingLinkingRequest,
    } = useAuthContext();
    const userProfileImgToDisplay = userProfile.profile_image || whiteAthenaV2;
    const membershipPlan = userProfile.membership_plan
        ? userProfile.membership_plan.charAt(0).toUpperCase() + userProfile.membership_plan.slice(1).toLowerCase()
        : "";
    const accountType = userProfile.account_type
        ? userProfile.account_type.charAt(0).toUpperCase() + userProfile.account_type.slice(1).toLowerCase()
        : "";

    const creditsMessage = membershipPlan === "Free" || membershipPlan === "Starter" ? userProfile.credit : "Unlimited";
    const [showDeactivationWarningModal, setShowDeactivationWarningModal] = useState(false);
    const [showEditLinkedAccounts, setShowEditLinkedAccounts] = useState(false);
    const [linkedAccounts, setLinkedAccounts] = useState([]);
    const [incomingLinkingRequests, setIncomingLinkingRequests] = useState([]);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [requestSentMessage, setRequestSentMessage] = useState("");
    const [requestSentError, setRequestSentError] = useState("");
    const [accountUnlinkingError, setAccountUnlinkingError] = useState("");
    const [accountLinkingError, setAccountLinkingError] = useState("");

    useEffect(() => {
        setLinkedAccounts(userProfile.linked_accounts);
        setIncomingLinkingRequests(userProfile.incoming_linking_requests);
    }, [userProfile]);

    const handleAccountUnlinkingButtonClick = (email) => {
        const formData = { target_email: email };
        handleAccountUnlinkingRequest(formData)
            .then((_) => {
                setLinkedAccounts(linkedAccounts.filter((item) => item !== email));
            })
            .catch((error) => {
                setAccountUnlinkingError(error.response.data.error);
            });
    };

    const handleAccountLinkingSubmit = (event) => {
        event.preventDefault();
        setIsSubmitting(true);
        setRequestSentMessage("");
        setRequestSentError("");

        handleAccountLinkingRequest(accountLinkingFormData)
            .then((response) => {
                setIsSubmitting(false);
                response?.data?.user
                    ? setRequestSentMessage(`A linking request has been sent to ${accountLinkingFormData.target_email}`)
                    : setRequestSentMessage(response.data.message);
            })
            .catch((error) => {
                setIsSubmitting(false);
                setRequestSentError(error.response.data.error);
            });
    };
    const [accountLinkingFormData, setAccountLinkingFormData] = useState({ target_email: "" });
    const handleTargetEmailInputChange = (event) => {
        setAccountLinkingFormData({ ...accountLinkingFormData, target_email: event.target.value });
    };

    const handleConfirmButtonClick = (email) => {
        const formData = { target_email: email };
        handleConfirmIncomingLinkingRequest(formData)
            .then((_) => {
                setLinkedAccounts([...linkedAccounts, email]);
                setIncomingLinkingRequests(incomingLinkingRequests.filter((item) => item !== email));
            })
            .catch((error) => setAccountLinkingError(error.response.data.error));
        // Need to handle edge case where account was deleted before confirmation
    };

    const handleDeleteButtonClick = (email) => {
        const formData = { target_email: email };
        handleDeleteIncomingLinkingRequest(formData)
            .then((_) => {
                setIncomingLinkingRequests(incomingLinkingRequests.filter((item) => item !== email));
            })
            .catch((error) => setAccountLinkingError(error.response.data.error));
    };

    const editLinkedAccounts = (
        <>
            {linkedAccounts?.length ? (
                <>
                    <ul>
                        {linkedAccounts.map((email, index) => {
                            return (
                                <li key={index}>
                                    <img
                                        src={closeIcon}
                                        className={styles.x_icon}
                                        onClick={() => {
                                            handleAccountUnlinkingButtonClick(email);
                                        }}
                                    />
                                    {email}
                                </li>
                            );
                        })}
                    </ul>
                </>
            ) : (
                <span>No linked accounts</span>
            )}
            {accountUnlinkingError && <div className={styles.error_text}>{accountUnlinkingError}</div>}
            <div className={styles.add_linked_account}>
                <form onSubmit={(event) => handleAccountLinkingSubmit(event)}>
                    <label htmlFor="targetEmailInput">
                        Link your {accountType === "Student" ? "parent's" : "student's"} email
                    </label>
                    <input
                        type="email"
                        id="targetEmailInput"
                        name="targetEmail"
                        placeholder={accountType === "Student" ? "parent@athenaco.ai" : "student@athenaco.ai"}
                        value={accountLinkingFormData.target_email}
                        onChange={(e) => handleTargetEmailInputChange(e)}
                    ></input>
                    <BaseButton className={styles.settings_item_button} disabled={isSubmitting}>
                        Send Request
                    </BaseButton>
                </form>
                {requestSentMessage && <div className={styles.add_linked_account_message}>{requestSentMessage}</div>}
                {requestSentError && <div className={styles.add_linked_account_error}>{requestSentError}</div>}
            </div>
        </>
    );
    const linkedAccountsList = showEditLinkedAccounts ? (
        editLinkedAccounts
    ) : linkedAccounts?.length ? (
        <ul>
            {linkedAccounts.map((email, index) => (
                <li key={index}>{email}</li>
            ))}
        </ul>
    ) : (
        <span>No linked accounts</span>
    );

    const editIncomingRequests = (
        <>
            {incomingLinkingRequests?.length ? (
                <ul>
                    {incomingLinkingRequests.map((email, index) => {
                        return (
                            <li key={index}>
                                <span className={styles.incoming_request_name}>{email}</span>
                                <div className={styles.incoming_request_options}>
                                    <BaseButton
                                        className={styles.confirm_button}
                                        onClick={() => handleConfirmButtonClick(email)}
                                    >
                                        Confirm
                                    </BaseButton>
                                    <BaseButton
                                        className={styles.delete_button}
                                        onClick={() => handleDeleteButtonClick(email)}
                                    >
                                        Delete
                                    </BaseButton>
                                </div>
                            </li>
                        );
                    })}
                </ul>
            ) : (
                <span>No incoming requests</span>
            )}
            {accountLinkingError && <div className={styles.error_text}>{accountLinkingError}</div>}
        </>
    );

    const incomingLinkingRequestsList = showEditLinkedAccounts ? (
        editIncomingRequests
    ) : incomingLinkingRequests?.length ? (
        <ul>{incomingLinkingRequests && incomingLinkingRequests.map((item, index) => <li key={index}>{item}</li>)}</ul>
    ) : (
        <span>No incoming requests</span>
    );

    const handleCloseDeactivationModal = () => {
        setShowDeactivationWarningModal(false);
    };

    const handleDeactivateAccount = () => {
        alert("Please reach out to support@athenaco.ai to deactivate your account.");
        setShowDeactivationWarningModal(false);
    };

    return (
        <div className={styles.settings_container}>
            <h1>Settings</h1>
            <div className={styles.settings_section}>
                <div className={styles.basic_info}>
                    <img src={userProfileImgToDisplay} alt="profile" />
                    <div className={styles.basic_info_text}>
                        <span className={styles.user_name}>
                            {userProfile.first_name} {userProfile.last_name}
                        </span>
                        <span>{userProfile.email}</span>
                    </div>
                </div>
            </div>
            <div className={styles.settings_section}>
                <h2>Membership</h2>
                <div className={styles.settings_subsection}>
                    <div className={styles.settings_item}>
                        <div className={styles.settings_item_body}>
                            <span className={styles.settings_item_title}>Membership Type</span>
                            <div className={styles.settings_item_desc}>
                                <span>{membershipPlan}</span>
                            </div>
                        </div>
                        <div className={styles.settings_item_cta}>
                            <BaseButton className={styles.settings_item_button} onClick={() => navigate("/pricing")}>
                                Upgrade
                            </BaseButton>
                        </div>
                    </div>
                </div>
                <div className={styles.settings_subsection}>
                    <div className={styles.settings_item}>
                        <div className={styles.settings_item_body}>
                            <span className={styles.settings_item_title}>Expiration Date</span>
                            <div className={styles.settings_item_desc}>
                                <span>{userProfile.membership_end_date ?? "Forever"}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={styles.settings_subsection}>
                    <div className={styles.settings_item}>
                        <div className={styles.settings_item_body}>
                            <span className={styles.settings_item_title}>Credits</span>
                            <div className={styles.settings_item_desc}>
                                <span>{creditsMessage}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.settings_section}>
                <h2>Account</h2>
                <div className={styles.settings_subsection}>
                    <div className={styles.settings_item}>
                        <div className={styles.settings_item_body}>
                            <span className={styles.settings_item_title}>Account Type</span>
                            <div className={styles.settings_item_desc}>
                                <span>{accountType}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={styles.settings_subsection}>
                    <div className={styles.settings_item}>
                        <div className={styles.settings_item_body}>
                            <span className={styles.settings_item_title}>Linked Accounts</span>
                            <div className={styles.settings_item_desc}>{linkedAccountsList}</div>
                        </div>
                        <div className={styles.settings_item_cta}>
                            <BaseButton
                                className={styles.settings_item_button}
                                onClick={() => {
                                    setShowEditLinkedAccounts(!showEditLinkedAccounts);
                                    setRequestSentMessage("");
                                    setRequestSentError("");
                                    setAccountUnlinkingError("");
                                    setAccountLinkingError("");
                                    setAccountLinkingFormData({ ...accountLinkingFormData, target_email: "" });
                                }}
                            >
                                {showEditLinkedAccounts ? "Close" : "Edit"}
                            </BaseButton>
                        </div>
                    </div>
                    <div className={styles.settings_item}>
                        <div className={styles.settings_item_body}>
                            <span className={styles.settings_item_title}>Incoming Requests</span>
                            <div className={styles.settings_item_desc}>{incomingLinkingRequestsList}</div>
                        </div>
                    </div>
                </div>
                {/* <div className={styles.settings_subsection}>
                    <div className={styles.settings_item}>
                        <div className={styles.settings_item_body}>
                            <span className={styles.settings_item_title}>Change Password</span>
                            <div className={styles.settings_item_desc}>
                                <span>Keep your account secure</span>
                            </div>
                        </div>
                        <div className={styles.settings_item_cta}>
                            <BaseButton
                                className={styles.settings_item_button}
                                onClick={() => alert("You thought you could change your password? LOL")}
                            >
                                Change
                            </BaseButton>
                        </div>
                    </div>
                </div> */}
                <div className={styles.settings_subsection}>
                    <div className={styles.settings_item}>
                        <div className={styles.settings_item_body}>
                            <span className={styles.settings_item_title}>Deactivate Account</span>
                            <div className={styles.settings_item_desc}>
                                <span>Leaving so soon?</span>
                            </div>
                        </div>
                        <div className={styles.settings_item_cta}>
                            <BaseButton
                                className={`${styles.settings_item_button} ${styles.deactivate_button}`}
                                onClick={() => setShowDeactivationWarningModal(true)}
                            >
                                Deactivate
                            </BaseButton>
                        </div>
                    </div>
                </div>
            </div>
            {showDeactivationWarningModal && (
                <DeactivationWarningModal
                    handleCloseModal={handleCloseDeactivationModal}
                    handleDeactivateAccount={handleDeactivateAccount}
                />
            )}
        </div>
    );
}
