import React, { useEffect, useRef } from 'react';
import lottie from 'lottie-web';

import greenCheckMarkAnimationData from "../../../imgs/green_check_mark.json";

import styles from "../../../styles/main/subcomponents/GreenCheckMark.module.css";

const GreenCheckMark = () => {
    const animationContainer = useRef(null);

    useEffect(() => {
        const anim = lottie.loadAnimation({
            container: animationContainer.current, // The DOM element that will contain the animation
            renderer: 'svg',
            loop: false,
            autoplay: true,
            animationData: greenCheckMarkAnimationData, // The JSON data of the animation
        });

        return () => anim.destroy(); // Optional clean up for unmounting
    }, []);

    return <div ref={animationContainer} className={styles.green_check_mark_container}/>;
};

export default GreenCheckMark;