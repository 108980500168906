import React, { createContext, useCallback, useContext, useState } from "react";
import {
    checkAuthentication,
    checkPasswordResetLink,
    cleverLogin,
    deleteAccountLinkingRequest,
    fetchAdminDashboard,
    fetchAdminDashboardLast50Users,
    fetchIPGeolocation,
    getUserProfile,
    linkAccount,
    processAccountSetup,
    sendAccountLinkingRequest,
    sendPasswordResetEmail,
    unlinkAccount,
    userGoogleLogin,
    userGoogleSignupViaAccessToken,
    userLogin,
    userLogOut,
    userResetPassword,
    userSignup,
} from "../../api/apiCalls";
import { Logtail } from "@logtail/browser";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const logtail = new Logtail(process.env.REACT_APP_LOGTAIL_ATHENA_FRONTEND_SOURCE_TOKEN);

const AuthContext = createContext();
const MARKETING_SITE_URL = process.env.REACT_APP_MARKETING_SITE_URL;

export function useAuthContext() {
    return useContext(AuthContext);
}

export function AuthProvider({ children }) {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [userProfile, setUserProfile] = useState({});

    const navigate = useNavigate();

    function handleGoogleLogin(credentialResponse, navigateOnLoginCompletion) {
        userGoogleLogin(credentialResponse.credential)
            .then((response) => {
                setUserProfile(response.data.user);
                setIsLoggedIn(true);
                navigate(navigateOnLoginCompletion);
            })
            .catch((error) => {
                navigate("/signup");
            });
    }

    function handleUserLogin(formData, navigateOnLoginCompletion) {
        return userLogin(formData)
            .then((response) => {
                setUserProfile(response.data.user);
                setIsLoggedIn(true);
                navigate(navigateOnLoginCompletion);
            })
            .catch((error) => {
                throw error;
            });
    }

    function handleUserLogout(logoutRedirectUrl = MARKETING_SITE_URL) {
        userLogOut()
            .then((response) => {
                setUserProfile({});
                setIsLoggedIn(false);
                window.location.replace(logoutRedirectUrl);
            })
            .catch((error) => {
                toast.error(error.response.data.error);
            });
    }

    function handleGoogleSignupViaAccessToken(tokenResponse, navigateOnSignupCompletion) {
        fetchIPGeolocation()
            .then((response) => {
                const formData = {
                    access_token: tokenResponse.access_token,
                    country: response?.data?.country,
                };
                return userGoogleSignupViaAccessToken(formData);
            })
            .then((response) => {
                setUserProfile(response.data.user);
                setIsLoggedIn(true);
                navigate(navigateOnSignupCompletion);
            })
            .catch((error) => {
                logtail.error(`Error during google user signup`, {
                    additionalInfo: {
                        message: error.response.data.error,
                        tokenErrorDescription: tokenResponse.error_description ?? "",
                    },
                });
                toast.error(error.response.data.error);
            });
    }

    function handlePasswordResetLinkCheck(userId, token) {
        return checkPasswordResetLink(userId, token);
    }

    function handleResetPassword(formData) {
        return userResetPassword(formData)
            .then((_) => {
                // TODO: Replace with toast notifications
                alert("Password reset successfully.");
                navigate("/login");
            })
            .catch((error) => {
                throw error;
            });
    }

    function handleSendPasswordResetEmail(formData) {
        return sendPasswordResetEmail(formData)
            .then((_) => {})
            .catch((error) => {
                throw error;
            });
    }

    function handleUserSignup(formData, navigateOnSignupCompletion) {
        return fetchIPGeolocation()
            .then((response) => {
                formData["country"] = response?.data?.country;
                return userSignup(formData);
            })
            .then((response) => {
                setUserProfile(response.data.user);
                setIsLoggedIn(true);
                navigate(navigateOnSignupCompletion);
            })
            .catch((error) => {
                console.log("error", error);
                logtail.error(`Error during user signup ${formData.email}`, {
                    additionalInfo: {
                        message: error.response.data.error,
                    },
                });
                throw error;
            });
    }

    function handleAuthenticationCheck() {
        checkAuthentication()
            .then((response) => {
                setIsLoggedIn(response.data.is_authenticated);
                if (response.data.is_authenticated) {
                    getUserProfile().then((response) => {
                        setUserProfile(response.data.user);
                    });
                }
            })
            .catch((_) => {});
    }

    function handleAccountSetup(data) {
        processAccountSetup(data)
            .then((_) => navigate("/"))
            .catch((_) => {});
    }

    function handleAccountLinkingRequest(data) {
        // We can do a simple return sendAccountLinkingRequest(data) here
        return sendAccountLinkingRequest(data)
            .then((response) => {
                return response;
            })
            .catch((error) => {
                throw error;
            });
    }

    function handleCleverLogin(formData) {
        return cleverLogin(formData).then((response) => {
            setUserProfile(response.data.user);
            setIsLoggedIn(true);
            navigate("/");
        });
    }

    // DEPRECATED
    function handleAccountUnlinkingRequest(formData) {
        return unlinkAccount(formData)
            .then((_) => console.log("unlinking request sent successfully!"))
            .catch((_) => console.error("account unlinking failed"));
    }

    function handleConfirmIncomingLinkingRequest(formData) {
        return linkAccount(formData)
            .then((_) => console.log("linking account confirmed successfully!"))
            .catch((_) => console.error("account linking failed"));
    }

    function handleDeleteIncomingLinkingRequest(formData) {
        return deleteAccountLinkingRequest(formData)
            .then((_) => console.log("linking account deleted successfully!"))
            .catch((_) => console.error("account linking deletion failed"));
    }

    // Admin dashboard stuff
    const handleFetchAdminDashboard = useCallback(() => fetchAdminDashboard(), []);
    const handleFetchAdminDashboardLast50Users = useCallback((page) => fetchAdminDashboardLast50Users(page), []);

    return (
        <AuthContext.Provider
            value={{
                isLoggedIn,
                userProfile,
                handleAccountLinkingRequest,
                handleAccountUnlinkingRequest,
                handleConfirmIncomingLinkingRequest,
                handleDeleteIncomingLinkingRequest,
                handleUserLogin,
                handleUserLogout,
                handleFetchAdminDashboard,
                handleFetchAdminDashboardLast50Users,
                handleGoogleLogin,
                handleGoogleSignupViaAccessToken,
                handlePasswordResetLinkCheck,
                handleResetPassword,
                handleSendPasswordResetEmail,
                handleUserSignup,
                handleAuthenticationCheck,
                handleAccountSetup,
                handleCleverLogin,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
}
