import BaseButton from "../base/BaseButton";
import blackAthenaV2 from "../../imgs/black-athena-v2.png";
import styles from "../../styles/auth/AuthPage.module.css";
import { ReactComponent as CleverLogo } from "../../imgs/clever-blue-logomark.svg";
import { ReactComponent as GoogleLogo } from "../../imgs/google-logomark.svg";
import { ReactComponent as AthenaBlackLogo } from "../../imgs/athena-black-logomark.svg";
import whiteAthenaV2 from "../../imgs/white_athena_v2.png";
import { useAuthContext } from "./AuthContext";

import { useGoogleLogin } from "@react-oauth/google";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

export default function SignupPage(props) {
    const { handleGoogleSignupViaAccessToken, handleUserSignup } = useAuthContext();
    const navigate = useNavigate();
    const [showEmailInput, setShowEmailInput] = useState(false);
    const [signupErrors, setSignupErrors] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleSubmit = (e) => {
        e.preventDefault();
        const formData = {
            first_name: e.target.first_name.value,
            last_name: e.target.last_name.value,
            email: e.target.email.value,
            password1: e.target.password1.value,
            password2: e.target.password2.value,
        };
        setIsSubmitting(true);
        handleUserSignup(formData, props.navigateOnSignupCompletion)
            .then((_) => setIsSubmitting(false))
            .catch((error) => {
                setSignupErrors({ ...error.response.data.error });
                setIsSubmitting(false);
                toast.error(error.response.data.error);
            });
    };

    const emailInput = (
        <div className={styles.signup_form_inputs}>
            <form onSubmit={handleSubmit}>
                <div className={styles.form_input}>
                    <label htmlFor="firstName">First Name</label>
                    <input type="name" id="firstName" name="first_name" placeholder="First Name" required />
                    {signupErrors.first_name && <div className={styles.error}>{signupErrors.first_name}</div>}
                </div>
                <div className={styles.form_input}>
                    <label htmlFor="lastName">Last Name</label>
                    <input type="name" id="lastName" name="last_name" placeholder="Last Name" />
                    {signupErrors.last_name && <div className={styles.error}>{signupErrors.last_name}</div>}
                </div>
                <div className={styles.form_input}>
                    <label htmlFor="email">Email Address</label>
                    <input type="email" id="email" name="email" placeholder="example@athenaco.ai" required />
                    {signupErrors.email && <div className={styles.error}>{signupErrors.email}</div>}
                </div>
                <div className={styles.form_input}>
                    <label htmlFor="password1">Password</label>
                    <input type="password" id="password1" name="password1" placeholder="Password" required />
                    {signupErrors.password1 && <div className={styles.error}>{signupErrors.password1}</div>}
                </div>
                <div className={styles.form_input}>
                    <label htmlFor="password2">Confirm Password</label>
                    <input type="password" id="password2" name="password2" placeholder="Password" required />
                    {signupErrors.password2 && <div className={styles.error}>{signupErrors.password2}</div>}
                </div>
                <BaseButton className={styles.auth_action_button} disabled={isSubmitting}>
                    Create Account
                </BaseButton>
            </form>
            <span className={styles.another_method_text} onClick={() => setShowEmailInput(false)}>
                Use Another Method
            </span>
        </div>
    );

    const googleLogin = useGoogleLogin({
        clientId: process.env.REACT_APP_GSI_CLIENT_ID,
        onSuccess: (tokenResponse) => {
            handleGoogleSignupViaAccessToken(tokenResponse, props.navigateOnSignupCompletion);
        },
        onError: (errorResponse) => {
            toast.error(`Error with google login: ${errorResponse.error_description}`);
        },
    });

    const redirectUri = process.env.REACT_APP_IS_LOCAL_ENVIRONMENT
        ? process.env.REACT_APP_LOCAL_CLEVER_OAUTH_URL_ESCAPED
        : process.env.REACT_APP_PROD_CLEVER_OAUTH_URL_ESCAPED;
    const clientId = process.env.REACT_APP_CLEVER_APP_CLIENT_ID;
    const handleCleverLogin = () => {
        window.location.href = `https://clever.com/oauth/authorize?response_type=code&redirect_uri=${redirectUri}&client_id=${clientId}`;
    };

    const signupOptions = (
        <div className={styles.signup_options}>
            <BaseButton className={styles.auth_button} onClick={() => googleLogin()}>
                <GoogleLogo className={styles.auth_button_icon} />
                <span className={styles.auth_button_text}>Continue with Google</span>
            </BaseButton>
            <BaseButton
                className={styles.auth_button}
                onClick={() => {
                    setShowEmailInput(true);
                    setSignupErrors({});
                }}
            >
                <AthenaBlackLogo className={styles.auth_button_icon} />
                <span className={styles.auth_button_text}>Continue with Email</span>
            </BaseButton>
            <BaseButton className={styles.auth_button} onClick={() => handleCleverLogin()}>
                <CleverLogo className={styles.auth_button_icon} />
                <span className={styles.auth_button_text}>Continue with Clever</span>
            </BaseButton>
        </div>
    );

    return (
        <div className={styles.signup_body}>
            <div className={styles.auth_page_header}>
                <img src={blackAthenaV2} alt="Black Athena logo" onClick={() => navigate("/")} />
                <h1>Create Your Account</h1>
                <p>
                    By creating an account, you agree to our{" "}
                    <a
                        href="https://docs.google.com/document/d/1bdweiU6PCRxniMyH_qgmmFpMuuD9olTn/edit?usp=drive_link"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Acceptable Use Policy
                    </a>
                    ,{" "}
                    <a
                        href="https://docs.google.com/document/d/1xP7DdvvQ_KZ94Bm0zxqZIITbFiKz3BqV/edit?usp=drive_link"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Privacy Policy
                    </a>
                    ,{" "}
                    <a
                        href="https://docs.google.com/document/d/1UZDGiM7kpBEm6sxCfS3GmlkqKaUhGt0G/edit?usp=drive_link"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Terms of Service
                    </a>
                    , and{" "}
                    <a
                        href="https://docs.google.com/document/d/1hzfHfhid-b689Dbtn1y4A63ix4h9JCHj/edit?usp=drive_link"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        User Agreement
                    </a>
                    .
                </p>
            </div>
            {showEmailInput ? emailInput : signupOptions}
            <div className={styles.signup_footer}>
                Already have an account?{" "}
                <span className={styles.link} onClick={props.onLoginClick}>
                    Log In
                </span>
            </div>
        </div>
    );
}
