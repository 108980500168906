import alabamaLandscape from "../../imgs/school-assets/alabama-landscape.jpg";
import alabamaLogo from "../../imgs/school-assets/alabama-logo.jpg";
import amherstLandscape from "../../imgs/school-assets/amherst-landscape.jpg";
import amherstLogo from "../../imgs/school-assets/amherst-logo.png";
import asuLandscape from "../../imgs/school-assets/asu-landscape.jpg";
import asuLogo from "../../imgs/school-assets/asu-logo.png";
import auburnLandscape from "../../imgs/school-assets/auburn-landscape.jpg";
import auburnLogo from "../../imgs/school-assets/auburn-logo.png";
import baylorLandscape from "../../imgs/school-assets/baylor-landscape.jpg";
import baylorLogo from "../../imgs/school-assets/baylor-logo.jpg";
import binghamtonLandscape from "../../imgs/school-assets/binghamton-landscape.jpg";
import binghamtonLogo from "../../imgs/school-assets/binghamton-logo.png";
import bostoncollegeLandscape from "../../imgs/school-assets/bostoncollege-landscape.jpg";
import bostoncollegeLogo from "../../imgs/school-assets/bostoncollege-logo.png";
import bowdoinLandscape from "../../imgs/school-assets/bowdoin-landscape.jpg";
import bowdoinLogo from "../../imgs/school-assets/bowdoin-logo.jpg";
import brandeisLandscape from "../../imgs/school-assets/brandeis-landscape.jpg";
import brandeisLogo from "../../imgs/school-assets/brandeis-logo.jpg";
import brownLandscape from "../../imgs/school-assets/brown-landscape.jpg";
import brownLogo from "../../imgs/school-assets/brown-logo.png";
import buLandscape from "../../imgs/school-assets/bu-landscape.jpg";
import buLogo from "../../imgs/school-assets/bu-logo.jpg";
import caltechLandscape from "../../imgs/school-assets/caltech-landscape.jpg";
import caltechLogo from "../../imgs/school-assets/caltech-logo.png";
import carletonLandscape from "../../imgs/school-assets/carleton-landscape.jpg";
import carletonLogo from "../../imgs/school-assets/carleton-logo.png";
import clemsonLandscape from "../../imgs/school-assets/clemson-landscape.jpg";
import clemsonLogo from "../../imgs/school-assets/clemson-logo.png";
import cmcLandscape from "../../imgs/school-assets/cmc-landscape.jpg";
import cmcLogo from "../../imgs/school-assets/cmc-logo.jpg";
import cmuLandscape from "../../imgs/school-assets/cmu-landscape.jpg";
import cmuLogo from "../../imgs/school-assets/cmu-logo.jpg";
import columbiaLandscape from "../../imgs/school-assets/columbia-landscape.jpg";
import columbiaLogo from "../../imgs/school-assets/columbia-logo.png";
import commonappLogo from "../../imgs/school-assets/commonapp-logo.png";
import cornellLandscape from "../../imgs/school-assets/cornell-landscape.jpg";
import cornellLogo from "../../imgs/school-assets/cornell-logo.png";
import csulbLandscape from "../../imgs/school-assets/csulb-landscape.jpg";
import csulbLogo from "../../imgs/school-assets/csulb-logo.png";
import casewesternLandscape from "../../imgs/school-assets/casewestern-landscape.jpg";
import casewesternLogo from "../../imgs/school-assets/casewestern-logo.png";
import dartmouthLandscape from "../../imgs/school-assets/dartmouth-landscape.jpg";
import dartmouthLogo from "../../imgs/school-assets/dartmouth-logo.jpg";
import drexelLandscape from "../../imgs/school-assets/drexel-landscape.jpg";
import drexelLogo from "../../imgs/school-assets/drexel-logo.png";
import dukeLandscape from "../../imgs/school-assets/duke-landscape.jpg";
import dukeLogo from "../../imgs/school-assets/duke-logo.png";
import emoryLandscape from "../../imgs/school-assets/emory-landscape.jpg";
import emoryLogo from "../../imgs/school-assets/emory-logo.png";
import fordhamLandscape from "../../imgs/school-assets/fordham-landscape.jpg";
import fordhamLogo from "../../imgs/school-assets/fordham-logo.png";
import floridastateLandscape from "../../imgs/school-assets/floridastate-landscape.jpg";
import floridastateLogo from "../../imgs/school-assets/floridastate-logo.png";
import georgiatechLandscape from "../../imgs/school-assets/georgiatech-landscape.jpg";
import georgiatechLogo from "../../imgs/school-assets/georgiatech-logo.png";
import georgetownLandscape from "../../imgs/school-assets/georgetown-landscape.jpg";
import georgetownLogo from "../../imgs/school-assets/georgetown-logo.png";
import gonzagaLandscape from "../../imgs/school-assets/gonzaga-landscape.jpg";
import gonzagaLogo from "../../imgs/school-assets/gonzaga-logo.png";
import gwuLandscape from "../../imgs/school-assets/gwu-landscape.jpg";
import gwuLogo from "../../imgs/school-assets/gwu-logo.jpg";
import hamiltonLandscape from "../../imgs/school-assets/hamilton-landscape.jpg";
import hamiltonLogo from "../../imgs/school-assets/hamilton-logo.png";
import harvardLandscape from "../../imgs/school-assets/harvard-landscape.png";
import harvardLogo from "../../imgs/school-assets/harvard-logo.jpg";
import harveymuddLandscape from "../../imgs/school-assets/harveymudd-landscape.jpg";
import harveymuddLogo from "../../imgs/school-assets/harveymudd-logo.png";
import iitLandscape from "../../imgs/school-assets/iit-landscape.jpg";
import iitLogo from "../../imgs/school-assets/iit-logo.png";
import iubloomingtonLandscape from "../../imgs/school-assets/iubloomington-landscape.jpg";
import iubloomingtonLogo from "../../imgs/school-assets/iubloomington-logo.png";
import jhuLandscape from "../../imgs/school-assets/jhu-landscape.jpg";
import jhuLogo from "../../imgs/school-assets/jhu-logo.jpg";
import lehighLandscape from "../../imgs/school-assets/lehigh-landscape.jpg";
import lehighLogo from "../../imgs/school-assets/lehigh-logo.png";
import lmuLandscape from "../../imgs/school-assets/lmu-landscape.jpg";
import lmuLogo from "../../imgs/school-assets/lmu-logo.png";
import marquetteLandscape from "../../imgs/school-assets/marquette-landscape.jpg";
import marquetteLogo from "../../imgs/school-assets/marquette-logo.png";
import cominesLandscape from "../../imgs/school-assets/comines-landscape.jpg";
import cominesLogo from "../../imgs/school-assets/comines-logo.jpg";
import mitLandscape from "../../imgs/school-assets/mit-landscape.png";
import mitLogo from "../../imgs/school-assets/mit-logo.jpg";
import michiganstateLandscape from "../../imgs/school-assets/michiganstate-landscape.jpg";
import michiganstateLogo from "../../imgs/school-assets/michiganstate-logo.png";
import ncstateLandscape from "../../imgs/school-assets/ncstate-landscape.jpg";
import ncstateLogo from "../../imgs/school-assets/ncstate-logo.png";
import njitLandscape from "../../imgs/school-assets/njit-landscape.jpg";
import njitLogo from "../../imgs/school-assets/njit-logo.png";
import northeasternLandscape from "../../imgs/school-assets/northeastern-landscape.jpg";
import northeasternLogo from "../../imgs/school-assets/northeastern-logo.png";
import northwesternLandscape from "../../imgs/school-assets/northwestern-landscape.jpg";
import northwesternLogo from "../../imgs/school-assets/northwestern-logo.png";
import notredameLandscape from "../../imgs/school-assets/notredame-landscape.jpg";
import notredameLogo from "../../imgs/school-assets/notredame-logo.jpg";
import nyuLandscape from "../../imgs/school-assets/nyu-landscape.jpg";
import nyuLogo from "../../imgs/school-assets/nyu-logo.jpg";
import osuLandscape from "../../imgs/school-assets/osu-landscape.jpg";
import osuLogo from "../../imgs/school-assets/osu-logo.png";
import pennstateLandscape from "../../imgs/school-assets/pennstate-landscape.jpg";
import pennstateLogo from "../../imgs/school-assets/pennstate-logo.png";
import pepperdineLandscape from "../../imgs/school-assets/pepperdine-landscape.jpg";
import pepperdineLogo from "../../imgs/school-assets/pepperdine-logo.png";
import pittLandscape from "../../imgs/school-assets/pitt-landscape.jpg";
import pittLogo from "../../imgs/school-assets/pitt-logo.png";
import pomonaLandscape from "../../imgs/school-assets/pomona-landscape.jpg";
import pomonaLogo from "../../imgs/school-assets/pomona-logo.jpg";
import princetonLandscape from "../../imgs/school-assets/princeton-landscape.jpg";
import princetonLogo from "../../imgs/school-assets/princeton-logo.png";
import purdueLandscape from "../../imgs/school-assets/purdue-landscape.jpg";
import purdueLogo from "../../imgs/school-assets/purdue-logo.png";
import riceLandscape from "../../imgs/school-assets/rice-landscape.png";
import riceLogo from "../../imgs/school-assets/rice-logo.jpg";
import ritLandscape from "../../imgs/school-assets/rit-landscape.jpg";
import ritLogo from "../../imgs/school-assets/rit-logo.png";
import rpiLandscape from "../../imgs/school-assets/rpi-landscape.jpg";
import rpiLogo from "../../imgs/school-assets/rpi-logo.png";
import rutgersLandscape from "../../imgs/school-assets/rutgers-landscape.jpg";
import rutgersLogo from "../../imgs/school-assets/rutgers-logo.png";
import rutgersnewarkLandscape from "../../imgs/school-assets/rutgersnewark-landscape.jpg";
import rutgersnewarkLogo from "../../imgs/school-assets/rutgersnewark-logo.jpg";
import santaclaraLandscape from "../../imgs/school-assets/santaclara-landscape.jpg";
import santaclaraLogo from "../../imgs/school-assets/santaclara-logo.png";
import sdsuLandscape from "../../imgs/school-assets/sdsu-landscape.jpg";
import sdsuLogo from "../../imgs/school-assets/sdsu-logo.jpg";
import sloLandscape from "../../imgs/school-assets/slo-landscape.jpg";
import sloLogo from "../../imgs/school-assets/slo-logo.png";
import stanfordLandscape from "../../imgs/school-assets/stanford-landscape.jpg";
import stanfordLogo from "../../imgs/school-assets/stanford-logo.png";
import stevenstechLandscape from "../../imgs/school-assets/stevenstech-landscape.jpg";
import stevenstechLogo from "../../imgs/school-assets/stevenstech-logo.png";
import stonybrookLandscape from "../../imgs/school-assets/stonybrook-landscape.jpg";
import stonybrookLogo from "../../imgs/school-assets/stonybrook-logo.png";
import swarthmoreLandscape from "../../imgs/school-assets/swarthmore-landscape.jpg";
import swarthmoreLogo from "../../imgs/school-assets/swarthmore-logo.jpg";
import syracuseLandscape from "../../imgs/school-assets/syracuse-landscape.jpg";
import syracuseLogo from "../../imgs/school-assets/syracuse-logo.png";
import texasamLandscape from "../../imgs/school-assets/texasam-landscape.jpg";
import texasamLogo from "../../imgs/school-assets/texasam-logo.jpg";
import templeLandscape from "../../imgs/school-assets/temple-landscape.jpg";
import templeLogo from "../../imgs/school-assets/temple-logo.png";
import tuftsLandscape from "../../imgs/school-assets/tufts-landscape.jpg";
import tuftsLogo from "../../imgs/school-assets/tufts-logo.jpg";
import tulaneLandscape from "../../imgs/school-assets/tulane-landscape.jpg";
import tulaneLogo from "../../imgs/school-assets/tulane-logo.png";
import ubuffaloLandscape from "../../imgs/school-assets/ubuffalo-landscape.jpg";
import ubuffaloLogo from "../../imgs/school-assets/ubuffalo-logo.jpg";
import ucLandscape from "../../imgs/school-assets/uc-landscape.jpg";
import ucLogo from "../../imgs/school-assets/uc-logo.png";
import uchicagoLandscape from "../../imgs/school-assets/uchicago-landscape.jpg";
import uchicagoLogo from "../../imgs/school-assets/uchicago-logo.png";
import uconnLandscape from "../../imgs/school-assets/uconn-landscape.jpg";
import uconnLogo from "../../imgs/school-assets/uconn-logo.png";
import udelawareLandscape from "../../imgs/school-assets/udelaware-landscape.jpg";
import udelawareLogo from "../../imgs/school-assets/udelaware-logo.jpg";
import ufloridaLandscape from "../../imgs/school-assets/uflorida-landscape.jpg";
import ufloridaLogo from "../../imgs/school-assets/uflorida-logo.png";
import ugaLandscape from "../../imgs/school-assets/uga-landscape.jpg";
import ugaLogo from "../../imgs/school-assets/uga-logo.png";
import uillinoischicagoLandscape from "../../imgs/school-assets/uillinoischicago-landscape.jpg";
import uillinoischicagoLogo from "../../imgs/school-assets/uillinoischicago-logo.png";
import uiowaLandscape from "../../imgs/school-assets/uiowa-landscape.jpg";
import uiowaLogo from "../../imgs/school-assets/uiowa-logo.png";
import uiucLandscape from "../../imgs/school-assets/uiuc-landscape.jpg";
import uiucLogo from "../../imgs/school-assets/uiuc-logo.png";
import umassLandscape from "../../imgs/school-assets/umass-landscape.jpg";
import umassLogo from "../../imgs/school-assets/umass-logo.png";
import umdLandscape from "../../imgs/school-assets/umd-landscape.jpg";
import umdLogo from "../../imgs/school-assets/umd-logo.png";
import umiamiLandscape from "../../imgs/school-assets/umiami-landscape.jpg";
import umiamiLogo from "../../imgs/school-assets/umiami-logo.png";
import umichLandscape from "../../imgs/school-assets/umich-landscape.jpg";
import umichLogo from "../../imgs/school-assets/umich-logo.png";
import umnLandscape from "../../imgs/school-assets/umn-landscape.jpg";
import umnLogo from "../../imgs/school-assets/umn-logo.jpg";
import uncLandscape from "../../imgs/school-assets/unc-landscape.jpg";
import uncLogo from "../../imgs/school-assets/unc-logo.png";
import uoregonLandscape from "../../imgs/school-assets/uoregon-landscape.jpg";
import uoregonLogo from "../../imgs/school-assets/uoregon-logo.png";
import upennLandscape from "../../imgs/school-assets/upenn-landscape.jpg";
import upennLogo from "../../imgs/school-assets/upenn-logo.png";
import urochesterLandscape from "../../imgs/school-assets/urochester-landscape.jpg";
import urochesterLogo from "../../imgs/school-assets/urochester-logo.png";
import airforceacademyLandscape from "../../imgs/school-assets/airforceacademy-landscape.jpg";
import airforceacademyLogo from "../../imgs/school-assets/airforceacademy-logo.png";
import uscLandscape from "../../imgs/school-assets/usc-landscape.jpg";
import uscLogo from "../../imgs/school-assets/usc-logo.jpg";
import usdLandscape from "../../imgs/school-assets/usd-landscape.jpg";
import usdLogo from "../../imgs/school-assets/usd-logo.png";
import usouthfloridaLandscape from "../../imgs/school-assets/usouthflorida-landscape.jpg";
import usouthfloridaLogo from "../../imgs/school-assets/usouthflorida-logo.png";
import navalacademyLandscape from "../../imgs/school-assets/navalacademy-landscape.jpg";
import navalacademyLogo from "../../imgs/school-assets/navalacademy-logo.png";
import utaustinLandscape from "../../imgs/school-assets/utaustin-landscape.jpg";
import utaustinLogo from "../../imgs/school-assets/utaustin-logo.png";
import uvaLandscape from "../../imgs/school-assets/uva-landscape.jpg";
import uvaLogo from "../../imgs/school-assets/uva-logo.jpg";
import uwashingtonLandscape from "../../imgs/school-assets/uwashington-landscape.jpg";
import uwashingtonLogo from "../../imgs/school-assets/uwashington-logo.jpg";
import vanderbiltLandscape from "../../imgs/school-assets/vanderbilt-landscape.jpg";
import vanderbiltLogo from "../../imgs/school-assets/vanderbilt-logo.png";
import villanovaLandscape from "../../imgs/school-assets/villanova-landscape.jpg";
import villanovaLogo from "../../imgs/school-assets/villanova-logo.png";
import virginiatechLandscape from "../../imgs/school-assets/virginiatech-landscape.jpg";
import virginiatechLogo from "../../imgs/school-assets/virginiatech-logo.jpg";
import washuLandscape from "../../imgs/school-assets/washu-landscape.jpg";
import washuLogo from "../../imgs/school-assets/washu-logo.png";
import wellesleyLandscape from "../../imgs/school-assets/wellesley-landscape.jpg";
import wellesleyLogo from "../../imgs/school-assets/wellesley-logo.png";
import westpointLandscape from "../../imgs/school-assets/westpoint-landscape.jpg";
import westpointLogo from "../../imgs/school-assets/westpoint-logo.png";
import wakeforestLandscape from "../../imgs/school-assets/wakeforest-landscape.jpg";
import wakeforestLogo from "../../imgs/school-assets/wakeforest-logo.png";
import williamsLandscape from "../../imgs/school-assets/williams-landscape.jpg";
import williamsLogo from "../../imgs/school-assets/williams-logo.jpg";
import wiscLandscape from "../../imgs/school-assets/wisc-landscape.jpg";
import wiscLogo from "../../imgs/school-assets/wisc-logo.jpg";
import williamandmaryLandscape from "../../imgs/school-assets/williamandmary-landscape.jpg";
import williamandmaryLogo from "../../imgs/school-assets/williamandmary-logo.jpg";
import wpiLandscape from "../../imgs/school-assets/wpi-landscape.jpg";
import wpiLogo from "../../imgs/school-assets/wpi-logo.png";
import yaleLandscape from "../../imgs/school-assets/yale-landscape.jpg";
import yaleLogo from "../../imgs/school-assets/yale-logo.png";

/* Art Schools */
import accdLandscape from "../../imgs/school-assets/accd-landscape.jpg";
import accdLogo from "../../imgs/school-assets/accd-logo.png";
import micaLandscape from "../../imgs/school-assets/mica-landscape.jpg";
import micaLogo from "../../imgs/school-assets/mica-logo.png";
import parsonsLandscape from "../../imgs/school-assets/parsons-landscape.jpg";
import parsonsLogo from "../../imgs/school-assets/parsons-logo.png";
import prattLandscape from "../../imgs/school-assets/pratt-landscape.jpg";
import prattLogo from "../../imgs/school-assets/pratt-logo.png";
import risdLandscape from "../../imgs/school-assets/risd-landscape.jpg";
import risdLogo from "../../imgs/school-assets/risd-logo.png";
import scadLandscape from "../../imgs/school-assets/scad-landscape.jpg";
import scadLogo from "../../imgs/school-assets/scad-logo.png";
import svaLandscape from "../../imgs/school-assets/sva-landscape.png";
import svaLogo from "../../imgs/school-assets/sva-logo.png";

/*
    schoolInfo - Contains all information for the schools we support
    - The keys of each object are the school codes for each university we support.
    - Each value contains
      - schoolName: the name of the school
      - searchTerms: terms the user could search that would yield this school
      - assets: assets for the school
        - bannerImage: the image to display on the top of the application page
        - logo: the logo of the school
*/
export const schoolInfo = {
    // Common app will NOT show up in new application dropdown
    common_app: {
        schoolName: "Common Application",
        searchTerms: ["Common Application", "Common App"],
        assets: {
            bannerImage: null,
            logo: commonappLogo,
        },
    },
    princeton: {
        schoolName: "Princeton University",
        searchTerms: ["Princeton University"],
        assets: {
            bannerImage: princetonLandscape,
            logo: princetonLogo,
        },
    },
    mit: {
        schoolName: "Massachusetts Institute of Technology",
        searchTerms: ["Massachusetts Institute of Technology", "MIT"],
        assets: {
            bannerImage: mitLandscape,
            logo: mitLogo,
        },
    },
    harvard: {
        schoolName: "Harvard University",
        searchTerms: ["Harvard University"],
        assets: {
            bannerImage: harvardLandscape,
            logo: harvardLogo,
        },
    },
    stanford: {
        schoolName: "Stanford University",
        searchTerms: ["Stanford University"],
        assets: {
            bannerImage: stanfordLandscape,
            logo: stanfordLogo,
        },
    },
    yale: {
        schoolName: "Yale University",
        searchTerms: ["Yale University"],
        assets: {
            bannerImage: yaleLandscape,
            logo: yaleLogo,
        },
    },
    upenn: {
        schoolName: "University of Pennsylvania",
        searchTerms: ["University of Pennsylvania", "UPenn", "Penn"],
        assets: {
            bannerImage: upennLandscape,
            logo: upennLogo,
        },
    },
    caltech: {
        schoolName: "California Institute of Technology",
        searchTerms: ["California Institute of Technology", "Caltech"],
        assets: {
            bannerImage: caltechLandscape,
            logo: caltechLogo,
        },
    },
    duke: {
        schoolName: "Duke University",
        searchTerms: ["Duke University"],
        assets: {
            bannerImage: dukeLandscape,
            logo: dukeLogo,
        },
    },
    brown: {
        schoolName: "Brown University",
        searchTerms: ["Brown University"],
        assets: {
            bannerImage: brownLandscape,
            logo: brownLogo,
        },
        isAIAllowed: false,
    },
    jhu: {
        schoolName: "Johns Hopkins University",
        searchTerms: ["Johns Hopkins University", "JHU"],
        assets: {
            bannerImage: jhuLandscape,
            logo: jhuLogo,
        },
    },
    northwestern: {
        schoolName: "Northwestern University",
        searchTerms: ["Northwestern University"],
        assets: {
            bannerImage: northwesternLandscape,
            logo: northwesternLogo,
        },
    },
    columbia: {
        schoolName: "Columbia University",
        searchTerms: ["Columbia University"],
        assets: {
            bannerImage: columbiaLandscape,
            logo: columbiaLogo,
        },
    },
    cornell: {
        schoolName: "Cornell University",
        searchTerms: ["Cornell University"],
        assets: {
            bannerImage: cornellLandscape,
            logo: cornellLogo,
        },
    },
    uchicago: {
        schoolName: "University of Chicago",
        searchTerms: ["University of Chicago", "UChicago"],
        assets: {
            bannerImage: uchicagoLandscape,
            logo: uchicagoLogo,
        },
    },
    uc: {
        schoolName: "University of California",
        searchTerms: [
            "University of California",
            "UC",
            "UC Berkeley",
            "UCLA",
            "UC Los Angeles",
            "UCSD",
            "UC San Diego",
            "UCSB",
            "UC Santa Barbara",
            "UCD",
            "UC Davis",
            "UCI",
            "UC Irvine",
            "UCR",
            "UC Riverside",
        ],
        assets: {
            bannerImage: ucLandscape,
            logo: ucLogo,
        },
    },
    rice: {
        schoolName: "Rice University",
        searchTerms: ["Rice University"],
        assets: {
            bannerImage: riceLandscape,
            logo: riceLogo,
        },
    },
    dartmouth: {
        schoolName: "Dartmouth College",
        searchTerms: ["Dartmouth College"],
        assets: {
            bannerImage: dartmouthLandscape,
            logo: dartmouthLogo,
        },
    },
    vanderbilt: {
        schoolName: "Vanderbilt University",
        searchTerms: ["Vanderbilt University"],
        assets: {
            bannerImage: vanderbiltLandscape,
            logo: vanderbiltLogo,
        },
    },
    notredame: {
        schoolName: "University of Notre Dame",
        searchTerms: ["University of Notre Dame"],
        assets: {
            bannerImage: notredameLandscape,
            logo: notredameLogo,
        },
    },
    umich: {
        schoolName: "University of Michigan--Ann Arbor",
        searchTerms: ["University of Michigan--Ann Arbor"],
        assets: {
            bannerImage: umichLandscape,
            logo: umichLogo,
        },
    },
    georgetown: {
        schoolName: "Georgetown University",
        searchTerms: ["Georgetown University"],
        assets: {
            bannerImage: georgetownLandscape,
            logo: georgetownLogo,
        },
    },
    unc: {
        schoolName: "University of North Carolina at Chapel Hill",
        searchTerms: ["University of North Carolina at Chapel Hill", "UNC"],
        assets: {
            bannerImage: uncLandscape,
            logo: uncLogo,
        },
    },
    cmu: {
        schoolName: "Carnegie Mellon University",
        searchTerms: ["Carnegie Mellon University", "CMU"],
        assets: {
            bannerImage: cmuLandscape,
            logo: cmuLogo,
        },
    },
    emory: {
        schoolName: "Emory University",
        searchTerms: ["Emory University"],
        assets: {
            bannerImage: emoryLandscape,
            logo: emoryLogo,
        },
    },
    uva: {
        schoolName: "University of Virginia",
        searchTerms: ["University of Virginia", "UVA"],
        assets: {
            bannerImage: uvaLandscape,
            logo: uvaLogo,
        },
    },
    washu: {
        schoolName: "Washington University in St. Louis",
        searchTerms: ["Washington University in St. Louis", "WashU"],
        assets: {
            bannerImage: washuLandscape,
            logo: washuLogo,
        },
    },
    uflorida: {
        schoolName: "University of Florida",
        searchTerms: ["University of Florida"],
        assets: {
            bannerImage: ufloridaLandscape,
            logo: ufloridaLogo,
        },
    },
    usc: {
        schoolName: "University of Southern California",
        searchTerms: ["University of Southern California", "USC"],
        assets: {
            bannerImage: uscLandscape,
            logo: uscLogo,
        },
    },
    utaustin: {
        schoolName: "University of Texas at Austin",
        searchTerms: ["University of Texas at Austin", "UT Austin"],
        assets: {
            bannerImage: utaustinLandscape,
            logo: utaustinLogo,
        },
    },
    georgiatech: {
        schoolName: "Georgia Institute of Technology",
        searchTerms: ["Georgia Institute of Technology", "Georgia Tech"],
        assets: {
            bannerImage: georgiatechLandscape,
            logo: georgiatechLogo,
        },
    },
    nyu: {
        schoolName: "New York University",
        searchTerms: ["New York University", "NYU"],
        assets: {
            bannerImage: nyuLandscape,
            logo: nyuLogo,
        },
    },
    uiuc: {
        schoolName: "University of Illinois Urbana-Champaign",
        searchTerms: ["University of Illinois Urbana-Champaign", "UIUC"],
        assets: {
            bannerImage: uiucLandscape,
            logo: uiucLogo,
        },
    },
    wisc: {
        schoolName: "University of Wisconsin--Madison",
        searchTerms: ["University of Wisconsin--Madison", "UW-Madison"],
        assets: {
            bannerImage: wiscLandscape,
            logo: wiscLogo,
        },
    },
    bostoncollege: {
        schoolName: "Boston College",
        searchTerms: ["Boston College"],
        assets: {
            bannerImage: bostoncollegeLandscape,
            logo: bostoncollegeLogo,
        },
    },
    rutgers: {
        schoolName: "Rutgers University--New Brunswick",
        searchTerms: ["Rutgers University--New Brunswick"],
        assets: {
            bannerImage: rutgersLandscape,
            logo: rutgersLogo,
        },
    },
    tufts: {
        schoolName: "Tufts University",
        searchTerms: ["Tufts University"],
        assets: {
            bannerImage: tuftsLandscape,
            logo: tuftsLogo,
        },
    },
    uwashington: {
        schoolName: "University of Washington",
        searchTerms: ["University of Washington", "UW"],
        assets: {
            bannerImage: uwashingtonLandscape,
            logo: uwashingtonLogo,
        },
    },
    bu: {
        schoolName: "Boston University",
        searchTerms: ["Boston University", "BU"],
        assets: {
            bannerImage: buLandscape,
            logo: buLogo,
        },
    },
    osu: {
        schoolName: "The Ohio State University",
        searchTerms: ["The Ohio State University"],
        assets: {
            bannerImage: osuLandscape,
            logo: osuLogo,
        },
    },
    purdue: {
        schoolName: "Purdue University--Main Campus",
        searchTerms: ["Purdue University--Main Campus"],
        assets: {
            bannerImage: purdueLandscape,
            logo: purdueLogo,
        },
    },
    umd: {
        schoolName: "University of Maryland, College Park",
        searchTerms: ["University of Maryland, College Park", "UMD"],
        assets: {
            bannerImage: umdLandscape,
            logo: umdLogo,
        },
    },
    lehigh: {
        schoolName: "Lehigh University",
        searchTerms: ["Lehigh University", "Lehigh"],
        assets: {
            bannerImage: lehighLandscape,
            logo: lehighLogo,
        },
    },
    texasam: {
        schoolName: "Texas A&M University",
        searchTerms: ["Texas A&M University"],
        assets: {
            bannerImage: texasamLandscape,
            logo: texasamLogo,
        },
    },
    uga: {
        schoolName: "University of Georgia",
        searchTerms: ["University of Georgia", "UGA"],
        assets: {
            bannerImage: ugaLandscape,
            logo: ugaLogo,
        },
    },
    urochester: {
        schoolName: "University of Rochester",
        searchTerms: ["University of Rochester"],
        assets: {
            bannerImage: urochesterLandscape,
            logo: urochesterLogo,
        },
    },
    virginiatech: {
        schoolName: "Virginia Tech",
        searchTerms: ["Virginia Tech"],
        assets: {
            bannerImage: virginiatechLandscape,
            logo: virginiatechLogo,
        },
    },
    wakeforest: {
        schoolName: "Wake Forest University",
        searchTerms: ["Wake Forest University"],
        assets: {
            bannerImage: wakeforestLandscape,
            logo: wakeforestLogo,
        },
    },
    casewestern: {
        schoolName: "Case Western Reserve University",
        searchTerms: ["Case Western Reserve University"],
        assets: {
            bannerImage: casewesternLandscape,
            logo: casewesternLogo,
        },
    },
    floridastate: {
        schoolName: "Florida State University",
        searchTerms: ["Florida State University", "FSU"],
        assets: {
            bannerImage: floridastateLandscape,
            logo: floridastateLogo,
        },
    },
    northeastern: {
        schoolName: "Northeastern University",
        searchTerms: ["Northeastern University"],
        assets: {
            bannerImage: northeasternLandscape,
            logo: northeasternLogo,
        },
    },
    umn: {
        schoolName: "University of Minnesota, Twin Cities",
        searchTerms: ["University of Minnesota, Twin Cities", "UMN"],
        assets: {
            bannerImage: umnLandscape,
            logo: umnLogo,
        },
    },
    williamandmary: {
        schoolName: "William and Mary",
        searchTerms: ["William and Mary"],
        assets: {
            bannerImage: williamandmaryLandscape,
            logo: williamandmaryLogo,
        },
    },
    stonybrook: {
        schoolName: "Stony Brook University--SUNY",
        searchTerms: ["Stony Brook University--SUNY"],
        assets: {
            bannerImage: stonybrookLandscape,
            logo: stonybrookLogo,
        },
    },
    uconn: {
        schoolName: "University of Connecticut",
        searchTerms: ["University of Connecticut", "UConn"],
        assets: {
            bannerImage: uconnLandscape,
            logo: uconnLogo,
        },
    },
    brandeis: {
        schoolName: "Brandeis University",
        searchTerms: ["Brandeis University", "Brandeis"],
        assets: {
            bannerImage: brandeisLandscape,
            logo: brandeisLogo,
        },
    },
    michiganstate: {
        schoolName: "Michigan State University",
        searchTerms: ["Michigan State University", "MSU"],
        assets: {
            bannerImage: michiganstateLandscape,
            logo: michiganstateLogo,
        },
    },
    ncstate: {
        schoolName: "North Carolina State University",
        searchTerms: ["North Carolina State University", "NC State"],
        assets: {
            bannerImage: ncstateLandscape,
            logo: ncstateLogo,
        },
    },
    pennstate: {
        schoolName: "Penn State - University Park",
        searchTerms: ["Penn State - University Park"],
        assets: {
            bannerImage: pennstateLandscape,
            logo: pennstateLogo,
        },
    },
    rpi: {
        schoolName: "Rensselaer Polytechnic Institute",
        searchTerms: ["Rensselaer Polytechnic Institute", "RPI"],
        assets: {
            bannerImage: rpiLandscape,
            logo: rpiLogo,
        },
    },
    santaclara: {
        schoolName: "Santa Clara University",
        searchTerms: ["Santa Clara University"],
        assets: {
            bannerImage: santaclaraLandscape,
            logo: santaclaraLogo,
        },
    },
    gwu: {
        schoolName: "George Washington University",
        searchTerms: ["George Washington University", "GWU"],
        assets: {
            bannerImage: gwuLandscape,
            logo: gwuLogo,
        },
    },
    syracuse: {
        schoolName: "Syracuse University",
        searchTerms: ["Syracuse University"],
        assets: {
            bannerImage: syracuseLandscape,
            logo: syracuseLogo,
        },
    },
    umass: {
        schoolName: "UMass Amherst",
        searchTerms: ["UMass Amherst"],
        assets: {
            bannerImage: umassLandscape,
            logo: umassLogo,
        },
    },
    umiami: {
        schoolName: "University of Miami",
        searchTerms: ["University of Miami", "UMiami"],
        assets: {
            bannerImage: umiamiLandscape,
            logo: umiamiLogo,
        },
    },
    pitt: {
        schoolName: "University of Pittsburgh",
        searchTerms: ["University of Pittsburgh"],
        assets: {
            bannerImage: pittLandscape,
            logo: pittLogo,
        },
    },
    villanova: {
        schoolName: "Villanova University",
        searchTerms: ["Villanova University"],
        assets: {
            bannerImage: villanovaLandscape,
            logo: villanovaLogo,
        },
        isAIAllowed: false,
    },
    binghamton: {
        schoolName: "Binghamton University--SUNY",
        searchTerms: ["Binghamton University--SUNY"],
        assets: {
            bannerImage: binghamtonLandscape,
            logo: binghamtonLogo,
        },
    },
    iubloomington: {
        schoolName: "Indiana University -- Bloomington",
        searchTerms: ["Indiana University -- Bloomington", "IU Bloomington"],
        assets: {
            bannerImage: iubloomingtonLandscape,
            logo: iubloomingtonLogo,
        },
    },
    tulane: {
        schoolName: "Tulane University",
        searchTerms: ["Tulane University"],
        assets: {
            bannerImage: tulaneLandscape,
            logo: tulaneLogo,
        },
    },
    comines: {
        schoolName: "Colorado School of Mines",
        searchTerms: ["Colorado School of Mines"],
        assets: {
            bannerImage: cominesLandscape,
            logo: cominesLogo,
        },
    },
    pepperdine: {
        schoolName: "Pepperdine University",
        searchTerms: ["Pepperdine University"],
        assets: {
            bannerImage: pepperdineLandscape,
            logo: pepperdineLogo,
        },
    },
    stevenstech: {
        schoolName: "Stevens Institute of Technology",
        searchTerms: ["Stevens Institute of Technology"],
        assets: {
            bannerImage: stevenstechLandscape,
            logo: stevenstechLogo,
        },
    },
    ubuffalo: {
        schoolName: "University at Buffalo--SUNY",
        searchTerms: ["University at Buffalo--SUNY", "UB"],
        assets: {
            bannerImage: ubuffaloLandscape,
            logo: ubuffaloLogo,
        },
    },
    udelaware: {
        schoolName: "University of Delaware",
        searchTerms: ["University of Delaware", "UDel"],
        assets: {
            bannerImage: udelawareLandscape,
            logo: udelawareLogo,
        },
    },
    rutgersnewark: {
        schoolName: "Rutgers - Newark",
        searchTerms: ["Rutgers - Newark", "Rutgers Newark"],
        assets: {
            bannerImage: rutgersnewarkLandscape,
            logo: rutgersnewarkLogo,
        },
    },
    uillinoischicago: {
        schoolName: "University of Illinois -- Chicago",
        searchTerms: ["University of Illinois -- Chicago", "UIC"],
        assets: {
            bannerImage: uillinoischicagoLandscape,
            logo: uillinoischicagoLogo,
        },
    },
    wpi: {
        schoolName: "Worcester Polytechnic Institute",
        searchTerms: ["Worcester Polytechnic Institute", "WPI"],
        assets: {
            bannerImage: wpiLandscape,
            logo: wpiLogo,
        },
    },
    clemson: {
        schoolName: "Clemson University",
        searchTerms: ["Clemson University"],
        assets: {
            bannerImage: clemsonLandscape,
            logo: clemsonLogo,
        },
    },
    marquette: {
        schoolName: "Marquette University",
        searchTerms: ["Marquette University"],
        assets: {
            bannerImage: marquetteLandscape,
            logo: marquetteLogo,
        },
    },
    njit: {
        schoolName: "New Jersey Institute of Technology",
        searchTerms: ["New Jersey Institute of Technology", "NJIT"],
        assets: {
            bannerImage: njitLandscape,
            logo: njitLogo,
        },
    },
    fordham: {
        schoolName: "Fordham University",
        searchTerms: ["Fordham University"],
        assets: {
            bannerImage: fordhamLandscape,
            logo: fordhamLogo,
        },
    },
    temple: {
        schoolName: "Temple University",
        searchTerms: ["Temple University"],
        assets: {
            bannerImage: templeLandscape,
            logo: templeLogo,
        },
    },
    usouthflorida: {
        schoolName: "University of South Florida",
        searchTerms: ["University of South Florida", "USF"],
        assets: {
            bannerImage: usouthfloridaLandscape,
            logo: usouthfloridaLogo,
        },
    },
    auburn: {
        schoolName: "Auburn University",
        searchTerms: ["Auburn University"],
        assets: {
            bannerImage: auburnLandscape,
            logo: auburnLogo,
        },
    },
    baylor: {
        schoolName: "Baylor University",
        searchTerms: ["Baylor University"],
        assets: {
            bannerImage: baylorLandscape,
            logo: baylorLogo,
        },
    },
    gonzaga: {
        schoolName: "Gonzaga University",
        searchTerms: ["Gonzaga University"],
        assets: {
            bannerImage: gonzagaLandscape,
            logo: gonzagaLogo,
        },
    },
    lmu: {
        schoolName: "Loyola Marymount University",
        searchTerms: ["Loyola Marymount University", "LMU"],
        assets: {
            bannerImage: lmuLandscape,
            logo: lmuLogo,
        },
    },
    uiowa: {
        schoolName: "University of Iowa",
        searchTerms: ["University of Iowa"],
        assets: {
            bannerImage: uiowaLandscape,
            logo: uiowaLogo,
        },
    },
    drexel: {
        schoolName: "Drexel University",
        searchTerms: ["Drexel University"],
        assets: {
            bannerImage: drexelLandscape,
            logo: drexelLogo,
        },
    },
    iit: {
        schoolName: "Illinois Institute of Technology",
        searchTerms: ["Illinois Institute of Technology", "Illinois Tech"],
        assets: {
            bannerImage: iitLandscape,
            logo: iitLogo,
        },
    },
    rit: {
        schoolName: "Rochester Institute of Technology",
        searchTerms: ["Rochester Institute of Technology", "RIT"],
        assets: {
            bannerImage: ritLandscape,
            logo: ritLogo,
        },
    },
    uoregon: {
        schoolName: "University of Oregon",
        searchTerms: ["University of Oregon"],
        assets: {
            bannerImage: uoregonLandscape,
            logo: uoregonLogo,
        },
    },
    usd: {
        schoolName: "University of San Diego",
        searchTerms: ["University of San Diego", "USD"],
        assets: {
            bannerImage: usdLandscape,
            logo: usdLogo,
        },
    },
    asu: {
        schoolName: "Arizona State University--Tempe",
        searchTerms: ["Arizona State University--Tempe", "ASU"],
        assets: {
            bannerImage: asuLandscape,
            logo: asuLogo,
        },
    },
    csulb: {
        schoolName: "California State University, Long Beach",
        searchTerms: ["California State University, Long Beach", "CSULB"],
        assets: {
            bannerImage: csulbLandscape,
            logo: csulbLogo,
        },
    },
    sdsu: {
        schoolName: "San Diego State University",
        searchTerms: ["San Diego State University", "SDSU"],
        assets: {
            bannerImage: sdsuLandscape,
            logo: sdsuLogo,
        },
    },
    slo: {
        schoolName: "California Polytechnic State University--San Luis Obispo",
        searchTerms: ["California Polytechnic State University--San Luis Obispo", "Cal Poly SLO"],
        assets: {
            bannerImage: sloLandscape,
            logo: sloLogo,
        },
    },
    williams: {
        schoolName: "Williams College",
        searchTerms: ["Williams College"],
        assets: {
            bannerImage: williamsLandscape,
            logo: williamsLogo,
        },
    },
    amherst: {
        schoolName: "Amherst College",
        searchTerms: ["Amherst College"],
        assets: {
            bannerImage: amherstLandscape,
            logo: amherstLogo,
        },
    },
    navalacademy: {
        schoolName: "United States Naval Academy",
        searchTerms: ["United States Naval Academy", "USNA"],
        assets: {
            bannerImage: navalacademyLandscape,
            logo: navalacademyLogo,
        },
    },
    pomona: {
        schoolName: "Pomona College",
        searchTerms: ["Pomona College"],
        assets: {
            bannerImage: pomonaLandscape,
            logo: pomonaLogo,
        },
    },
    swarthmore: {
        schoolName: "Swarthmore College",
        searchTerms: ["Swarthmore College"],
        assets: {
            bannerImage: swarthmoreLandscape,
            logo: swarthmoreLogo,
        },
    },
    wellesley: {
        schoolName: "Wellesley College",
        searchTerms: ["Wellesley College"],
        assets: {
            bannerImage: wellesleyLandscape,
            logo: wellesleyLogo,
        },
    },
    airforceacademy: {
        schoolName: "United States Air Force Academy",
        searchTerms: ["United States Air Force Academy", "USAFA"],
        assets: {
            bannerImage: airforceacademyLandscape,
            logo: airforceacademyLogo,
        },
    },
    westpoint: {
        schoolName: "United States Military Academy at West Point",
        searchTerms: ["United States Military Academy at West Point"],
        assets: {
            bannerImage: westpointLandscape,
            logo: westpointLogo,
        },
    },
    bowdoin: {
        schoolName: "Bowdoin College",
        searchTerms: ["Bowdoin College"],
        assets: {
            bannerImage: bowdoinLandscape,
            logo: bowdoinLogo,
        },
    },
    carleton: {
        schoolName: "Carleton College",
        searchTerms: ["Carleton College"],
        assets: {
            bannerImage: carletonLandscape,
            logo: carletonLogo,
        },
    },
    cmc: {
        schoolName: "Claremont McKenna College",
        searchTerms: ["Claremont McKenna College", "CMC"],
        assets: {
            bannerImage: cmcLandscape,
            logo: cmcLogo,
        },
    },
    harveymudd: {
        schoolName: "Harvey Mudd College",
        searchTerms: ["Harvey Mudd College"],
        assets: {
            bannerImage: harveymuddLandscape,
            logo: harveymuddLogo,
        },
    },
    hamilton: {
        schoolName: "Hamilton College",
        searchTerms: ["Hamilton College"],
        assets: {
            bannerImage: hamiltonLandscape,
            logo: hamiltonLogo,
        },
    },
    alabama: {
        schoolName: "University of Alabama",
        searchTerms: ["University of Alabama"],
        assets: {
            bannerImage: alabamaLandscape,
            logo: alabamaLogo,
        },
    },
    /* Art Schools */
    risd: {
        schoolName: "Rhode Island School of Design",
        searchTerms: ["Rhode Island School of Design", "RISD"],
        assets: {
            bannerImage: risdLandscape,
            logo: risdLogo,
        },
    },
    accd: {
        schoolName: "ArtCenter College of Design",
        searchTerms: ["ArtCenter College of Design", "ACCD"],
        assets: {
            bannerImage: accdLandscape,
            logo: accdLogo,
        },
    },
    sva: {
        schoolName: "School of Visual Arts",
        searchTerms: ["School of Visual Arts", "SVA"],
        assets: {
            bannerImage: svaLandscape,
            logo: svaLogo,
        },
    },
    parsons: {
        schoolName: "Parsons School of Design",
        searchTerms: ["Parsons School of Design"],
        assets: {
            bannerImage: parsonsLandscape,
            logo: parsonsLogo,
        },
    },
    pratt: {
        schoolName: "Pratt Institute",
        searchTerms: ["Pratt Institute", "Pratt"],
        assets: {
            bannerImage: prattLandscape,
            logo: prattLogo,
        },
    },
    scad: {
        schoolName: "Savannah College of Art and Design",
        searchTerms: ["Savannah College of Art and Design", "SCAD"],
        assets: {
            bannerImage: scadLandscape,
            logo: scadLogo,
        },
    },
    mica: {
        schoolName: "Maryland Institute College of Art",
        searchTerms: ["Maryland Institute College of Art", "MICA"],
        assets: {
            bannerImage: micaLandscape,
            logo: micaLogo,
        },
    },
    /* HBCUs <-- we should add o_o */
};

const schoolCodes = Object.keys(schoolInfo);

export const dropdownSchoolList = schoolCodes
    .filter((code) => code !== "common_app")
    .map((code) => {
        if (schoolInfo[code]?.schoolName) {
            return { name: schoolInfo[code].schoolName, metadata: { schoolCode: code } };
        }
        return null;
    })
    .filter((school) => school !== null)
    .sort((a, b) => a.name.localeCompare(b.name));

/* Mapping of schools with possible admission plans students can apply for
 * We don't account for alternative programs like Questbridge, etc.
 * keys are school codes
 * - name: Display name for admission plan
 * - metadata: extra data
 *   - admissionPlan: the admission plan code for the school that's recognizable by the backend
 *   - dueDate: deadline for this admission plan
 */
export const schoolToAdmissionPlans = {
    princeton: [
        {
            name: "Restrictive Early Action",
            metadata: {
                admissionPlan: "restrictive_early_action",
                dueDate: "November 1",
            },
        },
        {
            name: "Regular Decision",
            metadata: {
                admissionPlan: "regular_decision",
                dueDate: "January 1",
            },
        },
    ],
    mit: [
        {
            name: "Early Action",
            metadata: {
                admissionPlan: "early_action",
                dueDate: "November 1",
            },
        },
        {
            name: "Regular Decision",
            metadata: {
                admissionPlan: "regular_decision",
                dueDate: "January 4",
            },
        },
    ],
    harvard: [
        {
            name: "Restrictive Early Action",
            metadata: {
                admissionPlan: "restrictive_early_action",
                dueDate: "November 1",
            },
        },
        {
            name: "Regular Decision",
            metadata: {
                admissionPlan: "regular_decision",
                dueDate: "January 1",
            },
        },
    ],
    stanford: [
        {
            name: "Restrictive Early Action With Arts Portfolio",
            metadata: {
                admissionPlan: "restrictive_early_action_with_arts_portfolio",
                dueDate: "October 15",
            },
        },
        {
            name: "Restrictive Early Action",
            metadata: {
                admissionPlan: "restrictive_early_action",
                dueDate: "November 1",
            },
        },
        {
            name: "Regular Decision With Arts Portfolio",
            metadata: {
                admissionPlan: "regular_decision_with_arts_portfolio",
                dueDate: "December 5",
            },
        },
        {
            name: "Regular Decision",
            metadata: {
                admissionPlan: "regular_decision",
                dueDate: "January 5",
            },
        },
    ],
    yale: [
        {
            name: "Restrictive Early Action",
            metadata: {
                admissionPlan: "restrictive_early_action",
                dueDate: "November 1",
            },
        },
        {
            name: "Regular Decision",
            metadata: {
                admissionPlan: "regular_decision",
                dueDate: "January 2",
            },
        },
    ],
    upenn: [
        {
            name: "Early Decision",
            metadata: {
                admissionPlan: "early_decision",
                dueDate: "November 1",
            },
        },
        {
            name: "Regular Decision",
            metadata: {
                admissionPlan: "regular_decision",
                dueDate: "January 5",
            },
        },
    ],
    caltech: [
        {
            name: "Restrictive Early Action",
            metadata: {
                admissionPlan: "restrictive_early_action",
                dueDate: "November 1",
            },
        },
        {
            name: "Regular Decision",
            metadata: {
                admissionPlan: "regular_decision",
                dueDate: "January 3",
            },
        },
    ],
    duke: [
        {
            name: "Early Decision",
            metadata: {
                admissionPlan: "early_decision",
                dueDate: "November 1",
            },
        },
        {
            name: "Regular Decision",
            metadata: {
                admissionPlan: "regular_decision",
                dueDate: "January 2",
            },
        },
    ],
    brown: [
        {
            name: "Early Decision",
            metadata: {
                admissionPlan: "early_decision",
                dueDate: "November 1",
            },
        },
        {
            name: "Regular Decision",
            metadata: {
                admissionPlan: "regular_decision",
                dueDate: "January 3",
            },
        },
    ],
    jhu: [
        {
            name: "Early Decision 1",
            metadata: {
                admissionPlan: "early_decision_1",
                dueDate: "November 1",
            },
        },
        {
            name: "Early Decision 2",
            metadata: {
                admissionPlan: "early_decision_2",
                dueDate: "January 2",
            },
        },
        {
            name: "Regular Decision",
            metadata: {
                admissionPlan: "regular_decision",
                dueDate: "January 2",
            },
        },
    ],
    northwestern: [
        {
            name: "Early Decision",
            metadata: {
                admissionPlan: "early_decision",
                dueDate: "November 1",
            },
        },
        {
            name: "Regular Decision",
            metadata: {
                admissionPlan: "regular_decision",
                dueDate: "January 3",
            },
        },
    ],
    columbia: [
        {
            name: "Early Decision",
            metadata: {
                admissionPlan: "early_decision",
                dueDate: "November 1",
            },
        },
        {
            name: "Regular Decision",
            metadata: {
                admissionPlan: "regular_decision",
                dueDate: "January 1",
            },
        },
    ],
    cornell: [
        {
            name: "Early Decision",
            metadata: {
                admissionPlan: "early_decision",
                dueDate: "November 1",
            },
        },
        {
            name: "Regular Decision",
            metadata: {
                admissionPlan: "regular_decision",
                dueDate: "January 2",
            },
        },
    ],
    uchicago: [
        {
            name: "Early Action",
            metadata: {
                admissionPlan: "early_action",
                dueDate: "November 1",
            },
        },
        {
            name: "Early Decision 1",
            metadata: {
                admissionPlan: "early_decision_1",
                dueDate: "November 1",
            },
        },
        {
            name: "Early Decision 2",
            metadata: {
                admissionPlan: "early_decision_2",
                dueDate: "January 6",
            },
        },
        {
            name: "Regular Decision",
            metadata: {
                admissionPlan: "regular_decision",
                dueDate: "January 6",
            },
        },
    ],
    uc: [
        {
            name: "Regular Decision",
            metadata: {
                admissionPlan: "regular_decision",
                dueDate: "November 30",
            },
        },
    ],
    rice: [
        {
            name: "Early Decision 1",
            metadata: {
                admissionPlan: "early_decision_1",
                dueDate: "November 1",
            },
        },
        {
            name: "Early Decision 2",
            metadata: {
                admissionPlan: "early_decision_2",
                dueDate: "January 4",
            },
        },
        {
            name: "Regular Decision",
            metadata: {
                admissionPlan: "regular_decision",
                dueDate: "January 4",
            },
        },
    ],
    dartmouth: [
        {
            name: "Early Decision",
            metadata: {
                admissionPlan: "early_decision",
                dueDate: "November 1",
            },
        },
        {
            name: "Regular Decision",
            metadata: {
                admissionPlan: "regular_decision",
                dueDate: "January 2",
            },
        },
    ],
    vanderbilt: [
        {
            name: "Early Decision 1",
            metadata: {
                admissionPlan: "early_decision_1",
                dueDate: "November 1",
            },
        },
        {
            name: "Early Decision 2",
            metadata: {
                admissionPlan: "early_decision_2",
                dueDate: "January 1",
            },
        },
        {
            name: "Regular Decision",
            metadata: {
                admissionPlan: "regular_decision",
                dueDate: "January 1",
            },
        },
    ],
    notredame: [
        {
            name: "Restrictive Early Action",
            metadata: {
                admissionPlan: "restrictive_early_action",
                dueDate: "November 1",
            },
        },
        {
            name: "Regular Decision",
            metadata: {
                admissionPlan: "regular_decision",
                dueDate: "January 3",
            },
        },
    ],
    umich: [
        {
            name: "Early Action",
            metadata: {
                admissionPlan: "early_action",
                dueDate: "November 1",
            },
        },
        {
            name: "Regular Decision",
            metadata: {
                admissionPlan: "regular_decision",
                dueDate: "February 1",
            },
        },
    ],
    georgetown: [
        {
            name: "Early Action",
            metadata: {
                admissionPlan: "early_action",
                dueDate: "November 1",
            },
        },
        {
            name: "Regular Decision",
            metadata: {
                admissionPlan: "regular_decision",
                dueDate: "January 10",
            },
        },
    ],
    unc: [
        {
            name: "Early Action",
            metadata: {
                admissionPlan: "early_action",
                dueDate: "October 15",
            },
        },
        {
            name: "Regular Decision",
            metadata: {
                admissionPlan: "regular_decision",
                dueDate: "January 15",
            },
        },
    ],
    cmu: [
        {
            name: "Early Decision",
            metadata: {
                admissionPlan: "early_decision",
                dueDate: "November 1",
            },
        },
        {
            name: "Regular Decision",
            metadata: {
                admissionPlan: "regular_decision",
                dueDate: "January 2",
            },
        },
    ],
    emory: [
        {
            name: "Early Decision 1",
            metadata: {
                admissionPlan: "early_decision_1",
                dueDate: "November 1",
            },
        },
        {
            name: "Early Decision 2",
            metadata: {
                admissionPlan: "early_decision_2",
                dueDate: "January 1",
            },
        },
        {
            name: "Regular Decision",
            metadata: {
                admissionPlan: "regular_decision",
                dueDate: "January 1",
            },
        },
    ],
    uva: [
        {
            name: "Early Action",
            metadata: {
                admissionPlan: "early_action",
                dueDate: "November 1",
            },
        },
        {
            name: "Early Decision",
            metadata: {
                admissionPlan: "early_decision",
                dueDate: "November 1",
            },
        },
        {
            name: "Regular Decision",
            metadata: {
                admissionPlan: "regular_decision",
                dueDate: "January 5",
            },
        },
    ],
    washu: [
        {
            name: "Early Decision 1",
            metadata: {
                admissionPlan: "early_decision_1",
                dueDate: "November 1",
            },
        },
        {
            name: "Early Decision 2",
            metadata: {
                admissionPlan: "early_decision_2",
                dueDate: "January 2",
            },
        },
        {
            name: "Regular Decision",
            metadata: {
                admissionPlan: "regular_decision",
                dueDate: "January 2",
            },
        },
    ],
    uflorida: [
        {
            name: "Early Action",
            metadata: {
                admissionPlan: "early_action",
                dueDate: "November 1",
            },
        },
        {
            name: "Regular Decision",
            metadata: {
                admissionPlan: "regular_decision",
                dueDate: "January 15",
            },
        },
    ],
    usc: [
        {
            name: "Early Action",
            metadata: {
                admissionPlan: "early_action",
                dueDate: "November 1",
            },
        },
        {
            name: "Regular Decision",
            metadata: {
                admissionPlan: "regular_decision",
                dueDate: "January 15",
            },
        },
    ],
    utaustin: [
        {
            name: "Early Action",
            metadata: {
                admissionPlan: "early_action",
                dueDate: "October 15",
            },
        },
        {
            name: "Regular Decision",
            metadata: {
                admissionPlan: "regular_decision",
                dueDate: "December 1",
            },
        },
    ],
    georgiatech: [
        {
            name: "Early Action 1",
            metadata: {
                admissionPlan: "early_action_1",
                dueDate: "October 16",
            },
        },
        {
            name: "Early Action 2",
            metadata: {
                admissionPlan: "early_action_2",
                dueDate: "November 1",
            },
        },
        {
            name: "Regular Decision",
            metadata: {
                admissionPlan: "regular_decision",
                dueDate: "January 4",
            },
        },
    ],
    nyu: [
        {
            name: "Early Decision 1",
            metadata: {
                admissionPlan: "early_decision_1",
                dueDate: "November 1",
            },
        },
        {
            name: "Early Decision 2",
            metadata: {
                admissionPlan: "early_decision_2",
                dueDate: "January 1",
            },
        },
        {
            name: "Regular Decision",
            metadata: {
                admissionPlan: "regular_decision",
                dueDate: "January 5",
            },
        },
    ],
    uiuc: [
        {
            name: "Early Action",
            metadata: {
                admissionPlan: "early_action",
                dueDate: "November 1",
            },
        },
        {
            name: "Regular Decision",
            metadata: {
                admissionPlan: "regular_decision",
                dueDate: "January 5",
            },
        },
    ],
    wisc: [
        {
            name: "Early Action",
            metadata: {
                admissionPlan: "early_action",
                dueDate: "November 1",
            },
        },
        {
            name: "Regular Decision",
            metadata: {
                admissionPlan: "regular_decision",
                dueDate: "January 15",
            },
        },
    ],
    bostoncollege: [
        {
            name: "Early Decision 1",
            metadata: {
                admissionPlan: "early_decision_1",
                dueDate: "November 1",
            },
        },
        {
            name: "Early Decision 2",
            metadata: {
                admissionPlan: "early_decision_2",
                dueDate: "January 2",
            },
        },
        {
            name: "Regular Decision",
            metadata: {
                admissionPlan: "regular_decision",
                dueDate: "January 2",
            },
        },
    ],
    rutgers: [
        {
            name: "Early Action",
            metadata: {
                admissionPlan: "early_action",
                dueDate: "November 1",
            },
        },
        {
            name: "Regular Decision",
            metadata: {
                admissionPlan: "regular_decision",
                dueDate: "December 1",
            },
        },
    ],
    tufts: [
        {
            name: "Early Decision 1",
            metadata: {
                admissionPlan: "early_decision_1",
                dueDate: "November 1",
            },
        },
        {
            name: "Early Decision 2",
            metadata: {
                admissionPlan: "early_decision_2",
                dueDate: "January 4",
            },
        },
        {
            name: "Regular Decision",
            metadata: {
                admissionPlan: "regular_decision",
                dueDate: "January 4",
            },
        },
    ],
    uwashington: [
        {
            name: "Regular Decision",
            metadata: {
                admissionPlan: "regular_decision",
                dueDate: "November 15",
            },
        },
    ],
    bu: [
        {
            name: "Early Decision 1",
            metadata: {
                admissionPlan: "early_decision_1",
                dueDate: "November 1",
            },
        },
        {
            name: "Early Decision 2",
            metadata: {
                admissionPlan: "early_decision_2",
                dueDate: "January 4",
            },
        },
        {
            name: "Regular Decision",
            metadata: {
                admissionPlan: "regular_decision",
                dueDate: "January 4",
            },
        },
    ],
    osu: [
        {
            name: "Early Action",
            metadata: {
                admissionPlan: "early_action",
                dueDate: "November 1",
            },
        },
        {
            name: "Regular Decision",
            metadata: {
                admissionPlan: "regular_decision",
                dueDate: "January 15",
            },
        },
    ],
    purdue: [
        {
            name: "Early Action",
            metadata: {
                admissionPlan: "early_action",
                dueDate: "November 1",
            },
        },
        {
            name: "Regular Decision",
            metadata: {
                admissionPlan: "regular_decision",
                dueDate: "January 15",
            },
        },
    ],
    umd: [
        {
            name: "Early Action",
            metadata: {
                admissionPlan: "early_action",
                dueDate: "November 1",
            },
        },
        {
            name: "Regular Decision",
            metadata: {
                admissionPlan: "regular_decision",
                dueDate: "January 20",
            },
        },
    ],
    lehigh: [
        {
            name: "Early Decision 1",
            metadata: {
                admissionPlan: "early_decision_1",
                dueDate: "November 1",
            },
        },
        {
            name: "Early Decision 2",
            metadata: {
                admissionPlan: "early_decision_2",
                dueDate: "January 1",
            },
        },
        {
            name: "Regular Decision",
            metadata: {
                admissionPlan: "regular_decision",
                dueDate: "January 1",
            },
        },
    ],
    texasam: [
        {
            name: "Early Action",
            metadata: {
                admissionPlan: "early_action",
                dueDate: "October 15",
            },
        },
        {
            name: "Regular Decision",
            metadata: {
                admissionPlan: "regular_decision",
                dueDate: "December 1",
            },
        },
    ],
    uga: [
        {
            name: "Early Action",
            metadata: {
                admissionPlan: "early_action",
                dueDate: "October 15",
            },
        },
        {
            name: "Regular Decision",
            metadata: {
                admissionPlan: "regular_decision",
                dueDate: "January 1",
            },
        },
    ],
    urochester: [
        {
            name: "Early Decision 1",
            metadata: {
                admissionPlan: "early_decision_1",
                dueDate: "November 1",
            },
        },
        {
            name: "Early Decision 2",
            metadata: {
                admissionPlan: "early_decision_2",
                dueDate: "January 5",
            },
        },
        {
            name: "Regular Decision",
            metadata: {
                admissionPlan: "regular_decision",
                dueDate: "January 5",
            },
        },
    ],
    virginiatech: [
        {
            name: "Early Action",
            metadata: {
                admissionPlan: "early_action",
                dueDate: "November 15",
            },
        },
        {
            name: "Regular Decision",
            metadata: {
                admissionPlan: "regular_decision",
                dueDate: "January 15",
            },
        },
    ],
    wakeforest: [
        {
            name: "Early Decision 1",
            metadata: {
                admissionPlan: "early_decision_1",
                dueDate: "November 15",
            },
        },
        {
            name: "Early Action",
            metadata: {
                admissionPlan: "early_action",
                dueDate: "November 15",
            },
        },
        {
            name: "Early Decision 2",
            metadata: {
                admissionPlan: "early_decision_2",
                dueDate: "January 1",
            },
        },
        {
            name: "Regular Decision",
            metadata: {
                admissionPlan: "regular_decision",
                dueDate: "January 1",
            },
        },
    ],
    casewestern: [
        {
            name: "Early Decision 1",
            metadata: {
                admissionPlan: "early_decision_1",
                dueDate: "November 1",
            },
        },
        {
            name: "Early Action",
            metadata: {
                admissionPlan: "early_action",
                dueDate: "November 15",
            },
        },
        {
            name: "Early Decision 2",
            metadata: {
                admissionPlan: "early_decision_2",
                dueDate: "January 15",
            },
        },
        {
            name: "Regular Decision",
            metadata: {
                admissionPlan: "regular_decision",
                dueDate: "January 15",
            },
        },
    ],
    floridastate: [
        {
            name: "Early Action",
            metadata: {
                admissionPlan: "early_action",
                dueDate: "October 15",
            },
        },
        {
            name: "Regular Decision",
            metadata: {
                admissionPlan: "regular_decision",
                dueDate: "December 1",
            },
        },
        {
            name: "Rolling Admission",
            metadata: {
                admissionPlan: "rolling_admission",
                dueDate: "March 1",
            },
        },
    ],
    northeastern: [
        {
            name: "Early Decision 1",
            metadata: {
                admissionPlan: "early_decision_1",
                dueDate: "November 1",
            },
        },
        {
            name: "Early Action",
            metadata: {
                admissionPlan: "early_action",
                dueDate: "November 1",
            },
        },
        {
            name: "Early Decision 2",
            metadata: {
                admissionPlan: "early_decision_2",
                dueDate: "January 1",
            },
        },
        {
            name: "Regular Decision",
            metadata: {
                admissionPlan: "regular_decision",
                dueDate: "January 1",
            },
        },
    ],
    umn: [
        {
            name: "Early Action 1",
            metadata: {
                admissionPlan: "early_action_1",
                dueDate: "November 1",
            },
        },
        {
            name: "Early Action 2",
            metadata: {
                admissionPlan: "early_action_2",
                dueDate: "December 1",
            },
        },
        {
            name: "Regular Decision",
            metadata: {
                admissionPlan: "regular_decision",
                dueDate: "January 1",
            },
        },
    ],
    williamandmary: [
        {
            name: "Early Decision 1",
            metadata: {
                admissionPlan: "early_decision_1",
                dueDate: "November 1",
            },
        },
        {
            name: "Early Decision 2",
            metadata: {
                admissionPlan: "early_decision_2",
                dueDate: "January 5",
            },
        },
        {
            name: "Regular Decision",
            metadata: {
                admissionPlan: "regular_decision",
                dueDate: "January 5",
            },
        },
    ],
    stonybrook: [
        {
            name: "Early Action",
            metadata: {
                admissionPlan: "early_action",
                dueDate: "November 1",
            },
        },
        {
            name: "Regular Decision",
            metadata: {
                admissionPlan: "regular_decision",
                dueDate: "January 15",
            },
        },
    ],
    uconn: [
        {
            name: "Early Decision",
            metadata: {
                admissionPlan: "early_decision",
                dueDate: "November 1",
            },
        },
        {
            name: "Regular Decision",
            metadata: {
                admissionPlan: "regular_decision",
                dueDate: "January 15",
            },
        },
    ],
    brandeis: [
        {
            name: "Early Decision 1",
            metadata: {
                admissionPlan: "early_decision_1",
                dueDate: "November 1",
            },
        },
        {
            name: "Early Decision 2",
            metadata: {
                admissionPlan: "early_decision_2",
                dueDate: "January 2",
            },
        },
        {
            name: "Regular Decision",
            metadata: {
                admissionPlan: "regular_decision",
                dueDate: "January 2",
            },
        },
    ],
    michiganstate: [
        {
            name: "Early Action",
            metadata: {
                admissionPlan: "early_action",
                dueDate: "November 1",
            },
        },
        {
            name: "Regular Decision",
            metadata: {
                admissionPlan: "regular_decision",
                dueDate: "February 1",
            },
        },
        {
            name: "Rolling Admission",
            metadata: {
                admissionPlan: "rolling_admission",
                dueDate: "No due date",
            },
        },
    ],
    ncstate: [
        {
            name: "Early Action",
            metadata: {
                admissionPlan: "early_action",
                dueDate: "November 1",
            },
        },
        {
            name: "Regular Decision",
            metadata: {
                admissionPlan: "regular_decision",
                dueDate: "January 15",
            },
        },
    ],
    pennstate: [
        {
            name: "Early Action",
            metadata: {
                admissionPlan: "early_action",
                dueDate: "November 1",
            },
        },
        {
            name: "Rolling Admission",
            metadata: {
                admissionPlan: "rolling_admission",
                dueDate: "December 1",
            },
        },
    ],
    rpi: [
        {
            name: "Early Decision 1",
            metadata: {
                admissionPlan: "early_decision_1",
                dueDate: "November 1",
            },
        },
        {
            name: "Early Action",
            metadata: {
                admissionPlan: "early_action",
                dueDate: "December 1",
            },
        },
        {
            name: "Early Decision 2",
            metadata: {
                admissionPlan: "early_decision_2",
                dueDate: "January 3",
            },
        },
        {
            name: "Regular Decision",
            metadata: {
                admissionPlan: "regular_decision",
                dueDate: "January 15",
            },
        },
    ],
    santaclara: [
        {
            name: "Early Decision 1",
            metadata: {
                admissionPlan: "early_decision_1",
                dueDate: "November 1",
            },
        },
        {
            name: "Early Action",
            metadata: {
                admissionPlan: "early_action",
                dueDate: "November 1",
            },
        },
        {
            name: "Early Decision 2",
            metadata: {
                admissionPlan: "early_decision_2",
                dueDate: "January 7",
            },
        },
        {
            name: "Regular Decision",
            metadata: {
                admissionPlan: "regular_decision",
                dueDate: "January 7",
            },
        },
    ],
    gwu: [
        {
            name: "Early Decision 1",
            metadata: {
                admissionPlan: "early_decision_1",
                dueDate: "November 1",
            },
        },
        {
            name: "Early Decision 2",
            metadata: {
                admissionPlan: "early_decision_2",
                dueDate: "January 5",
            },
        },
        {
            name: "Regular Decision",
            metadata: {
                admissionPlan: "regular_decision",
                dueDate: "January 5",
            },
        },
    ],
    syracuse: [
        {
            name: "Early Decision 1",
            metadata: {
                admissionPlan: "early_decision_1",
                dueDate: "November 15",
            },
        },
        {
            name: "Early Decision 2",
            metadata: {
                admissionPlan: "early_decision_2",
                dueDate: "January 5",
            },
        },
        {
            name: "Regular Decision",
            metadata: {
                admissionPlan: "regular_decision",
                dueDate: "January 5",
            },
        },
    ],
    umass: [
        {
            name: "Early Action",
            metadata: {
                admissionPlan: "early_action",
                dueDate: "November 5",
            },
        },
        {
            name: "Regular Decision",
            metadata: {
                admissionPlan: "regular_decision",
                dueDate: "January 15",
            },
        },
    ],
    umiami: [
        {
            name: "Early Decision 1",
            metadata: {
                admissionPlan: "early_decision_1",
                dueDate: "November 1",
            },
        },
        {
            name: "Early Action",
            metadata: {
                admissionPlan: "early_action",
                dueDate: "November 1",
            },
        },
        {
            name: "Early Decision 2",
            metadata: {
                admissionPlan: "early_decision_2",
                dueDate: "January 6",
            },
        },
        {
            name: "Regular Decision",
            metadata: {
                admissionPlan: "regular_decision",
                dueDate: "January 6",
            },
        },
    ],
    pitt: [
        {
            name: "Rolling Admission",
            metadata: {
                admissionPlan: "rolling_admission",
                dueDate: "No due date",
            },
        },
    ],
    villanova: [
        {
            name: "Early Decision 1",
            metadata: {
                admissionPlan: "early_decision_1",
                dueDate: "November 1",
            },
        },
        {
            name: "Early Action",
            metadata: {
                admissionPlan: "early_action",
                dueDate: "November 1",
            },
        },
        {
            name: "Early Decision 2",
            metadata: {
                admissionPlan: "early_decision_2",
                dueDate: "January 15",
            },
        },
        {
            name: "Regular Decision",
            metadata: {
                admissionPlan: "regular_decision",
                dueDate: "January 15",
            },
        },
    ],
    binghamton: [
        {
            name: "Early Action",
            metadata: {
                admissionPlan: "early_action",
                dueDate: "November 1",
            },
        },
        {
            name: "Regular Decision",
            metadata: {
                admissionPlan: "regular_decision",
                dueDate: "January 15",
            },
        },
    ],
    iubloomington: [
        {
            name: "Early Action",
            metadata: {
                admissionPlan: "early_action",
                dueDate: "November 1",
            },
        },
        {
            name: "Regular Decision",
            metadata: {
                admissionPlan: "regular_decision",
                dueDate: "February 1",
            },
        },
    ],
    tulane: [
        {
            name: "Early Decision 1",
            metadata: {
                admissionPlan: "early_decision_1",
                dueDate: "November 1",
            },
        },
        {
            name: "Early Action",
            metadata: {
                admissionPlan: "early_action",
                dueDate: "November 15",
            },
        },
        {
            name: "Regular Decision",
            metadata: {
                admissionPlan: "regular_decision",
                dueDate: "January 15",
            },
        },
        {
            name: "Early Decision 2",
            metadata: {
                admissionPlan: "early_decision_2",
                dueDate: "January 16",
            },
        },
    ],
    comines: [
        {
            name: "Early Action",
            metadata: {
                admissionPlan: "early_action",
                dueDate: "November 1",
            },
        },
        {
            name: "Regular Decision",
            metadata: {
                admissionPlan: "regular_decision",
                dueDate: "January 15",
            },
        },
        {
            name: "Rolling Admission",
            metadata: {
                admissionPlan: "rolling_admission",
                dueDate: "April 1",
            },
        },
    ],
    pepperdine: [
        {
            name: "Early Decision 1",
            metadata: {
                admissionPlan: "early_decision_1",
                dueDate: "November 1",
            },
        },
        {
            name: "Regular Decision",
            metadata: {
                admissionPlan: "regular_decision",
                dueDate: "January 15",
            },
        },
    ],
    stevenstech: [
        {
            name: "Early Decision 1",
            metadata: {
                admissionPlan: "early_decision_1",
                dueDate: "November 15",
            },
        },
        {
            name: "Early Action",
            metadata: {
                admissionPlan: "early_action",
                dueDate: "December 1",
            },
        },
        {
            name: "Early Decision 2",
            metadata: {
                admissionPlan: "early_decision_2",
                dueDate: "January 15",
            },
        },
        {
            name: "Regular Decision",
            metadata: {
                admissionPlan: "regular_decision",
                dueDate: "February 1",
            },
        },
    ],
    ubuffalo: [
        {
            name: "Early Action",
            metadata: {
                admissionPlan: "early_action",
                dueDate: "November 1",
            },
        },
        {
            name: "Regular Decision",
            metadata: {
                admissionPlan: "regular_decision",
                dueDate: "February 1",
            },
        },
    ],
    udelaware: [
        {
            name: "Early Action",
            metadata: {
                admissionPlan: "early_action",
                dueDate: "November 1",
            },
        },
        {
            name: "Regular Decision",
            metadata: {
                admissionPlan: "regular_decision",
                dueDate: "January 15",
            },
        },
    ],
    rutgersnewark: [
        {
            name: "Early Action",
            metadata: {
                admissionPlan: "early_action",
                dueDate: "November 1",
            },
        },
        {
            name: "Regular Decision",
            metadata: {
                admissionPlan: "regular_decision",
                dueDate: "December 1",
            },
        },
    ],
    uillinoischicago: [
        {
            name: "Early Action",
            metadata: {
                admissionPlan: "early_action",
                dueDate: "November 1",
            },
        },
        {
            name: "Regular Decision",
            metadata: {
                admissionPlan: "regular_decision",
                dueDate: "February 15",
            },
        },
    ],
    wpi: [
        {
            name: "Early Decision 1",
            metadata: {
                admissionPlan: "early_decision_1",
                dueDate: "November 1",
            },
        },
        {
            name: "Early Action 1",
            metadata: {
                admissionPlan: "early_action_1",
                dueDate: "November 1",
            },
        },
        {
            name: "Early Decision 2",
            metadata: {
                admissionPlan: "early_decision_2",
                dueDate: "January 5",
            },
        },
        {
            name: "Early Action 2",
            metadata: {
                admissionPlan: "early_action_2",
                dueDate: "January 5",
            },
        },
        {
            name: "Regular Decision",
            metadata: {
                admissionPlan: "regular_decision",
                dueDate: "February 1",
            },
        },
    ],
    clemson: [
        {
            name: "Early Action",
            metadata: {
                admissionPlan: "early_action",
                dueDate: "October 15",
            },
        },
        {
            name: "Regular Decision",
            metadata: {
                admissionPlan: "regular_decision",
                dueDate: "January 1",
            },
        },
    ],
    marquette: [
        {
            name: "Early Action",
            metadata: {
                admissionPlan: "early_action",
                dueDate: "November 15",
            },
        },
        {
            name: "Regular Decision",
            metadata: {
                admissionPlan: "regular_decision",
                dueDate: "January 15",
            },
        },
    ],
    njit: [
        {
            name: "Early Action 1",
            metadata: {
                admissionPlan: "early_action_1",
                dueDate: "November 15",
            },
        },
        {
            name: "Early Action 2",
            metadata: {
                admissionPlan: "early_action_2",
                dueDate: "December 15",
            },
        },
        {
            name: "Rolling Admission",
            metadata: {
                admissionPlan: "rolling_admission",
                dueDate: "March 1",
            },
        },
    ],
    fordham: [
        {
            name: "Early Action",
            metadata: {
                admissionPlan: "early_action",
                dueDate: "November 1",
            },
        },
        {
            name: "Early Decision 1",
            metadata: {
                admissionPlan: "early_decision_1",
                dueDate: "November 1",
            },
        },
        {
            name: "Early Decision 2",
            metadata: {
                admissionPlan: "early_decision_2",
                dueDate: "January 3",
            },
        },
        {
            name: "Regular Decision",
            metadata: {
                admissionPlan: "regular_decision",
                dueDate: "January 3",
            },
        },
    ],
    temple: [
        {
            name: "Early Action",
            metadata: {
                admissionPlan: "early_action",
                dueDate: "November 1",
            },
        },
        {
            name: "Regular Decision",
            metadata: {
                admissionPlan: "regular_decision",
                dueDate: "July 15",
            },
        },
    ],
    usouthflorida: [
        {
            name: "Early Action",
            metadata: {
                admissionPlan: "early_action",
                dueDate: "November 1",
            },
        },
        {
            name: "Rolling Admission",
            metadata: {
                admissionPlan: "rolling_admission",
                dueDate: "March 1",
            },
        },
    ],
    auburn: [
        {
            name: "Early Action 1",
            metadata: {
                admissionPlan: "early_action_1",
                dueDate: "September 15",
            },
        },
        {
            name: "Early Action 2",
            metadata: {
                admissionPlan: "early_action_2",
                dueDate: "October 15",
            },
        },
        {
            name: "Early Action 3",
            metadata: {
                admissionPlan: "early_action_3",
                dueDate: "November 15",
            },
        },
        {
            name: "Early Action 4",
            metadata: {
                admissionPlan: "early_action_4",
                dueDate: "December 1",
            },
        },
        {
            name: "Regular Decision",
            metadata: {
                admissionPlan: "regular_decision",
                dueDate: "February 1",
            },
        },
    ],
    baylor: [
        {
            name: "Early Decision",
            metadata: {
                admissionPlan: "early_decision",
                dueDate: "November 1",
            },
        },
        {
            name: "Early Action",
            metadata: {
                admissionPlan: "early_action",
                dueDate: "November 1",
            },
        },
        {
            name: "Regular Decision",
            metadata: {
                admissionPlan: "regular_decision",
                dueDate: "February 1",
            },
        },
    ],
    gonzaga: [
        {
            name: "Early Action",
            metadata: {
                admissionPlan: "early_action",
                dueDate: "November 15",
            },
        },
        {
            name: "Regular Decision",
            metadata: {
                admissionPlan: "regular_decision",
                dueDate: "February 1",
            },
        },
    ],
    lmu: [
        {
            name: "Early Action",
            metadata: {
                admissionPlan: "early_action",
                dueDate: "November 1",
            },
        },
        {
            name: "Early Decision 1",
            metadata: {
                admissionPlan: "early_decision_1",
                dueDate: "November 1",
            },
        },
        {
            name: "Early Decision 2",
            metadata: {
                admissionPlan: "early_decision_2",
                dueDate: "January 8",
            },
        },
        {
            name: "Regular Decision",
            metadata: {
                admissionPlan: "regular_decision",
                dueDate: "January 15",
            },
        },
    ],
    uiowa: [
        {
            name: "Rolling Admission",
            metadata: {
                admissionPlan: "rolling_admission",
                dueDate: "May 1",
            },
        },
    ],
    drexel: [
        {
            name: "Early Decision 1",
            metadata: {
                admissionPlan: "early_decision_1",
                dueDate: "November 1",
            },
        },
        {
            name: "Early Action",
            metadata: {
                admissionPlan: "early_action",
                dueDate: "November 1",
            },
        },
        {
            name: "Regular Decision",
            metadata: {
                admissionPlan: "regular_decision",
                dueDate: "January 15",
            },
        },
    ],
    iit: [
        {
            name: "Early Decision 1",
            metadata: {
                admissionPlan: "early_decision_1",
                dueDate: "November 1",
            },
        },
        {
            name: "Early Action",
            metadata: {
                admissionPlan: "early_action",
                dueDate: "November 15",
            },
        },
        {
            name: "Regular Decision",
            metadata: {
                admissionPlan: "regular_decision",
                dueDate: "February 1",
            },
        },
    ],
    rit: [
        {
            name: "Early Decision 1",
            metadata: {
                admissionPlan: "early_decision_1",
                dueDate: "November 1",
            },
        },
        {
            name: "Early Action",
            metadata: {
                admissionPlan: "early_action",
                dueDate: "November 1",
            },
        },
        {
            name: "Early Decision 2",
            metadata: {
                admissionPlan: "early_decision_2",
                dueDate: "January 1",
            },
        },
        {
            name: "Regular Decision",
            metadata: {
                admissionPlan: "regular_decision",
                dueDate: "January 15",
            },
        },
    ],
    uoregon: [
        {
            name: "Early Action",
            metadata: {
                admissionPlan: "early_action",
                dueDate: "November 1",
            },
        },
        {
            name: "Regular Decision",
            metadata: {
                admissionPlan: "regular_decision",
                dueDate: "January 15",
            },
        },
    ],
    usd: [
        {
            name: "Regular Decision",
            metadata: {
                admissionPlan: "regular_decision",
                dueDate: "December 1",
            },
        },
        {
            name: "Rolling Admission",
            metadata: {
                admissionPlan: "rolling_admission",
                dueDate: "January 15",
            },
        },
    ],
    asu: [
        {
            name: "Regular Decision",
            metadata: {
                admissionPlan: "regular_decision",
                dueDate: "May 1",
            },
        },
    ],
    csulb: [
        {
            name: "Regular Decision",
            metadata: {
                admissionPlan: "regular_decision",
                dueDate: "November 30",
            },
        },
    ],
    sdsu: [
        {
            name: "Regular Decision",
            metadata: {
                admissionPlan: "regular_decision",
                dueDate: "November 30",
            },
        },
    ],
    slo: [
        {
            name: "Regular Decision",
            metadata: {
                admissionPlan: "regular_decision",
                dueDate: "November 30",
            },
        },
    ],
    williams: [
        {
            name: "Early Decision",
            metadata: {
                admissionPlan: "early_decision",
                dueDate: "November 15",
            },
        },
        {
            name: "Regular Decision",
            metadata: {
                admissionPlan: "regular_decision",
                dueDate: "January 6",
            },
        },
    ],
    amherst: [
        {
            name: "Early Decision",
            metadata: {
                admissionPlan: "early_decision",
                dueDate: "November 8",
            },
        },
        {
            name: "Regular Decision",
            metadata: {
                admissionPlan: "regular_decision",
                dueDate: "January 6",
            },
        },
    ],
    navalacademy: [
        {
            name: "Regular Decision",
            metadata: {
                admissionPlan: "regular_decision",
                dueDate: "January 31",
            },
        },
    ],
    pomona: [
        {
            name: "Early Decision 1",
            metadata: {
                admissionPlan: "early_decision_1",
                dueDate: "November 8",
            },
        },
        {
            name: "Early Decision 2",
            metadata: {
                admissionPlan: "early_decision_2",
                dueDate: "January 8",
            },
        },
        {
            name: "Regular Decision",
            metadata: {
                admissionPlan: "regular_decision",
                dueDate: "January 8",
            },
        },
    ],
    swarthmore: [
        {
            name: "Early Decision 1",
            metadata: {
                admissionPlan: "early_decision_1",
                dueDate: "November 15",
            },
        },
        {
            name: "Early Decision 2",
            metadata: {
                admissionPlan: "early_decision_2",
                dueDate: "January 4",
            },
        },
        {
            name: "Regular Decision",
            metadata: {
                admissionPlan: "regular_decision",
                dueDate: "January 4",
            },
        },
    ],
    wellesley: [
        {
            name: "Early Decision 1",
            metadata: {
                admissionPlan: "early_decision_1",
                dueDate: "November 1",
            },
        },
        {
            name: "Early Decision 2",
            metadata: {
                admissionPlan: "early_decision_2",
                dueDate: "January 1",
            },
        },
        {
            name: "Regular Decision",
            metadata: {
                admissionPlan: "regular_decision",
                dueDate: "January 8",
            },
        },
    ],
    airforceacademy: [
        {
            name: "Regular Decision",
            metadata: {
                admissionPlan: "regular_decision",
                dueDate: "December 31",
            },
        },
    ],
    westpoint: [
        {
            name: "Regular Decision",
            metadata: {
                admissionPlan: "regular_decision",
                dueDate: "January 31",
            },
        },
    ],
    bowdoin: [
        {
            name: "Early Decision 1",
            metadata: {
                admissionPlan: "early_decision_1",
                dueDate: "November 15",
            },
        },
        {
            name: "Early Decision 2",
            metadata: {
                admissionPlan: "early_decision_2",
                dueDate: "January 6",
            },
        },
        {
            name: "Regular Decision",
            metadata: {
                admissionPlan: "regular_decision",
                dueDate: "January 6",
            },
        },
    ],
    carleton: [
        {
            name: "Early Decision 1",
            metadata: {
                admissionPlan: "early_decision_1",
                dueDate: "November 15",
            },
        },
        {
            name: "Early Decision 2",
            metadata: {
                admissionPlan: "early_decision_2",
                dueDate: "January 15",
            },
        },
        {
            name: "Regular Decision",
            metadata: {
                admissionPlan: "regular_decision",
                dueDate: "January 15",
            },
        },
    ],
    cmc: [
        {
            name: "Early Decision 1",
            metadata: {
                admissionPlan: "early_decision_1",
                dueDate: "November 1",
            },
        },
        {
            name: "Early Decision 2",
            metadata: {
                admissionPlan: "early_decision_2",
                dueDate: "January 10",
            },
        },
        {
            name: "Regular Decision",
            metadata: {
                admissionPlan: "regular_decision",
                dueDate: "January 10",
            },
        },
    ],
    harveymudd: [
        {
            name: "Early Decision 1",
            metadata: {
                admissionPlan: "early_decision_1",
                dueDate: "November 15",
            },
        },
        {
            name: "Early Decision 2",
            metadata: {
                admissionPlan: "early_decision_2",
                dueDate: "January 5",
            },
        },
        {
            name: "Regular Decision",
            metadata: {
                admissionPlan: "regular_decision",
                dueDate: "January 5",
            },
        },
    ],
    hamilton: [
        {
            name: "Early Decision 1",
            metadata: {
                admissionPlan: "early_decision_1",
                dueDate: "November 15",
            },
        },
        {
            name: "Early Decision 2",
            metadata: {
                admissionPlan: "early_decision_2",
                dueDate: "January 6",
            },
        },
        {
            name: "Regular Decision",
            metadata: {
                admissionPlan: "regular_decision",
                dueDate: "January 6",
            },
        },
    ],
    alabama: [
        {
            name: "Regular Decision",
            metadata: {
                admissionPlan: "regular_decision",
                dueDate: "January 15",
            },
        },
        {
            name: "Rolling Admission",
            metadata: {
                admissionPlan: "rolling_admission",
                dueDate: "No due date",
            },
        },
    ],
    risd: [
        {
            name: "Early Decision 1",
            metadata: {
                admissionPlan: "early_decision_1",
                dueDate: "November 1",
            },
        },
        {
            name: "Regular Decision",
            metadata: {
                admissionPlan: "regular_decision",
                dueDate: "January 15",
            },
        },
    ],
    accd: [
        {
            name: "Early Action",
            metadata: {
                admissionPlan: "early_action",
                dueDate: "November 15",
            },
        },
        {
            name: "Rolling Admission",
            metadata: {
                admissionPlan: "rolling_admission",
                dueDate: "No due date",
            },
        },
    ],
    sva: [
        {
            name: "Early Action",
            metadata: {
                admissionPlan: "early_action",
                dueDate: "December 1",
            },
        },
        {
            name: "Rolling Admission",
            metadata: {
                admissionPlan: "rolling_admission",
                dueDate: "No due date",
            },
        },
    ],
    parsons: [
        {
            name: "Early Action",
            metadata: {
                admissionPlan: "early_action",
                dueDate: "November 1",
            },
        },
        {
            name: "Early Decision 1",
            metadata: {
                admissionPlan: "early_decision_1",
                dueDate: "November 1",
            },
        },
        {
            name: "Regular Decision",
            metadata: {
                admissionPlan: "regular_decision",
                dueDate: "January 15",
            },
        },
        {
            name: "Rolling Admission",
            metadata: {
                admissionPlan: "rolling_admission",
                dueDate: "No due date",
            },
        },
        {
            name: "Spring",
            metadata: {
                admissionPlan: "spring_admission",
                dueDate: "October 15",
            },
        },
    ],
    pratt: [
        {
            name: "Early Action",
            metadata: {
                admissionPlan: "early_action",
                dueDate: "November 15",
            },
        },
        {
            name: "Priority Regular Decision",
            metadata: {
                admissionPlan: "priority_regular_decision",
                dueDate: "January 15",
            },
        },
        {
            name: "Regular Decision",
            metadata: {
                admissionPlan: "regular_decision",
                dueDate: "March 15",
            },
        },
        {
            name: "Spring",
            metadata: {
                admissionPlan: "spring_admission",
                dueDate: "October 1",
            },
        },
    ],
    scad: [
        {
            name: "Rolling Admission",
            metadata: {
                admissionPlan: "rolling_admission",
                dueDate: "No due date",
            },
        },
    ],
    mica: [
        {
            name: "Early Action",
            metadata: {
                admissionPlan: "early_action",
                dueDate: "December 1",
            },
        },
        {
            name: "Regular Decision",
            metadata: {
                admissionPlan: "regular_decision",
                dueDate: "February 1",
            },
        },
        {
            name: "Spring",
            metadata: {
                admissionPlan: "spring_admission",
                dueDate: "November 1",
            },
        },
    ],
};

// const plans = Object.values(schoolToAdmissionPlans);
// console.log(
//     plans
//         .reduce((acc, planList) => {
//             planList.forEach((plan) => {
//                 if (!acc.includes(plan.name)) {
//                     acc.push(plan.name);
//                 }
//             });
//             return acc;
//         }, [])
//         .sort()
//         .map((value) =>
//             [
//                 "    " +
//                     value
//                         .split(" ")
//                         .map((word) => word.toUpperCase())
//                         .join("_"),
//                 '"' +
//                     value
//                         .split(" ")
//                         .map((word) => word.toLowerCase())
//                         .join("_") +
//                     '"',
//             ].join(" = ")
//         )
//         .join("\n")
// );
