import styles from "../../../styles/main/history/HistoryItemPage.module.css";
import { useEffect, useState } from "react";
import { getHistoryItem } from "../../../api/apiCalls";

import ReactMarkdown from "react-markdown";
import { FormatModelResponse } from "../utils/utils";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";

export default function HistoryItemPage() {
    const { id } = useParams();
    const [data, setData] = useState(null);

    useEffect(() => {
        getHistoryItem(id)
            .then((response) => setData(response.data))
            .catch((error) => toast.error(`Error fetching past submission: ${error.response.data.error}`));
    }, [id]);

    return (
        <div className={styles.history_container}>
            <div className={styles.history_inner_container}>
                <div className={styles.section_header}>Your Essay(s):</div>
                <div className={styles.essay_textarea}>{data?.initial_essay}</div>
                <div className={styles.section_header}>Athena's Feedback:</div>
                <div className={styles.section_subtext}>
                    <a href="/content/how-to-interpret-athena-feedback" target="_blank" rel="noopener">
                        Need help understanding this feedback?
                    </a>
                </div>
                <div className={styles.model_response_container}>
                    <ReactMarkdown>{FormatModelResponse(data?.model_response)}</ReactMarkdown>
                </div>
            </div>
        </div>
    );
}
