import styles from "../../../styles/content/articles/GeneralArticle.module.css";
import ajay from "../../../imgs/ajay.jpg";
import essay_grading from "../../../imgs/essay_grading.png";

export const StaticArticlePath = "/content/how-to-interpret-athena-feedback";

function Article() {
    return (
        <div className={styles.article_outer_container}>
            <div className={styles.article_inner_container}>
                <div className={styles.header_container}>
                    <div className={styles.title}>How To Interpret Athena Feedback</div>
                    <div className={styles.author_container}>
                        <img className={styles.author_img} src={ajay} alt="ajay"/>
                        <div className={styles.author_info}>
                            <div className={styles.author_name}>Ajay Natarajan</div>
                            <div className={styles.author_description}>Caltech Class of 2022</div>
                        </div>
                    </div>
                </div>
                
                <div className={styles.article_body}>
                    <img className={styles.article_img} src={essay_grading} alt="essay grading"/>
                    <div className={styles.paragraph}>
                        Understanding Athena's feedback should be mostly straightforward and self-explanatory. Each piece of feedback is concrete and actionable, helping guide you towards creating a better essay. However, additional context will help you understand how to incorporate Athena's feedback into your essay — hence why I am writing this article. 
                        <br></br><br></br>
                        Regardless of what type of essay you submitted for grading, every piece of Athena Feedback has the same 5 key components. Let's do a quick overview of what is included:
                        <br></br><br></br>
                        ----------------------------------------------
                        <br></br><br></br>
                        The first is an <span className={styles.bold}>Overall Grade</span>. Nearly every essay is scored on a scale of A+ (wow! you knocked it out of the park and have an essay that is on par with the best essays admitted to top universities) to a D (your essay is actively showcasing undesirable personality traits that might get you rejected).
                        <br></br><br></br>
                        The second is an <span className={styles.bold}>Overview</span>. This is a small paragraph which details what your essay did well and what it did poorly, at a high level.
                        <br></br><br></br>
                        The third is the <span className={styles.bold}>Requirements</span>. Each type of essay (Common App vs. Supplemental vs. a University-Specific model) has a different set of requirements that have been teased out from hundreds of successful essays. Think of these as the “secret rubric” that you should be looking to satisfy as you write a certain type of essay. No one else will tell you what this rubric is, but at Athena, that is part of what we were able to figure out using AI and plenty of past successful essays. Within each requirement, we will give you a subgrade (also from A+ to D), and an explanation for why you received that subgrade. Finally, we will also tell you the best part of your essay that contributes to this requirement (if your subgrade is A or higher) or where your essay needs improvement to satisfy this requirement (if your subgrade is A- or lower).
                        <br></br><br></br>
                        The fourth is the <span className={styles.bold}>Optional Techniques</span>. Each type of essay has also a different set of techniques that have been teased out from hundreds of successful essays — these are metrics that are present in many but not all successful essays. That is to say, they can greatly help your essay, but are not an explicit requirement to have a fantastic essay. We will analyze which of these techniques already fit with the general theme of your essay and can be easily integrated into it. We will then suggest them to you.
                        <br></br><br></br>
                        The fifth is <span className={styles.bold}>Red Flags</span>. There are a few red flags present in essays, mostly having to do with certain negative personality traits that you may be unintentionally portraying. This could be anything from how a certain anecdote reflects on you, to how your reasoning about a past conflict or experience may accidentally indicate a few less-than-desirable traits about yourself. We will scan your essay for these and notify you if you violate one or more of them.
                        <br></br><br></br>
                        ----------------------------------------------
                        <br></br><br></br>
                        Now let's discuss how to effectively incorporate Athena's feedback into improving your essay. The ideal way to use Athena is <span className={styles.bold}>iteratively</span> — pass in the first version of your essay, see what Athena says, make the adjustments you like, and try again. 
                        <br></br><br></br>
                        The main thing to pay attention to is the “Requirements” section. Go requirement by requirement, and figure out how to work in the recommendations Athena gives you into your essay. To be clear, if you do not agree with or like a certain piece of feedback, feel free to ignore it. Essays are culminations of who you are, so if something does not quite feel right or match who you are, then please feel free to ignore it! Even though it may improve your essay strictly-speaking, if you include it but it does not feel as if it is authentically you, your essay may oddly enough become weaker (of course you can test this out by just passing in the new version of your essay back into Athena).
                    </div>
                </div>
            </div>
            
        </div>
    );
}

export function HowToInterpretAthenaFeedback() {
    return (
        <Article />
    );
}