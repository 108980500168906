import styles from "../../styles/premiumTag/PremiumTag.module.css";

import Crown from "../../imgs/crown.png";

// For this to be attached to something, the base div needs to have
// "position: relative" on it.
export default function PremiumTag(props) {
    return (
        <div className={styles.subscription_required}>
            <img className={styles.subscription_tag_img} src={Crown} alt="Crown" />
            <div>{props.tag_text}</div>
        </div>
    );
}
