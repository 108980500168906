import styles from "../../../styles/content/articles/GeneralArticle.module.css";
import ajay from "../../../imgs/ajay.jpg";
import campus from "../../../imgs/campus.jpg";
import funnel_sieve_basket from "../../../imgs/funnel_sieve_basket.png";

export const StaticArticlePath = "/content/youre-thinking-about-the-admissions-process-incorrectly";

function Article() {
    return (
        <div className={styles.article_outer_container}>
            <div className={styles.article_inner_container}>
                <div className={styles.header_container}>
                    <div className={styles.title}>You're Thinking About The Admissions Process Incorrectly</div>
                    <div className={styles.author_container}>
                        <img className={styles.author_img} src={ajay} alt="ajay"/>
                        <div className={styles.author_info}>
                            <div className={styles.author_name}>Ajay Natarajan</div>
                            <div className={styles.author_description}>Caltech Class of 2022</div>
                        </div>
                    </div>
                </div>
                
                <div className={styles.article_body}>
                    <img className={styles.article_img} src={campus} alt="college campus"/>
                    <div className={styles.paragraph}>
                        Before we get into the nitty gritty, welcome to Athena — we are glad you have chosen to advance your admissions journey with us. To be blunt, you are starting a year-long journey which you have never been through before and won't go through again. And this puts you at an information disadvantage... as we here at Athena affectionately put it:  <span className={styles.bold}>you don't know what you don't know.</span>
                        <br></br><br></br>
                        Let's start with 3 ground rules.
                        <br></br><br></br>
                        <span className={styles.bold_and_underline}>Rule #1. Be careful with trusting online advice.</span>
                        <br></br>
                        I have served as a private college admissions consultant for over half a decade, and I've seen parents and students alike led astray by what they read online. Applicants who got into top schools frequently post advice online, claiming to know the “secrets” of how they got in, and give suggestions to future applicants of what to do. As one Admissions Director once put it to me, it is like “the blind leading the blind”. There is some good information out there, but there is a LOT of bad information.
                        <br></br>
                        This pitfall is why every piece of information we tell you, whether it is the content articles we have put together or the AI essay grading, comes from 1 of 2 sources. Either (A) it has been vetted by admissions directors and officers, or (B) it is based on a LOT of data (hundreds of past successful applications). 
                        <br></br><br></br>
                        <span className={styles.bold_and_underline}>Rule #2. For parents: you need to have a heart-to-heart with your child.</span>
                        <br></br>
                        If you are a parent reading this, your instinct may be to take the reins on the college application process since you understand it is so critical to your child's future. Before you do that though, you need to make sure your child WANTS you to do this. The most important thing you can do to help your child in the admissions process is to align your desires with theirs. I have seen many families where the parent tries to drive the admissions process and the student is simply being dragged along — that has never been a recipe for success. First and foremost, you have to make sure your child is self-motivated to crush the college admissions process. If not, then it is worth the time to sit down and chat with them to understand what they want out of life — and it is on you to help them internalize why success in the college admissions process will be critical for their future endeavors.
                        <br></br><br></br>
                        <span className={styles.bold_and_underline}>Rule #3. For students: you may need to push out of your comfort zone a bit.</span>
                        <br></br>
                        Comfort is the enemy of success. Your goal may be a Top 30 school — or maybe it is your local state school. Either way, acceptance rates are dropping year-over-year, quite drastically at that. Take UCLA for example — a decade ago, it had a 25% acceptance rate. This most recent year? 8.5%. This means that just having good grades no longer cuts it. It's the things you do outside of the classroom that really matter, and sometimes this might not be entirely comfortable. But it is necessary.
                        <br></br><br></br>
                        With that introduction out of the way, let's discuss the proper mental model to have when thinking about the admissions process.
                        <br></br>
                        It is a <span className={styles.bold}>funnel</span> that leads to a <span className={styles.bold}>sieve</span> that drops into a <span className={styles.bold}>basket</span>.
                        <div className={styles.body_img_container}>
                            <img className={styles.funnel_sieve_basket_img} src={funnel_sieve_basket} alt="funnel-sieve-basket diagram"/>
                        </div>
                        All applications start at the top of the funnel.
                        <br></br><br></br>
                        <span className={styles.bold}>Step 1: The Funnel</span>
                        <br></br>
                        Universities need to admit students that will actually graduate and pass the rigor of their course load. Why? Because poor four-year graduation rates reflect poorly on the university. So if your grades or standardized test scores don't cut it, there is a high chance you will be cut, regardless of how amazing the rest of your application is. If your application shows that you can handle the rigor of the university, you will go through the mouth of the funnel and pass through to the next phase. If not, you fall outside of the mouth of the funnel and won't be considered for admission.
                        <br></br><br></br>
                        <span className={styles.bold}>Step 2: The Sieve</span>
                        <br></br>
                        Now, this is where subjectivity comes in. And we can make that work for us. Admissions officers decide who gets in and they have to go to bat in front of other officers to justify their choice — and that can be done in many different ways. But it boils down to who they emotionally root for and who they can make a defensible case for as to why they would flourish on campus. Imagine admissions officers shaking a sieve, and the fine grains of sand of people with interesting stories and likable personalities fall through to the final basket.
                        <br></br><br></br>
                        <span className={styles.bold}>Step 3: The Basket</span>
                        <br></br>
                        If you've made it here, you win — congratulations on your admission!
                        <br></br><br></br>
                        So as you think about your essays and crafting your application, have this model in your head at all times. Application readers are humans just like you and I, and the golden ticket is finding a way to get whoever reads your essay to become emotionally invested in your story and start rooting for you. We'll get into specific tactics on how to do this in future articles but for now, keep this as the North Star goal in your mind while writing your essays.
                        <br></br><br></br>
                        The key takeaway is that beyond a certain point, additional academic qualifications are meaningless. Once you're past the mouth of the funnel, an additional award here or there won't move the needle. It all comes down to the essays you write. And plenty of students get tripped up here, thinking more As and more APs is what will help. When in reality, this couldn't be further from the truth.
                    </div>
                </div>
            </div>
            
        </div>
    );
}

export function YoureThinkingAboutTheAdmissionsProcessIncorrectly() {
    return (
        <Article />
    );
}