import styles from "../../styles/base/Dropdown.module.css";
import ChevronDownIcon from "../../imgs/chevron-down-icon.png";
import { useEffect, useState } from "react";

function DropdownItem(props) {
    const { onClick, dropdownItem } = props;

    return (
        <div className={styles.dropdown_item} onClick={onClick}>
            <span className={styles.dropdown_item_name}>{dropdownItem?.name}</span>
            {dropdownItem?.tag && <div className={styles.dropdown_item_tag}>{dropdownItem.tag}</div>}
        </div>
    );
}

/*
 * Dropdown component that displays a list of items and allows the user to select one
 * @param {boolean} showDropdown - whether the dropdown is currently displayed
 * @param {function} setShowDropdown - function to toggle the dropdown
 * @param {array} items - list of items to display in the dropdown
 * @param {object} selectedItem - the currently selected item
 * @param {function} setSelectedItem - function to set the selected item
 * @param {object} dropdownRef - reference to the dropdown container
 * @param {string} previewPrefix - text to display before the selected item
 * @param {string} placeholder - text to display when no item is selected
 * @param {boolean} disabled - whether the dropdown is disabled
 * ^^^^ ok this was all generated but it looks kinda cool
 *
 * items is an array of objects with the following properties:
 * - name: the name of the item
 * - tag (optional): a tag for the item
 * - metadata (optional): additional metadata if you need to attach other data
 */
export default function Dropdown(props) {
    const {
        showDropdown,
        setShowDropdown,
        items,
        selectedItem,
        setSelectedItem,
        dropdownRef,
        previewPrefix,
        placeholder,
        disabled,
    } = props;
    const [dropdownTopOffset, setDropdownTopOffset] = useState(null); // null | number
    // const { prompts, selectedPrompt, setSelectedPrompt } = useApplicationContext();
    useEffect(() => {
        if (dropdownRef.current) {
            const dropdownContainerHeight = dropdownRef.current.clientHeight;
            setDropdownTopOffset(dropdownContainerHeight);
        }
    }, [dropdownRef]);

    if (disabled) {
        return (
            <div className={styles.disabled_dropdown_container}>
                <div className={styles.disabled_dropdown_preview}>
                    <span className={styles.dropdown_placeholder}>{placeholder}</span>
                    <img
                        className={`${styles.dropdown_arrow} ${showDropdown && styles.rotated_dropdown_arrow}`}
                        src={ChevronDownIcon}
                        alt="arrow"
                    />
                </div>
            </div>
        );
    }

    return (
        <div
            className={styles.dropdown_container}
            onClick={() => setShowDropdown((prevDropdownState) => !prevDropdownState)}
            ref={dropdownRef}
        >
            <div className={styles.dropdown_preview}>
                <span>
                    {previewPrefix && <span className={styles.dropdown_preview_prefix}>{previewPrefix}</span>}
                    {selectedItem?.name || <span className={styles.dropdown_placeholder}>{placeholder}</span>}
                </span>
                <img
                    className={`${styles.dropdown_arrow} ${showDropdown && styles.rotated_dropdown_arrow}`}
                    src={ChevronDownIcon}
                    alt="arrow"
                />
            </div>
            {showDropdown && (
                <div
                    className={styles.dropdown_content}
                    style={dropdownTopOffset !== null ? { top: `${dropdownTopOffset + 4}px` } : {}}
                >
                    {items?.map((item, index) => {
                        return <DropdownItem key={index} onClick={() => setSelectedItem(item)} dropdownItem={item} />;
                    })}
                </div>
            )}
        </div>
    );
}
