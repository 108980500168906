import styles from "../../styles/clusterSelection/ClusterSelectionPage.module.css";
import PremiumTag from "../premiumTag/PremiumTag";

import { useNavigate } from "react-router-dom";

// Images
import NoUniversity from "../../imgs/no_university.png";

// Ivy League
import Princeton from "../../imgs/princeton.png";
import Harvard from "../../imgs/harvard.png";
import Brown from "../../imgs/brown.png";
import UPenn from "../../imgs/upenn.png";
import Yale from "../../imgs/yale.png";
import Columbia from "../../imgs/columbia.png";
import Cornell from "../../imgs/cornell.png";
import Dartmouth from "../../imgs/dartmouth.png";

// Ivy+
import MIT from "../../imgs/mit.png";
import Stanford from "../../imgs/stanford.png";
import Caltech from "../../imgs/caltech.png";
import Duke from "../../imgs/duke.png";
import JHU from "../../imgs/jhu.png";
import Northwestern from "../../imgs/northwestern.png";
import UChicago from "../../imgs/uchicago.png";

// UCs
import UC from "../../imgs/uc.png";

// Other top schools
import Rice from "../../imgs/rice.png";
import Vanderbilt from "../../imgs/vanderbilt.png";
import NotreDame from "../../imgs/notredame.png";
import UMich from "../../imgs/umich.png";
import Georgetown from "../../imgs/georgetown.png";
import USC from "../../imgs/usc.png";
// import GeorgiaTech from "../../imgs/georgiatech.png";
// import NYU from "../../imgs/nyu.png";
// import UIUC from "../../imgs/uiuc.png";
import CMU from "../../imgs/cmu.png";

function ModelSelectionWidget(props) {
    const navigate = useNavigate();
    return (
        <div
            className={styles.widget}
            onClick={() => {
                navigate(`/essay-grading/${props.alt}`);
                window.scrollTo(0, 0);
            }}
        >
            <img className={styles.widget_image} src={props.img} alt={props.alt} loading="lazy" />
            <div className={styles.university_text}>{props.university}</div>
            {props.subscription_required ? <PremiumTag tag_text={"Athena Pro"} /> : null}
        </div>
    );
}

export default function ClusterSelectionPage() {
    return (
        <div className={styles.main_section}>
            <div className={styles.main_inner_section}>
                <div className={styles.page_title_container}>
                    <div className={styles.page_title}>Step 1: Pick the university you want your essay graded for.</div>
                </div>
                <div className={styles.model_type_container}>
                    <div className={styles.model_type_title}>Flagship Models</div>
                    <div className={styles.model_type_description}>
                        These Artificial Intelligence (AI) models understand what constitutes a fantastic essay to any
                        top university. They have identified the patterns of success from hundreds of successful college
                        application essays.
                    </div>
                    <div className={styles.widgets_container}>
                        <ModelSelectionWidget
                            img={NoUniversity}
                            alt="flagship"
                            university="Flagship Models"
                            subscription_required={false}
                        />
                    </div>
                </div>
                <div className={styles.model_type_container}>
                    <div className={styles.model_type_title}>University-Specific Models</div>
                    <div className={styles.model_type_description}>
                        These AI models are even more powerful — they understand what constitutes a fantastic essay for
                        a specific university (e.g. Harvard or Princeton). Each of them have been specially tailored by
                        only focusing on successful application essays from that one university.
                    </div>
                    <div className={styles.school_type_section}>
                        <div className={styles.school_type_title}>Ivy League</div>
                        <div className={styles.widgets_container}>
                            <ModelSelectionWidget
                                img={Princeton}
                                alt="princeton"
                                university="Princeton University"
                                subscription_required={true}
                            />
                            <ModelSelectionWidget
                                img={Harvard}
                                alt="harvard"
                                university="Harvard University"
                                subscription_required={true}
                            />
                            <ModelSelectionWidget
                                img={Yale}
                                alt="yale"
                                university="Yale University"
                                subscription_required={true}
                            />
                            <ModelSelectionWidget
                                img={UPenn}
                                alt="upenn"
                                university="University of Pennsylvania"
                                subscription_required={true}
                            />
                            <ModelSelectionWidget
                                img={Brown}
                                alt="brown"
                                university="Brown University"
                                subscription_required={true}
                            />
                            <ModelSelectionWidget
                                img={Columbia}
                                alt="columbia"
                                university="Columbia University"
                                subscription_required={true}
                            />
                            <ModelSelectionWidget
                                img={Cornell}
                                alt="cornell"
                                university="Cornell University"
                                subscription_required={true}
                            />
                            <ModelSelectionWidget
                                img={Dartmouth}
                                alt="dartmouth"
                                university="Dartmouth College"
                                subscription_required={true}
                            />
                        </div>
                    </div>
                    <div className={styles.school_type_section}>
                        <div className={styles.school_type_title}>Ivy+</div>
                        <div className={styles.widgets_container}>
                            <ModelSelectionWidget
                                img={MIT}
                                alt="mit"
                                university="Massachusetts Institute of Technology"
                                subscription_required={true}
                            />
                            <ModelSelectionWidget
                                img={Stanford}
                                alt="stanford"
                                university="Stanford University"
                                subscription_required={true}
                            />
                            <ModelSelectionWidget
                                img={Caltech}
                                alt="caltech"
                                university="Caltech"
                                subscription_required={true}
                            />
                            <ModelSelectionWidget
                                img={JHU}
                                alt="jhu"
                                university="Johns Hopkins University"
                                subscription_required={true}
                            />
                            <ModelSelectionWidget
                                img={Duke}
                                alt="duke"
                                university="Duke University"
                                subscription_required={true}
                            />
                            <ModelSelectionWidget
                                img={Northwestern}
                                alt="northwestern"
                                university="Northwestern University"
                                subscription_required={true}
                            />
                            <ModelSelectionWidget
                                img={UChicago}
                                alt="uchicago"
                                university="University of Chicago"
                                subscription_required={true}
                            />
                        </div>
                    </div>
                    <div className={styles.school_type_section}>
                        <div className={styles.school_type_title}>University of California (UCs)</div>
                        <div className={styles.widgets_container}>
                            <ModelSelectionWidget img={UC} alt="uc" university="UCs" subscription_required={true} />
                        </div>
                    </div>
                    <div className={styles.school_type_section}>
                        <div className={styles.school_type_title}>Other top schools</div>
                        <div className={styles.widgets_container}>
                            <ModelSelectionWidget
                                img={Rice}
                                alt="rice"
                                university="Rice University"
                                subscription_required={true}
                            />
                            <ModelSelectionWidget
                                img={Vanderbilt}
                                alt="vanderbilt"
                                university="Vanderbilt University"
                                subscription_required={true}
                            />
                            <ModelSelectionWidget
                                img={NotreDame}
                                alt="notredame"
                                university="University of Notre Dame"
                                subscription_required={true}
                            />
                            <ModelSelectionWidget
                                img={UMich}
                                alt="umich"
                                university="University of Michigan Ann Arbor"
                                subscription_required={true}
                            />
                            <ModelSelectionWidget
                                img={Georgetown}
                                alt="georgetown"
                                university="Georgetown University"
                                subscription_required={true}
                            />
                            <ModelSelectionWidget
                                img={CMU}
                                alt="cmu"
                                university="Carnegie Mellon University"
                                subscription_required={true}
                            />
                            <ModelSelectionWidget
                                img={USC}
                                alt="usc"
                                university="University of Southern California"
                                subscription_required={true}
                            />
                            {/* <ModelSelectionWidget img={GeorgiaTech} alt="georgiatech" university="Georgia Institute of Technology" subscription_required={true}/>
                            <ModelSelectionWidget img={NYU} alt="nyu" university="New York University" subscription_required={true}/>
                            <ModelSelectionWidget img={UIUC} alt="uiuc" university="University of Illinois Urbana-Champaign" subscription_required={true}/> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
