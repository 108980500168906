import { CollegeApplication } from "./Application.js";
import { createApplication, fetchCommonAppApplication } from "../../api/apiCalls.js";
import { useApplicationContext } from "./ApplicationContext.js";
import { useEffect } from "react";

export default function CommonApp() {
    /*
    What is the user flow when they load common app?
    - We check whether user has a common app essay saved in database
    - If yes: manually set application id?
    - If no:
        - Create common app application
        - Return application id
    */
    const { handleInitializeCommonApp } = useApplicationContext();

    useEffect(() => {
        handleInitializeCommonApp();
    }, []);
    return <CollegeApplication />;
}
