import { useEffect, useState, useMemo } from "react";

import styles from "../../../styles/main/subcomponents/LoadingSection.module.css";
import loadingSpinner from "../../../imgs/loading.svg";


export default function LoadingSection(props) {
    const loadingTexts = useMemo(
        () => [
            "Give me some time - this might take close to 1 minute...",
            "While you wait, I'll give you some secret college admissions strategies!",
            "The most underutilized strategy in the college admissions process is building a strong relationship with your guidance counselor. After all, they write 1 of your recommendation letters.",
            "When applying to rigorous STEM institutions in particular, try to include an 'unintentional' example of your kindness and empathy in one of your essays.",
            "In the supplemental essay of a school asking 'Why this school?', try reaching out to a current student at that school to get unique insights that you can use in your essays. Mention that student by name to show the admissions committee you've gone another layer deeper with your research and can prove you'll be a good fit at that school.",
            "Fit is one of the most important metrics when it comes to college admissions. Show why you're not just a talented person but why you really fit at the school you're applying to.",
            "More tips to come in the coming months... stay tuned!",
        ],
        []
    );

    const [currentTextIndex, setCurrentTextIndex] = useState(0);

    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentTextIndex((prevTextIndex) => {
                return (prevTextIndex + 1) % loadingTexts.length;
            });
        }, 13000); // Change text every 13 seconds (since polling is also 10 seconds)

        return () => clearInterval(intervalId); // Clear interval on component unmount
    }, [loadingTexts]);

    return (
        <div className={styles.loading_section}>
            <img src={loadingSpinner} alt="loading spinner" width={50} />
            {
                props.shouldShowText 
                ? (<div className={styles.loading_text}>
                    {loadingTexts[currentTextIndex]}
                    </div>
                )
                : null
            }
        </div>
    );
}