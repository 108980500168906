import React, { createContext, useState, useCallback } from "react";
import { getAllHistoryPreviews } from "../../api/apiCalls";
import { toast } from "react-toastify";

// Create a Context
export const SidebarContext = createContext();

const NUM_PREVIEW_CHARS = 30;

export const SidebarProvider = ({ children }) => {
    const [historyItemPreviews, setHistoryItemPreviews] = useState([]);

    const fetchHistoryItemPreviews = useCallback(async () => {
        getAllHistoryPreviews(NUM_PREVIEW_CHARS)
            .then((response) => {
                setHistoryItemPreviews(response.data["history_items"]);
            })
            .catch((error) => toast.error(`Error fetching history items: ${error.response.data.error}`));
    }, []);

    // Expose the history item previews and the function to retrigger fetching them fresh
    return (
        <SidebarContext.Provider value={{ historyItemPreviews, fetchHistoryItemPreviews }}>
            {children}
        </SidebarContext.Provider>
    );
};
