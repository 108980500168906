import { useAuthContext } from "./AuthContext";
import { ReactComponent as AthenaBlackLogo } from "../../imgs/athena-black-logomark.svg";
import styles from "../../styles/auth/AdminDashboard.module.css";

import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import BaseButton from "../base/BaseButton";

const formatTimestamp = (timestamp) => {
    const date = new Date(timestamp);
    return date.toLocaleString();
};

function Card(props) {
    const { title, body } = props;

    return (
        <div key={title} className={styles.card}>
            <div className={styles.card_title}>{title}</div>
            <div className={styles.card_body}>{body}</div>
        </div>
    );
}

function UserDataBody({ dashboardData }) {
    const totalUsers = dashboardData.total_users;
    const totalVerifiedUsersSinceMay1 = dashboardData.total_verified_users_since_may_1;
    const totalStudentUsers = dashboardData.total_student_users;
    const totalParentUsers = dashboardData.total_parent_users;
    const totalGoogleUsers = dashboardData.total_google_users;
    const totalCleverUsers = dashboardData.total_clever_users;
    const totalEmailUsers = parseInt(totalUsers) - parseInt(totalGoogleUsers) - parseInt(totalCleverUsers);
    const joinedLast20Days = dashboardData.users_joined_last_20_days;
    const referralSources = dashboardData.referral_sources;
    const last20ReferralSources = dashboardData.last_20_referral_sources;

    return (
        <div>
            <p className={styles.list_title}>Verified users since May 1st: {totalVerifiedUsersSinceMay1}</p>
            <p className={styles.list_title}>Breakdown by account type:</p>
            <ul>
                <li>Student Users: {totalStudentUsers}</li>
                <li>Parent Users: {totalParentUsers}</li>
            </ul>
            <p className={styles.list_title}>Breakdown by login method:</p>
            <ul>
                <li>Email Users: {totalEmailUsers}</li>
                <li>Google Users: {totalGoogleUsers}</li>
                <li>Clever Users: {totalCleverUsers}</li>
            </ul>
            <p className={styles.list_title}>Number of users joined in last 20 days:</p>
            <ul className={styles.two_column_list}>
                {joinedLast20Days &&
                    Object.entries(joinedLast20Days).map(([date, count], index) => (
                        <li key={index}>
                            {date}: {count}
                        </li>
                    ))}
            </ul>
            <p className={styles.list_title}>Popular Referral Sources:</p>
            <ul className={styles.two_column_list}>
                {referralSources &&
                    Object.entries(referralSources).map(([source, count], index) => (
                        <li key={index}>
                            {source}: {count}
                        </li>
                    ))}
            </ul>
            <p className={styles.list_title}>Most recent 20 referral sources:</p>
            <ul className={styles.two_column_list}>
                {last20ReferralSources &&
                    Object.entries(last20ReferralSources).map(([source, count], index) => (
                        <li key={index}>
                            {source}: {count}
                        </li>
                    ))}
            </ul>
        </div>
    );
}

function AIUsageBody({ dashboardData }) {
    const aiUsageStats = dashboardData.ai_usage_stats;

    return (
        <div className={styles.card_body}>
            <ul>
                <li>Brainstorm usage: {aiUsageStats?.brainstorm_count}</li>
                <li>Progress Check usage: {aiUsageStats?.progress_check_count}</li>
                <li>Report Card usage: {aiUsageStats?.report_card_count}</li>
            </ul>
        </div>
    );
}

function ApplicationsBody({ dashboardData }) {
    const applicationStats = dashboardData.application_stats;
    const sortedApplications = applicationStats?.sorted_applications;

    return (
        <div className={styles.card_body}>
            <p className={styles.list_title}>Total Applications: {applicationStats?.total_applications}</p>
            <p className={styles.list_title}>Most popular applications</p>
            <ul className={styles.three_column_list}>
                {sortedApplications?.map((stats, index) => (
                    <li key={index}>
                        {stats.school_code}: {stats.count}
                    </li>
                ))}
            </ul>
        </div>
    );
}

function Last50UsersBody({ dashboardData }) {
    const [last50UsersPage, setLast50UsersPage] = useState(1);
    const [last50Users, setLast50Users] = useState(dashboardData.last_50_users);
    useEffect(() => {
        setLast50Users(dashboardData.last_50_users);
        setLast50UsersPage(1);
    }, [dashboardData]);

    const { handleFetchAdminDashboardLast50Users } = useAuthContext();

    const handleNextPage = () => {
        handleFetchAdminDashboardLast50Users(last50UsersPage + 1)
            .then((response) => {
                setLast50Users(response.data.users);
                setLast50UsersPage(last50UsersPage + 1);
            })
            .catch((error) => toast.error(error.response.data.error));
    };

    const handlePreviousPage = () => {
        if (last50UsersPage === 1) return;

        handleFetchAdminDashboardLast50Users(last50UsersPage - 1)
            .then((response) => {
                setLast50Users(response.data.users);
                setLast50UsersPage(last50UsersPage - 1);
            })
            .catch((error) => toast.error(error.response.data.error));
    };

    return (
        <div className={styles.table_container}>
            <div className={styles.card_row}>
                <BaseButton
                    className={styles.table_page_button}
                    onClick={handlePreviousPage}
                    disabled={last50UsersPage === 1}
                >
                    <AthenaBlackLogo className={`${styles.table_page_button_icon} ${styles.rotate_270_deg}`} />
                </BaseButton>
                <p className={styles.list_title}>Page {last50UsersPage}</p>
                <BaseButton
                    className={styles.table_page_button}
                    onClick={handleNextPage}
                    disabled={last50Users?.length < 50}
                >
                    <AthenaBlackLogo className={`${styles.table_page_button_icon} ${styles.rotate_90_deg}`} />
                </BaseButton>
            </div>
            <table>
                <thead>
                    <tr>
                        <th>Email</th>
                        <th>First Name</th>
                        <th>Last Name</th>
                        <th>Date Joined</th>
                        <th>Country</th>
                        <th>Account Type</th>
                        <th>Is Verified</th>
                        <th>Credit</th>
                        <th>Membership Plan</th>
                    </tr>
                </thead>
                <tbody>
                    {last50Users?.map((user, index) => (
                        <tr key={index}>
                            <td>{user.email}</td>
                            <td>{user.first_name}</td>
                            <td>{user.last_name}</td>
                            <td>{formatTimestamp(user.date_joined)}</td>
                            <td>{user.country}</td>
                            <td>{user.account_type}</td>
                            <td>{user.is_verified ? "True" : "False"}</td>
                            <td>{user.credit}</td>
                            <td>{user.membership_plan}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}

export default function AdminDashboard() {
    const { handleFetchAdminDashboard } = useAuthContext();
    const [dashboardData, setDashboardData] = useState({});

    useEffect(() => {
        handleFetchAdminDashboard()
            .then((response) => {
                setDashboardData(response.data.data);
            })
            .catch((error) => toast.error(error.response.data.error));
    }, [handleFetchAdminDashboard]);

    if (Object.keys(dashboardData).length === 0) {
        return null;
    }

    return (
        <div className={styles.admin_dashboard_container}>
            <h1>Admin Dashboard</h1>
            <div className={styles.admin_dashboard}>
                <Card
                    title={`Total Users:  ${dashboardData.total_users}`}
                    body={<UserDataBody dashboardData={dashboardData} />}
                />
                <Card title={"AI Usage Stats: "} body={<AIUsageBody dashboardData={dashboardData} />} />
                <Card title={"Application Stats: "} body={<ApplicationsBody dashboardData={dashboardData} />} />
                <Card title={"Last 50 Users: "} body={<Last50UsersBody dashboardData={dashboardData} />} />
            </div>
        </div>
    );
}
