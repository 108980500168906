import styles from "../../styles/gradeRequestTypeButton/GradeRequestTypeButton.module.css";
import BaseButton from "../base/BaseButton";

export default function GradeRequestTypeButton(props) {
    return (
        <BaseButton
            className={`${styles.grade_request_type_button} ${props.className}`}
            onClick={props.onClick}
            type={props.type}
        >
            <div>
                <div className={styles.button_title}>{props.title}</div>
            </div>
        </BaseButton>
    );
}
