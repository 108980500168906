import { forwardRef } from "react";
import styles from "../../styles/base/BaseButton.module.css";

const BaseButton = forwardRef(function BaseButton({ ...props }, ref) {
    return (
        <button
            className={`${props.className} ${styles.base_button}`}
            onClick={props.onClick}
            type={props.type}
            disabled={props.disabled}
            ref={ref}
            style={props.style}
        >
            {props.children}
        </button>
    );
});

export default BaseButton;
