import styles from "../../styles/auth/AuthPage.module.css";
import { GoogleLogin, useGoogleLogin } from "@react-oauth/google";
import { useAuthContext } from "./AuthContext";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import whiteAthenaV2 from "../../imgs/white_athena_v2.png";
import blackAthenaV2 from "../../imgs/black-athena-v2.png";
import { useEffect, useState, useRef } from "react";
import BaseButton from "../base/BaseButton";

import ForgotPasswordPage from "./ForgotPasswordPage";
import LoginPage from "./LoginPage";
import ResetPasswordPage from "./ResetPasswordPage";
import SignupPage from "./SignupPage";

export default function AuthPage(props) {
    const { isLoggedIn } = useAuthContext();
    const navigate = useNavigate();
    const navigateRef = useRef(navigate);

    useEffect(() => {
        if (isLoggedIn) {
            navigateRef.current("/");
        }
    }, [isLoggedIn]);

    const { authView } = props;
    const renderBody = () => {
        switch (authView) {
            case "login":
                return <LoginPage navigateOnLoginCompletion={"/"} onSignupClick={() => {navigate("/signup")}}/>;
            case "signup":
                return <SignupPage navigateOnSignupCompletion={"/account-setup"} onLoginClick={() => {navigate("/login")}}/>;
            case "forgot-password":
                return <ForgotPasswordPage />;
            case "reset-password":
                return <ResetPasswordPage />;
            default:
                return <LoginPage navigateOnLoginCompletion={"/"} onSignupClick={() => {navigate("/signup")}}/>;
        }
    };
    return (
        <div className={styles.new_background}>
            <div className={styles.auth_container}>{renderBody()}</div>
        </div>
    );
}
