import { Slide, ToastContainer } from "react-toastify";
import styles from "../../styles/base/Toast.module.css";
import "react-toastify/dist/ReactToastify.css";

export default function AthenaToastContainer({ styles: customStyles }) {
    return (
        <ToastContainer
            className={styles.toast_container}
            position={"bottom-right"}
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
            transition={Slide}
            {...customStyles}
        />
    );
}
